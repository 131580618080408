import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Cta from '@mc/components/_partials/cta/Cta';
import { firestore } from '@mc/config/firebase';
import { getUser } from '@mc/redux/auth/auth.selectors';
import {
  Wrap,
  Glyph,
  CourseDetails,
  ModuleName,
  ChapterName,
  Progress,
  PageName,
  Placeholder,
} from './CourseProgress.styled';

const CourseProgress = () => {
  const user = useSelector(getUser);
  const history = useHistory();
  const [courseModule, setCourseModule] = useState(null);
  const [courseChapter, setCourseChapter] = useState(null);
  const [coursePage, setCoursePage] = useState(null);
  const [chapterCompletedPages, setChapterCompletedPages] = useState([]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const fetchPageViews = async () => {
      try {
        let courseModuleObj = null;
        let courseChapterObj = null;
        let coursePageObj = null;

        const { docs: pageViewDocs } = await firestore()
          .collection('pageViews')
          .where('userId', '==', user.uid)
          .orderBy('lastViewedAt', 'desc')
          .limit(1)
          .get();
        const [latestPageView] = pageViewDocs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (latestPageView) {
          const moduleDoc = await firestore().doc(`modules/${latestPageView.moduleId}`).get();
          courseModuleObj = { id: moduleDoc.id, ...moduleDoc.data() };

          const chapterDoc = await firestore().doc(`chapters/${latestPageView.chapterId}`).get();
          courseChapterObj = { id: chapterDoc.id, ...chapterDoc.data() };

          const pageDoc = await firestore().doc(`pages/${latestPageView.pageId}`).get();
          coursePageObj = { id: pageDoc.id, ...pageDoc.data() };
        } else {
          const courseDoc = await firestore().doc(`courses/${process.env.REACT_APP_INTRO_COURSE_ID}`).get();
          const course = { id: courseDoc.id, ...courseDoc.data() };

          const moduleDoc = await firestore().doc(`modules/${course.modules[0].id}`).get();
          courseModuleObj = { id: moduleDoc.id, ...moduleDoc.data() };

          const chapterDoc = await firestore().doc(`chapters/${courseModuleObj.chapters[0].id}`).get();
          courseChapterObj = { id: chapterDoc.id, ...chapterDoc.data() };
        }

        const { docs: completedPagesDocs } = await firestore()
          .collection('pageViews')
          .where('userId', '==', user.uid)
          .where('chapterId', '==', courseChapterObj.id)
          .where('completed', '==', true)
          .get();
        const completedPages = completedPagesDocs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setCourseModule(courseModuleObj);
        setCourseChapter(courseChapterObj);
        setCoursePage(coursePageObj);
        setChapterCompletedPages(completedPages);
      } catch (ex) {
        console.error(ex);
      }
    };

    fetchPageViews();
  }, [user]);

  const isBasicCourse = courseModule && courseModule.chapters.length === 1;
  const progress = courseChapter
    ? Math.ceil((chapterCompletedPages.length / (courseChapter.pages?.length || 0)) * 100)
    : 0;

  return (
    <>
      {courseModule && courseChapter ? (
        <Wrap>
          {courseModule.color && courseModule.icon ? (
            <Glyph color={courseModule.color}>
              <span className={`icon icon-${courseModule.icon}`} />
            </Glyph>
          ) : null}
          <CourseDetails centered={!coursePage}>
            {!isBasicCourse ? <ModuleName>{courseModule.name}</ModuleName> : null}
            <ChapterName>{courseChapter.name}</ChapterName>
            {coursePage ? (
              <PageName>
                <span className="icon icon-file-code" />
                {coursePage.title}
              </PageName>
            ) : null}
          </CourseDetails>
          <Progress value={progress} text={`${progress}%`} strokeWidth={12} />
          <Cta
            onClick={async () => {
              if (courseModule.autoEnrol && !coursePage) {
                try {
                  window.fbq('track', 'StartTrial', {
                    value: 0,
                    currency: 'GBP',
                    subscription_id: 'introduction-to-programming',
                    predicted_ltv: '5000',
                  });

                  try {
                    await fetch(`https://hooks.zapier.com/hooks/catch/11857442/33xyq3l/`, {
                      method: 'POST',
                      body: JSON.stringify({
                        email: user.email,
                        tag: 'itp_started',
                      }),
                    });
                  } catch (ex) {
                    // fall silently
                  }

                  await axios.request({
                    method: 'POST',
                    url: 'https://hooks.zapier.com/hooks/catch/4751012/orcxewg/',
                    data: {
                      email: user.email,
                      date: new Date(),
                      moduleSlug: courseModule.slug,
                    },
                    headers: {
                      'Content-Type': 'application/x-www-form-urlencoded',
                    },
                  });
                } catch (ex) {
                  // fall silently
                }
              }

              history.push(
                `/module/${courseModule.slug}/${courseChapter.slug}${coursePage ? `/?pageId=${coursePage.id}` : ''}`
              );
            }}
            primary
            $large
          >
            {coursePage ? 'Resume' : 'Start'}
          </Cta>
        </Wrap>
      ) : (
        <Placeholder />
      )}
    </>
  );
};

export default CourseProgress;
