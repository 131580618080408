import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

export const Wrap = styled.div`
  ${({ theme, mobile, desktop }) => `
    margin: 0 0 1.5rem;
    display: ${mobile ? 'flex' : 'none'};
    font-size: 14px;

    @media (min-width: ${theme.screens.xl}) {
      display: ${desktop ? 'block' : 'none'};
      margin: 0 0 2rem;
    }

    i {
      margin-right: 15px;
      display: inline-block;
      vertical-align: middle;
      padding-top: 5px;
    }
  `}
`;

export const Link = styled(ReactRouterLink)`
  line-height: 1.5;
`;

export const Divider = styled.div`
  ${({ theme }) => `
    display: inline-block;
    color: ${theme.colors.darkAlpha};
    margin: 0 15px;
  `}
`;
