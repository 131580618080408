import { createSelector } from 'reselect';

export const getHeaderData = createSelector(
  state => {return {header: state.app.content.header, theme: state.theme.theme}},
  headerData => {
    if (headerData.theme === "dark"){
      return {logo: headerData.header.logo_dark};
    }else{
      return {logo: headerData.header.logo};
    }
  }
)

export const getFooterData = createSelector(
  state => { return {footer: state.app.content.footer, theme: state.theme.theme} },
  data => {
    if (data.theme === "dark"){
      return {logo: data.footer.logo_dark};
    }else{
      return {logo: data.footer.logo};
    }
  }
);

export const getLabels = createSelector(
  state => state.app.content.labels,
  labels => labels
);

export const getSlack = createSelector(
  state => state.app.content.slack,
  slack => slack
);
