import theme from '@mc/config/theme';

export const formatSbUrl = url => (url.startsWith('http') ? url : `/${url}`);

export const gtag = (...args) => window.dataLayer.push(args);

export const supportsWebP = (feature, callback) => {
  const testImages = {
    lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
    lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
    alpha:
      'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
    animation:
      'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
  };
  const img = new Image();
  img.onload = function() {
    const result = img.width > 0 && img.height > 0;
    callback(feature, result);
  };
  img.onerror = function() {
    callback(feature, false);
  };
  img.src = `data:image/webp;base64,${testImages[feature]}`;
};

export const getScaledImagePath = (image, size, webPSupport) =>
  `//img2.storyblok.com/${size}${webPSupport ? '/filters:format(webp)' : ''}${image.replace('//a.storyblok.com', '')}`;

export const getOptimisedImagePath = (image, options) => {
  if (typeof options === 'string') {
    return getScaledImagePath(image, options);
  }

  const { webpSupport, isMobile, isTablet } = options;

  if (typeof window === 'undefined') {
    if (isMobile) {
      return getScaledImagePath(image, options.sm || options.md || options.lg || options.xl, webpSupport);
    }

    if (isTablet) {
      return getScaledImagePath(image, options.md || options.lg || options.xl || options.sm, webpSupport);
    }

    return getScaledImagePath(image, options.xl || options.lg || options.md || options.sm, webpSupport);
  }

  const viewportWidth = window.innerWidth;
  const { md: tablet, xl: desktop } = theme.screens;

  if (viewportWidth < tablet && options.sm) {
    return getScaledImagePath(image, options.sm, webpSupport);
  }

  if (viewportWidth < desktop && options.md) {
    return getScaledImagePath(image, options.md, webpSupport);
  }

  return getScaledImagePath(image, options.xl || options.lg || options.md || options.sm, webpSupport);
};

export const getViewportWidth = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

export const getViewportHeight = () => Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

export const bodyNoScroll = {
  scrollTop: 0,
  add(breakpoint) {
    this.breakpoint = breakpoint;

    if (!breakpoint || getViewportWidth() < breakpoint) {
      this.scrollTop = window.scrollY;
      document.documentElement.classList.add('lock-scroll');
    }
  },
  remove() {
    document.documentElement.classList.remove('lock-scroll');
    window.scrollTo(0, this.scrollTop);
  },
};

export const getUserAgent = () => navigator.userAgent || navigator.vendor || window.opera;

export const isAndroid = () => {
  const userAgent = getUserAgent();

  return /android/i.test(userAgent);
};

export const isIOS = () => {
  const userAgent = getUserAgent();

  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
};

export const isAndroidOrIOS = () => isAndroid() || isIOS();
