import React from 'react';

const ExamSummary = () => (
  <>
    <h2>Manchester Codes Entry Exam</h2>
    <img src="/images/illustrations/exam.svg" alt="Exam" style={{ width: 400, height: 'auto', margin: '40px 0' }} />
    <p>
      Welcome to the Manchester Codes Entry Exam. Prior to starting this exam you should have completed{' '}
      <a href="/module/introduction-to-programming/programming-basics" target="_blank">
        our free INTRO course
      </a>{' '}
      and{' '}
      <a href="https://eloquentjavascript.net/" target="_blank" rel="noreferrer">
        the first 5 chapters of EloquentJS
      </a>
      .
    </p>
    <p>
      This exam consists of 10 questions - each relevant to the material you have been asked to study. You should allow
      at least an hour to complete the exam (though don't worry if it takes you longer). If you don't pass the exam the
      first time then you won't be able to retake for another 4 weeks, so please invest as much time as possible in the
      preparation work.
    </p>
    <p>
      The exam is marked by a member of our team. We still award marks for solutions that contain small errors, or for
      partial solutions where the approach is correct, so please attempt all of the questions. You need a 70% mark to
      pass, and will find out if you have been successful or not - along with feedback - within 2-3 working days of
      completing the exam.
    </p>
    <p>
      <strong>
        Please don't leave or refresh the page, as your progress will be lost and you may not get to take another
        attempt.
      </strong>
    </p>
    <p>Lastly, keep calm, don't be nervous, and enjoy the tasks ahead!</p>
  </>
);

export default ExamSummary;
