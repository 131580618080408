import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
  name: 'config',
  initialState: {
    meta: {
      webpSupport: false,
      isMobile: false,
      isTablet: false,
    },
    country: 'GB',
  },
  reducers: {
    setBrowserMeta(state, { payload: meta }) {
      state.meta = meta;
    },
    setCountry(state, { payload: country }) {
      state.country = country;
    },
  },
});

export const { actions, reducer } = configSlice;
export const { setBrowserMeta, setCountry } = actions;
export default reducer;
