import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Section, Title, Fieldset, Checkbox, CtaContainer, Error, TextFrame } from '../Checkout.styled';
import Cta from '@mc/components/_partials/cta/Cta';
// import TermsInner from './TermsInner';
import RichTextField from '@mc/components/rich-text-field/RichTextField';

const TermsSchema = Yup.object().shape({
  consent: Yup.bool().oneOf([true], 'You must agree to our Student Terms and Conditions to enrol onto this course'),
});

const Terms = ({ onNextStep, course, customer, onUpdateCustomer }) => {
  const handleSubmit = values => {
    onUpdateCustomer({
      ...customer,
      terms: values,
    });
    onNextStep();
  };

  return (
    <Formik
      initialValues={{
        consent: false,
      }}
      validationSchema={TermsSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Form>
          <Section>
            <Title>Course Terms and Conditions</Title>
            <Fieldset>
              <TextFrame src="https://www.manchestercodes.com/content/student-terms-conditions">
              </TextFrame>
            </Fieldset>
            <Fieldset>
              <Checkbox name="consent" id="consent" onChange={handleChange} onBlur={handleBlur}>
                I agree to the Course Terms and Conditions
              </Checkbox>
              {errors.consent && touched.consent ? <Error>{errors.consent}</Error> : null}
            </Fieldset>
          </Section>
          <CtaContainer>
            <Cta primary type="submit">
              Continue
            </Cta>
          </CtaContainer>
        </Form>
      )}
    </Formik>
  );
};

export default Terms;
