import styled from 'styled-components';

export const Wrap = styled.div`
  padding: 2rem;
  position: relative;
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
`;

export const NavButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  outline: none;
  padding: 0;
`;

export const SettingsButton = styled.span`
  margin-left: auto;
  cursor: pointer;
  font-size: 1.25rem;
`;

export const Settings = styled.div`
${({theme}) => `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${theme.colors.white};
  padding: 2rem;
  `}
`;

export const CloseIcon = styled.span`
  top: 2rem;
  right: 2rem;
  position: absolute;
  cursor: pointer;
`;

export const Label = styled.label`
  ${({ theme }) => `
    font-family: ${theme.fonts.intro};
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    display: block;
  `}
`;

export const Title = styled.h2`
  margin-bottom: 0;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0 1.25rem;
`;

export const Subheader = styled.h3`
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const Tracks = styled.div``;

export const Lectures = styled.div``;

export const Link = styled.a`
  display: flex;
  justify-content: space-between;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
`;

export const Details = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 1rem;
    width: 20px;
    display: inline-block;
    text-align: center;
  }
`;

export const PlayIcon = styled.span`
  font-size: 1.5rem;

  ${({ theme }) => `
    color: ${theme.colors.blue}
  `}
`;
