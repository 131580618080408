import { createSelector } from 'reselect';
import { countries } from '@mc/config/countries';

export const getMeta = createSelector(
  state => state.config.meta,
  meta => meta
);

export const getCountry = createSelector(
  state => state.config.country,
  country => {
    const selectedCountry = countries.find(countryMeta => countryMeta.code === country);

    return selectedCountry;
  }
);
