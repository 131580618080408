import React from 'react';
import { Wrap } from './RichTextField.styled';

const StoryblokClient = require('storyblok-js-client');

const Storyblok = new StoryblokClient({
  accessToken: 'FI8tX9zawVJMcQKBnZrvfQtt',
});

function createMarkup(storyblokHTML) {
  return {
    __html: Storyblok.richTextResolver.render(storyblokHTML),
  };
}

const RichTextField = ({ data, className, children }) =>
  children ? (
    <Wrap className={className}>{children}</Wrap>
  ) : (
    <Wrap dangerouslySetInnerHTML={createMarkup(data)} className={className} />
  );

export default RichTextField;
