import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { auth } from '@mc/config/firebase';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import FormFieldGroup from '@mc/components/_form/form-field-group/FormFieldGroup';
import FormLabel from '@mc/components/_form/form-label/FormLabel';
import FormInput from '@mc/components/_form/form-input/FormInput';
import FormButton from '@mc/components/_form/form-button/FormButton';
import { Wrap, Col, Logo, Title, Illustration, Description } from '../login/Login.styled';
import { getUser } from '@mc/redux/auth/auth.selectors';
import FormError from '@mc/components/_form/form-error/FormError';
import { getHeaderData } from '@mc/redux/app/app.selectors';

const MigrateAccountSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9@$=!:.#%-]+$)/,
      'Password must contain at least one number, one uppercase letter and one lowercase letter'
    )
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
});

const SlackMigration = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(getUser);
  const header = useSelector(getHeaderData);

  useEffect(() => {
    const doAuthChecks = async () => {
      if (!auth().currentUser) {
        return history.push('/login');
      }

      const userHasPassword = auth().currentUser.providerData.some(providerData => providerData.providerId === 'password');
      if (userHasPassword) {
        toast.error('Please login with the email and password associated with this account. Slack sign on is no longer supported.');
        history.push('/login');
        await auth().signOut();
      }
    };

    doAuthChecks();
  }, [history, location, user]);

  const handleUpdateAccount = async ({ email, password }) => {
    const credential = auth.EmailAuthProvider.credential(email, password);

    try {
      await auth().currentUser.linkWithCredential(credential);
      await auth().signOut();

      toast.success('Your account has been updated. Please login with your new email and password.');
    } catch (ex) {
      console.error(ex);

      if (ex.code === 'auth/requires-recent-login') {
        await auth().signOut();
        history.push('/login');

        return toast.error('Your user session has expired. Please use the Sign in with Slack button again to renew your session.');
      }

      if (ex.code === 'auth/email-already-in-use') {
        return toast.error('This email address is already in use. Please get in touch with us if you need assistance.');
      }

      toast.error('Your account couldn\'t be updated. Please get in touch with us if you need assistance.');
    }
  };

  return (
    <Wrap>
      <Col noBottomPad>
        <Logo src={header.logo.src} alt={header.logo.alt} />
        <Title>Please update your account</Title>
        <Illustration src="/images/illustrations/login.svg" alt=""></Illustration>
      </Col>
      <Col>
        <Formik onSubmit={handleUpdateAccount} validationSchema={MigrateAccountSchema} initialValues={{ email: '', password: '', confirmPassword: '' }}>
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form>
              <Description>We no longer support single sign on with Slack. Please specify a new email address and password for your account in order to carry on using the platform.</Description>
              <FormFieldGroup>
                <FormLabel htmlFor="email">Email</FormLabel>
                <FormInput id="email" value={values.email} onChange={handleChange} onBlur={handleBlur}></FormInput>
                {errors.email && touched.email ? <FormError>{errors.email}</FormError> : null}
              </FormFieldGroup>
              <FormFieldGroup>
                <FormLabel htmlFor="password">Password</FormLabel>
                <FormInput id="password" type="password" value={values.password} onChange={handleChange} onBlur={handleBlur}></FormInput>
                {errors.password && touched.password ? <FormError>{errors.password}</FormError> : null}
              </FormFieldGroup>
              <FormFieldGroup>
                <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
                <FormInput id="confirmPassword" type="password" value={values.confirmPassword} onChange={handleChange} onBlur={handleBlur}></FormInput>
                {errors.confirmPassword && touched.confirmPassword ? <FormError>{errors.confirmPassword}</FormError> : null}
              </FormFieldGroup>
              <FormButton>Update Account</FormButton>
            </Form>
          )}
        </Formik>
      </Col>
    </Wrap>
  );
};

export default SlackMigration;
