import React from 'react';
import { countries } from '@mc/config/countries';
import { Wrap, CountryCodeWrap, PhoneNumberWrap } from './FormPhoneInput.styled';
import FormSelect from '../form-select/FormSelect';
import { FormikInput } from '../form-input/FormInput.styled';

const FormPhoneInput = ({
  countryCodeName,
  phoneNumberName,
  countryCodeValue,
  phoneNumberValue,
  onCountryCodeBlur,
  onPhoneNumberBlur,
  onCountryCodeChange,
  onPhoneNumberChange,
  autoFocus,
}) => {
  const sortedCountries = countries
    .filter((country, index, countriesArr) => countriesArr.indexOf(country) === index)
    .sort((countryA, countryB) => {
      const friendlyA = countryA.dialCode.replace('+', '');
      const friendlyB = countryB.dialCode.replace('+', '');

      if (friendlyA < friendlyB) {
        return -1;
      }

      if (friendlyA > friendlyB) {
        return 1;
      }

      return 0;
    });

  return (
    <Wrap>
      <CountryCodeWrap>
        <FormSelect
          name={countryCodeName}
          value={countryCodeValue}
          onChange={onCountryCodeChange}
          onBlur={onCountryCodeBlur}
        >
          {sortedCountries.map(country => (
            <option key={`${country.name}_${country.dialCode}`} value={country.code}>
              {country.dialCode}
            </option>
          ))}
        </FormSelect>
      </CountryCodeWrap>
      <PhoneNumberWrap>
        <FormikInput
          name={phoneNumberName}
          value={phoneNumberValue}
          onChange={onPhoneNumberChange}
          onBlur={onPhoneNumberBlur}
          autoFocus={autoFocus}
        ></FormikInput>
      </PhoneNumberWrap>
    </Wrap>
  );
};

export default FormPhoneInput;
