import styled from 'styled-components';

export const Wrap = styled.button`
  ${({ theme }) => `
    cursor: pointer;
    width: 34px;
    height: 34px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    appearance: none;
    border: 0;
    background: transparent;
    padding: 0;

    @media (min-width: ${theme.screens.xl}) {
      display: none;
    }
  `}
`;

export const Slice = styled.div`
  ${({ theme, width }) => `
    width: ${width ? `${width}%` : '34px'};
    height: 2px;
    background-color: ${theme.colors.dark};
    pointer-events: none;
    margin: 3px 0;
  `}
`;
