import React from 'react';
import styled from 'styled-components';
import { CardElement } from '@stripe/react-stripe-js';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Container from '../container/Container';
import { Card } from '@mc/components/dashboard/Dashboard.styled';
import FormLabel from '@mc/components/_form/form-label/FormLabel';
import FormSelect from '@mc/components/_form/form-select/FormSelect';
import FormInput from '@mc/components/_form/form-input/FormInput';
import FormCheckbox from '@mc/components/_form/form-checkbox/FormCheckbox';
import { inputStyles } from '@mc/components/_form/form-input/FormInput.styled';

// Build

export const Wrap = styled(Container)`  
  ${({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 4rem;
  `}
`;

export const Main = styled(Card)`
  ${({ theme }) => `
    padding: 1.5rem;
    margin-bottom: 1.5rem;

    @media (min-width: ${theme.screens.md}) {
      padding: 5rem;
    }

    @media (min-width: ${theme.screens.lg}) {
      width: 68%;
      margin-bottom: 0;
    }
  `}
`;

export const Panel = styled.div`
  ${({ theme }) => `  
    background-color: ${theme.colors.lightGrey};
    border: 1px solid ${theme.colors.midGrey};
    justify-content: space-around;
    margin-bottom: 2rem;
    display: none;

    @media (min-width: ${theme.screens.lg}) {
      display: flex;
    }
  `}
`;

export const PanelItem = styled.div`
  ${({ theme }) => `
    position: relative;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    padding: 1rem 0;
  `}
`;

export const PanelNumber = styled.span`
  ${({ theme, active }) => `
    background-color: ${active ? theme.colors.dark : 'transparent'};
    border: 2px solid ${theme.colors.dark};
    color: ${active ? theme.colors.white : theme.colors.dark};
    max-width: 24px;
    max-height: 24px;
    min-width: 24px;
    min-height: 24px;
    // Offset alignment
    padding-top: 2px;
    display: inline-block;
    font-family: ${theme.fonts.intro};
    font-weight: bold;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  `}
`;

export const PanelText = styled.span`
  font-weight: bold;
`;

export const Section = styled.div`
  ${({ center }) => `
    margin-bottom: 2rem;

    ${
      center
        ? `
      text-align: center;
    `
        : ``
    }
  `}
`;

export const Title = styled.h2`
  margin-bottom: 1.25rem;
`;

export const Subheading = styled.h3`
  margin-bottom: 0.75rem;
`;

export const Fieldset = styled.fieldset`
  flex-grow: 1;
  border: none;
  padding: 0;
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const FieldGroup = styled.div`
  ${({ theme }) => `
    margin: 0 -0.75rem 2rem;

    @media (min-width: ${theme.screens.md}) {
      display: flex;
    }

    > ${Fieldset} {
      padding: 0 0.75rem;

      @media (min-width: ${theme.screens.md}) {
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  `}
`;

export const Button = styled.button`
${({theme}) => `
  border: 0;
  border-bottom: 1px solid #838b91;

  padding-bottom: 2px;
  padding: 0;
  margin-top: 0.75rem;
  font-size: 1rem;
  background: transparent;
  appearance: none;
  font-family: inherit;

  color: ${theme.colors.blue};
  cursor: pointer;

  :hover {
    color: ${theme.colors.blueHover};
  }


  `}
`;

export const Select = styled(FormSelect)``;

export const Input = styled(FormInput)``;

export const Label = styled(FormLabel)``;

export const RadioLabel = styled(FormLabel)`
  cursor: pointer;
  flex: 1;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.4;
  position: relative;
  margin-bottom: 20px;
`;

export const RadioInput = styled(FormInput)`
${({theme}) => `
  -webkit-appearance: none;
  background-color: ${theme.colors.midGrey};
  border-radius: 1rem;
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 17px;
  margin: 0;
  min-width: 20px;
  outline: none;
  padding: 8px;
  position: absolute;

  &:checked {
    border-color: ${theme.colors.dark};
  }

  &:before,
  &:checked:before {
    color: ${theme.colors.white};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  &:checked:before {
    content: ' ';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${theme.colors.dark};
    font-size: 11px;
    top: 4px;
    left: 4px;
  }
  `}
`;

export const RadioText = styled.p`
  line-height: initial;
  padding-left: 2rem;
  font-size: 0.875rem;
`;

export const Checkbox = styled(FormCheckbox)``;

export const CtaContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Summary = styled(Card)`
  ${({ theme }) => `
    padding: 2rem;

    @media (min-width: ${theme.screens.lg}) {
      width: 30%;
    }
  `}
`;

export const Subheader = styled.h3`
  font-size: 1.125rem;
  margin-bottom: 1rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
`;

export const RowLabel = styled.span`
  font-size: 0.875rem;

  span {
    opacity: 0.6;
  }
`;

export const RowValue = styled.span`
  font-size: 0.875rem;
`;

export const Separator = styled.hr`
  border: 0;
  border-top: 1px solid #eaeaea;
  height: 0;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

export const Footnote = styled.span`
  font-size: 0.75rem;
`;

export const PaymentOptions = styled.div`
  margin-bottom: 2rem;
`;

export const CardForm = styled(CardElement)`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border: 2px solid ${({ theme }) => theme.colors.midGrey};
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  outline: 0;
`;

export const Error = styled.span`
  color: ${({theme}) => theme.colors.red};
  font-size: 0.875rem;
  font-weight: bold;
  display: block;
  margin-top: 0.5rem;
`;

export const ErrorAlert = styled(Card)`
  border-left: 3px solid red;
  padding: 1rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
`;

export const Image = styled.img`
  max-width: 400px;
  margin: 0.5rem 0 3rem;
`;

export const PlacesAutoComplete = ({ className, ...props }) => (
  <GooglePlacesAutocomplete {...props} inputClassName={className}></GooglePlacesAutocomplete>
);

export const PlaceSearch = styled(PlacesAutoComplete)`
  ${inputStyles}
`;

export const Description = styled.div`
  font-size: 0.875rem;
`;

export const TextFrame = styled.iframe`
  ${({ theme }) => `
    border: 1px solid ${theme.colors.midGrey};
    width: 100%;
    height: 400px;
    overflow: auto;
  `}
`;
