import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import ReactPixel from 'react-facebook-pixel';
import { auth, firestore } from '@mc/config/firebase';
import LayoutRoute from './components/_utilities/LayoutRoute';
import { getUser } from './redux/auth/auth.selectors';
import FixedWidthLayout from './components/_layouts/fixed-width-layout/FixedWidthLayout';
import AuthLayout from './components/_layouts/auth-layout/AuthLayout';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/_pages/login/Login';
import GlobalStyle from './components/_utilities/GlobalStyle';

import { setUser } from './redux/auth/auth.slice';
import { flattenFirebaseUser } from './helpers/auth';

import Loader from './components/_partials/loader/Loader';
import Module from './components/module/Module';
import { setContent } from './redux/app/app.slice';
import Learner from './components/learner/Learner';
import ForgotPassword from './components/_pages/forgot-password';
import SlackAuth from './components/_pages/slack-auth';
import SlackMigration from './components/_pages/slack-migration';
import ChangePassword from './components/_pages/change-password';
import CreateAccount from './components/_pages/create-account';
import ChooseCohort from './components/choose-cohort/ChooseCohort';
import Checkout from './components/checkout/Checkout';
import getTheme from './redux/theme/theme.selector';
import EntryExam from './components/entry-exam/EntryExam';
import MyAccount from './components/my-account/MyAccount';

const App = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useSelector(getTheme);

  useEffect(() => {
    ReactPixel.pageView();
  }, [location]);

  useEffect(() => {
    auth().onAuthStateChanged(async (loggedInUser) => {
      const configDoc = await firestore().doc(`config/config`).get();

      dispatch(setContent(configDoc.data()));

      ReactPixel.init('339320489810107');

      if (!loggedInUser) {
        dispatch(setUser(null));
        setLoading(false);
        return null;
      }

      const profileDoc = await firestore().doc(`users/${loggedInUser.uid}`).get();

      const idTokenResult = await auth().currentUser.getIdTokenResult();
      const { signInProvider } = idTokenResult;

      const user = flattenFirebaseUser({
        ...loggedInUser,
        ...profileDoc.data(),
        role: idTokenResult.claims.admin ? 'admin' : 'user',
        signInProvider,
        token: idTokenResult.token,
      });

      dispatch(setUser(user));

      try {
        const today = new Date().toLocaleDateString();

        // check if we already ran the code today
        const didRunToday = localStorage.getItem('didRunToday') === today;
    
        if (!didRunToday) {
          await fetch(`https://hooks.zapier.com/hooks/catch/11857442/33xuht0/`, {
            method: 'POST',
            body: JSON.stringify({
              email: user.email,
              date: new Date(),
              firstName: user.firstName,
              lastName: user.lastName,
            }),
          });
          // store today's date in local storage
          localStorage.setItem('didRunToday', today);
        }
      } catch (ex) {
        console.error(ex.message);
      }

      try {
        ReactPixel.init(
          '339320489810107',
          {
            em: user.email,
            fn: user.firstName,
            ln: user.lastName,
            db: user.dob?.toString().replace(/-/g, ''),
          },
          {
            autoConfig: true,
            debug: false,
          }
        );
      } catch (ex) {
        console.error(ex);
      }
      
      if (signInProvider === 'custom') {
        history.push('/slack/migrate');
      }

      setLoading(false);
    });
  }, [dispatch, history]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <>
          {loading ? (
            <Loader />
          ) : (
            <Switch>
              <LayoutRoute
                exact
                path="/"
                layout={user ? FixedWidthLayout : AuthLayout}
                component={user ? Dashboard : Login}
              />
              <LayoutRoute
                exact
                path="/forgot-password"
                layout={user ? FixedWidthLayout : AuthLayout}
                component={user ? Dashboard : ForgotPassword}
              />
              <LayoutRoute exact path="/auth/action" layout={AuthLayout} component={ChangePassword} />
              <LayoutRoute
                exact
                path="/login"
                layout={user ? FixedWidthLayout : AuthLayout}
                component={user ? Dashboard : Login}
              />
              <LayoutRoute
                exact
                path="/create-account"
                layout={user ? FixedWidthLayout : AuthLayout}
                component={user ? Dashboard : CreateAccount}
              />
              <LayoutRoute exact path="/slack/migrate" layout={AuthLayout} component={user ? SlackMigration : Login} />
              <LayoutRoute exact path="/slack/auth" layout={AuthLayout} component={user ? SlackMigration : SlackAuth} />
              <LayoutRoute
                exact
                path="/module/:moduleSlug"
                layout={user ? FixedWidthLayout : AuthLayout}
                component={user ? Module : Login}
              />
              <LayoutRoute
                exact
                path="/module/:moduleSlug/:chapterSlug"
                layout={user ? FixedWidthLayout : AuthLayout}
                component={user ? Learner : Login}
              />
              <LayoutRoute
                exact
                path="/choose-cohort"
                layout={user ? FixedWidthLayout : AuthLayout}
                component={user ? ChooseCohort : Login}
              />
              <LayoutRoute
                exact
                path="/checkout"
                layout={user ? FixedWidthLayout : AuthLayout}
                component={user ? Checkout : Login}
              />
              <LayoutRoute
                exact
                path="/entry-exam"
                layout={user ? FixedWidthLayout : AuthLayout}
                component={user ? EntryExam : Login}
              />
              <LayoutRoute
                exact
                path="/account"
                layout={user ? FixedWidthLayout : AuthLayout}
                component={user ? MyAccount : Login}
              />
            </Switch>
          )}
          <GlobalStyle />
          <ToastContainer position="bottom-right" />
        </>
      </ThemeProvider>
    </div>
  );
};

export default App;
