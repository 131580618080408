import styled from 'styled-components';

export const Wrap = styled.div`
  position: relative;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Rail = styled.div`
  ${({ theme }) => `
    position: absolute;
    width: 100%;
    top: 15px;
    height: 3px;
    background-color: ${theme.colors.lightGrey};
  `}
`;

export const Step = styled.div`
  ${({ theme, active, completed }) => `
    width: 30px;
    height: 30px;
    background-color: ${active || completed ? theme.colors.dark : theme.colors.lightGrey};
    color: ${active || completed ? theme.colors.white : theme.colors.dark};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    font-size: 13px;
    font-weight: 500;
  `}
`;
