import styled from 'styled-components';
import Container from '../container/Container';

export const Wrap = styled(Container)`
  ${({ theme }) => `
    padding: 0 1.5rem;

    @media (min-width: ${theme.screens.md}) {
      padding: 0 2.5rem;
    }
  `}
`;

export const Greeting = styled.div`
  ${({ theme }) => `
    font-size: 24px;
    font-family: ${theme.fonts.intro};
    font-weight: bold;
    margin-bottom: 30px;
  `}
`;

export const Blocks = styled.div`
  margin-bottom: 30px;
`;

export const Grid = styled.div`
  ${({ theme }) => `
    margin-bottom: 30px;
    
    @media (min-width: ${theme.screens.md}) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      grid-template-rows: 120px auto;
      grid-row-gap: 20px;
      grid-auto-flow: column;
    }
  `}
`;

export const Card = styled.div`
${({theme}) =>`
  box-shadow: 0 8px 20px 0 rgba(49, 62, 81, 0.05);
  background: ${theme.colors.bgSecondary};
  border-radius: 5px;
  overflow: hidden;
`}`;

export const CourseCard = styled.div`
  margin-bottom: 30px;
`;

export const ErrorCard = styled(Card)`
  ${({ theme }) => `
    margin-bottom: 30px;
    background: ${theme.colors.red};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.white};
    text-align: center;
    font-weight: bold;
    line-height: 1.375;
    padding: 1.25rem 2rem;

    @media (min-width: ${theme.screens.md}) {
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
    }
    
    span {
      display: inline-block;
      margin-bottom: 1rem;

      @media (min-width: ${theme.screens.md}) {
        margin-bottom: 0;
      }  
    }
  `}

`;

export const ChatCtaCard = styled.div`
  margin-bottom: 30px;

  /* ${({ theme }) => `
    @media (min-width: ${theme.screens.md}) {
      margin-bottom: 0;
    }
  `} */
`;

export const CodeChallengeCard = styled(Card)`
  margin-bottom: 30px;

  ${({ theme }) => `
    @media (min-width: ${theme.screens.md}) {
      margin-bottom: 0;
    }
  `}
`;

export const ScheduleCard = styled(Card)`
  grid-row: 1 / 3;
  margin-bottom: 30px;
`;

export const DaysOfCodeCard = styled.div`
  margin-bottom: 30px;
`;

export const EnrolCard = styled(Card)`
  ${({ theme }) => `
    margin-bottom: 30px;
    background: ${theme.colors.green};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.white};
    text-align: center;
    font-weight: bold;
    line-height: 1.375;
    padding: 1.25rem 2rem;

    @media (min-width: ${theme.screens.md}) {
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
    }
    
    span {
      display: inline-block;
      margin-bottom: 1rem;

      @media (min-width: ${theme.screens.md}) {
        margin-bottom: 0;
      }  
    }
  `}
`;
