import React from 'react';
import styled from 'styled-components';
import { Controlled as CodeMirror } from 'react-codemirror2';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 40px 0 15px;
`;

export const Toolbar = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
  height: 60px;
`;

export const Title = styled.h2`
  margin: 0;
  color: black;
  font-size: 1.25rem;
  flex: 1;
`;

export const Actions = styled.div`
  display: flex;
`;

export const TextArea = styled(CodeMirror)`
  font-size: 18px;
  flex: 1;
  display: flex;
  overflow: auto;
  min-height: 200px;

  ${({ exam }) =>
    exam
      ? `
    min-height: 250px;
  `
      : ``}

  .CodeMirror {
    height: auto;
    flex: 1;
  }

  @media (min-width: ${({ theme }) => theme.screens.xl}) {
    min-height: unset;

    ${({ exam }) =>
      exam
        ? `
      min-height: 250px;
    `
        : ``}
  }
`;
