import styled from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import { lighten } from 'polished';
import { Card } from '@mc/components/dashboard/Dashboard.styled';

export const Wrap = styled(Card)`
  ${({ theme }) => `
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${theme.screens.lg}) {
      height: 170px;
      flex-direction: row;
    }
  `}
`;

export const Glyph = styled.div`
  ${({ theme, color }) => `
    background-color: ${lighten(0.35, color)};
    color: ${color};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-bottom: 20px;

    @media (min-width: ${theme.screens.lg}) {
      margin-bottom: 0;
    }
  `}
`;

export const CourseDetails = styled.div`
  ${({ theme, centered }) => `
    height: ${centered ? 'auto' : '100%'};
    flex: ${centered ? 'auto' : 1};
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${theme.screens.lg}) {
      margin-left: 30px;
    }
  `}
`;

export const ModuleName = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.darkAlpha};
    margin-bottom: 0.5rem;
    text-align: center;

    @media (min-width: ${theme.screens.lg}) {
      text-align: left;
    }
  `}
`;

export const ChapterName = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fonts.intro};
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 15px;
    text-align: center;
    line-height: 1.375rem;

    @media (min-width: ${theme.screens.lg}) {
      text-align: left;

      &:only-child {
        margin-bottom: 0;
      }
    }
  `}
`;

export const PageName = styled.div`
  ${({ theme }) => `
    font-weight: 500;
    display: inline-block;
    margin-bottom: 1.5rem;
    text-align: center;

    span.icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.5rem;
      font-size: 1.25rem;
    }

    @media (min-width: ${theme.screens.lg}) {
      margin-bottom: 0;
      text-align: left;
    }
  `}
`;

export const Progress = styled(CircularProgressbar)`
  ${({ theme }) => `
    width: 100px;
    height: auto;
    margin-bottom: 1.5rem;

    @media (min-width: ${theme.screens.lg}) {
      margin-right: 4rem;
      margin-bottom: 0rem;
    }

    .CircularProgressbar-trail {
      stroke: #c1c5c8;
    }

    .CircularProgressbar-path {
      stroke: ${theme.colors.dark};
    }

    .CircularProgressbar-text {
      font-family: Intro, Arial, sans-serif;
      font-size: 1.25rem;
      font-weight: bold;
      fill: ${theme.colors.dark};
      dominant-baseline: mathematical;
    }
  `}
`;

export const Placeholder = styled(Card)`
${({theme}) => `
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, ${theme.colors.white} 8%, ${theme.colors.midGrey} 18%, ${theme.colors.white} 33%);
  background-size: 1000px 100%;
  width: 100%;
  height: 170px;

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`}
`;
