import styled from 'styled-components';
import Container from '@mc/components/container/Container';

export const Wrap = styled(Container)`
  ${({ theme }) => `
    position: relative;
  `}
`;

export const Inner = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    @media (min-width: ${theme.screens.xl}) {
      flex-wrap: no-wrap;
      padding: 3rem 0;
    }
  `}
`;

export const Logo = styled.img`
  ${({ theme }) => `
    width: 32px;
    height: 32px;
    vertical-align: middle;
    margin-bottom: 1.5rem;

    @media (min-width: ${theme.screens.xl}) {
      margin-bottom: 0;
    }
  `}
`;

export const Section = styled.div`
  ${({ wide, theme }) => `
    width: ${wide ? '100%' : '50%'};
    margin-bottom: 1rem;
    padding-right: 1rem;

    @media (min-width: ${theme.screens.xl}) {
      width: auto;
      margin-bottom: 0;
      margin-right: 5rem;
      padding-right: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  `}
`;

export const SubNav = styled.div``;

export const SubNavItem = styled.div`
  margin: 1rem 0;
  line-height: 1.5;
  font-size: 14px;
`;

export const Address = styled.pre`
  white-space: pre-wrap;
  line-height: 1.5;
  font-family: inherit;
`;

export const Icon = styled.span`
  vertical-align: text-top;
  display: inline-block;
  margin-right: 0.5rem;
`;

export const Contact = styled.div`
  display: flex;
  align-items: center;
  margin: 0.75rem 0;
  font-size: 14px;
`;

export const Legal = styled(Container)`
  ${({ theme }) => `
    padding: 0 0 2.5rem;
    font-size: 12px;

    @media (min-width: ${theme.screens.lg}) {
      display: flex;
      justify-content: space-between;
      padding: 0 0 2.5rem;
    }
  `}
`;

export const LegalCopyright = styled.div`
  ${({ theme }) => `
    margin-top: 0.75rem;

    @media (min-width: ${theme.screens.lg}) {
      margin-top: 0;
    }
  `}
`;

export const LegalLinks = styled.div``;

export const Pip = styled.div`
  ${({ theme }) => `
    width: 4px;
    height: 4px;
    border-radius: 50%;
    position: relative;
    vertical-align: middle;
    margin: 0 0.5rem;
    display: inline-block;
    background: ${theme.colors.midGrey};
  `}
`;
