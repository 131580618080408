import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Container from '../container/Container';
import { Card } from '@mc/components/dashboard/Dashboard.styled';
import Cta from '@mc/components/_partials/cta/Cta';
import { darken } from 'polished';

export const Wrap = styled(Container)``;

export const ContentOuter = styled.div`
  ${({ theme }) => `
    @media (min-width: ${theme.screens.md}) {
      min-height: 600px;
    }
  `}
`;

export const Content = styled(Card)`
  ${({ theme }) => `
    margin-bottom: 1.5rem;
    padding: 1.5rem;

    @media (min-width: ${theme.screens.xl}) {
      padding: 2.5rem;
    }
  `}
`;

export const Pagination = styled.div`
  ${({ theme, $opaque }) => `
    margin-bottom: 3rem;
    overflow: inherit;
    
    @media (min-width: ${theme.screens.xl}) {
      position: sticky;
      top: 30px;
      z-index: 1;
      width: 370px;
      opacity: ${$opaque ? 1 : 0.25};
      transition: opacity 0.1s ease-in;

      &:hover {
        opacity: 1;
      }
    }
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => `
    margin-bottom: 15px;
  `}
`;

export const Progress = styled.div`
  margin-bottom: 1.5rem;

  svg {
    max-width: 100%;
  }
`;

export const ProgressBar = styled.div`
  background-color: ${({theme}) => theme.colors.lightGrey};
  border-radius: 4px;
  height: 8px;
`;

export const ProgressBarInner = styled(ProgressBar)`
  ${({ theme, percentage = 0 }) => `
    background-color: ${theme.colors.green};
    width: ${percentage}%;
  `}
`;

export const ProgressHeader = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: inline-block;
`;

export const Subheading = styled.span`
  opacity: 0.6;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  display: inline-block;
`;

export const Description = styled.p`
  line-height: 24px;
  font-size: 0.875rem;
`;

export const Selected = styled.button`
  ${({ theme, menuOpen }) => `
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    font-family: ${theme.fonts.body};
    appearance: none;
    padding: 0;
    background: ${theme.colors.blue};
    color: #000;
    border: 0;
    border-radius: 8px;
    z-index: 100;
    cursor: pointer;
    position: relative;
    padding: 1.5rem 3rem 1.5rem 1.25rem;
    height: 100%;
    text-align: left;
    line-height: 1.5;
    width: 100%;
    transition: ease-in;
    box-shadow: 0 8px 20px 0 rgb(49 62 81 / 5%);

    &:hover {
      background: ${theme.colors.blueHover};
    }

    &:focus {
      background: ${theme.colors.blueHover};
      color: #000;
    }

    ${
      menuOpen
        ? `
      background: ${theme.colors.darkAlpha};
      color: ${theme.colors.white};

      ${ChevronIcon} {
        transform: rotate(180deg);
      }
    `
        : ``
    }
  `}
`;

export const SelectedIcon = styled.span`
  margin-right: 15px;
  font-size: 1.5rem;
`;

export const ChevronIcon = styled.span`
  position: absolute;
  right: 20px;
  font-size: 14px;
`;

export const Select = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

export const ClickCatch = styled.div`
  background-color: rgba(49, 62, 71, 0.10);
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Menu = styled.div`
  ${({ theme }) => `
    box-shadow: 0 0 12px 0 rgba(49, 62, 71, 0.10);
    bottom: 73px;
    z-index: 100;
    cursor: default;
    position: absolute;
    background: ${theme.colors.bgSecondary};
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    padding-bottom: 0;
    right: 0;
    left: 0;
    border-radius: 8px;

    @media (min-width: ${theme.screens.xl}) {
      bottom: auto;
      top: 73px;
      height: calc(100vh - 90px - 73px); // viewport height - (sticky gutter + (padding * 2)) - button height
    }
  `}
`;

export const MenuSubheading = styled.span`
  opacity: 0.6;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

export const MenuHeading = styled.h4`
  margin-bottom: 1rem;
`;

export const Options = styled.div`
  margin-left: -3px;
`;

export const OptionWrap = styled.div`
  ${({ theme }) => `
    height: 300px;
    overflow-y: scroll;
    padding-bottom: 50px;

    @media (min-width: ${theme.screens.xl}) {
      height: 100%;
    }
  `}
`;

export const Option = styled.div`
  ${({currentPage, theme}) => `
    border-radius: 30px;
    padding: 2px;

    ${currentPage ? `
      background-color: ${theme.colors.midGrey};
    ` : ''}

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    a {
      color: inherit;
    }
  `}
`;

export const OptionLink = styled(Link)`
  border-radius: 20px;
  padding: 3px;
  padding-right: 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
`;

export const OptionIcon = styled.span`
  ${({ theme, completed }) => `
    background-color: ${completed ? theme.colors.green : '#ebebeb'};
    color: ${completed ? 'white' : 'inherit'};
    width: 21px;
    height: 21px;
    // Offset alignment
    padding-top: 1px;
    display: inline-flex;
    font-family: ${theme.fonts.body};
    font-size: 12px;
    font-weight: 500;
    border-radius: 9999px;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  `}
`;

export const Gradient = styled.div`
  background-image: linear-gradient(to bottom, transparent, ${({theme}) => theme.colors.bg});
  height: 40px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const Nav = styled.div`
  ${({ theme }) => `
    a:hover {
      color: inherit;
    }
  `}
`;

export const Button = styled(Cta)`
  ${({ theme }) => `
    width: 100%;
  `}
`;

export const ArrowLeftIcon = styled.i`
  ${({ theme }) => `
    display: inline-block;

    @media (min-width: ${theme.screens.md}) {
      margin-right: 0.5rem;
      font-size: 1rem;
    }
  `}
`;

export const ArrowRightIcon = styled.i`
  ${({ theme }) => `
    display: inline-block;

    @media (min-width: ${theme.screens.md}) {
      margin-left: 0.5rem;
      font-size: 1rem;
    }
  `}
`;

export const CompleteButton = styled(Cta)`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 15px 0;
  flex: 1;
  width: 100%;

  span.icon {
    position: relative;
    bottom: 2px;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CompleteButtonIcon = styled.span``;

export const Columns = styled.div`
  ${({ theme }) => `
    @media (min-width: ${theme.screens.xl}) {
      display: flex;
    }
  `}
`;

export const MainCol = styled.div`
  ${({ theme }) => `
    @media (min-width: ${theme.screens.xl}) {
      flex: 1;
      width: calc(100% - 400px);
    }
  `}
`;

export const Sidebar = styled.div`
  ${({ theme }) => `
    @media (min-width: ${theme.screens.xl}) {
      min-width: 400px;
      width: 400px;
      max-width: 400px;
      padding-left: 30px;
    }
  `}
`;

export const SidebarInner = styled(Card)`
  ${({ theme }) => `
    padding: 1.5rem;

    @media (min-width: ${theme.screens.xl}) {
      padding: 2.5rem;
    }
  `}
`;

export const PaginationLoader = styled.div`
  padding: 50px;
`;


export const SlackWrap = styled(Card)`
  margin-bottom: 30px;
`;

export const SlackText = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.grey};
    font-size: 0.875rem;
    margin-bottom: 15px;
    margin-right: 10px;

    @media (min-width: ${theme.screens.xl}) {
      margin-bottom: 0;
    }
  `}
`;
