import styled from 'styled-components';
import { Card } from '@mc/components/dashboard/Dashboard.styled';

export const Wrap = styled(Card)`
  ${({ theme }) => `
    padding: 2rem;
    height: 100%;
    align-items: center;

    @media (min-width: ${theme.screens.xl}) {
      display: flex;
    }
  `}
`;

export const ImageWrap = styled.div``;

export const Logo = styled.img`
  display: block;
  margin-bottom: 0.5rem;
`;

export const Text = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.grey};
    font-size: 0.875rem;
    margin-bottom: 15px;

    @media (min-width: ${theme.screens.xl}) {
      margin-bottom: 0;
    }
  `}
`;

export const CtaWrap = styled.div`
  ${({ theme }) => `
    @media (min-width: ${theme.screens.xl}) {
      display: flex;
      justify-content: flex-end;
      flex: 1;
    }

    a:not(:last-child) {
      margin-right: 20px;
    }
  `}
`;
