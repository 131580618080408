import styled from 'styled-components';

const Container = styled.div`
  ${({ theme }) => `  
    margin: 0 auto;
    padding: 1.5rem;

    @media (min-width: ${theme.screens.xl}) {
      padding: 0 2.5rem;
    }
  `}
`;

export const PaddedContainer = styled(Container)`
  ${({ theme }) => `
    padding: 4rem 1.5rem;

    @media (min-width: ${theme.screens.md}) {
      padding: 4rem 2.5rem;
    }

    @media (min-width: ${theme.screens.lg}) {
      padding: 4rem 3rem;
    }
  `}
`;

export default Container;
