import React, { useState, useEffect } from 'react';
import courses from './courses.json';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import {
  Wrap,
  Main,
  Panel,
  PanelItem,
  PanelNumber,
  PanelText,
  Summary,
  Subheader,
  Row,
  RowLabel,
  RowValue,
  Separator,
} from './Checkout.styled';
import Enrolment from './components/Enrolment';
import AdditionalDetails from './components/AdditionalDetails';
import Terms from './components/Terms';
import Payment from './components/Payment';
import Confirmation from './components/Confirmation';
import useFormatPrice from '@mc/hooks/useFormatPrice';
import useCalculateVat from '@mc/hooks/useCalculateVat';

const Checkout = () => {
  const location = useLocation();
  const formatPrice = useFormatPrice();
  const calculateVat = useCalculateVat();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [cohort, setCohort] = useState(null);
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const course = courses.find(course => course.uuid === query.course);

  const checkoutSteps = [
    {
      component: Enrolment,
      name: 'Personal Details',
    },
    {
      component: AdditionalDetails,
      name: 'Additional Details',
    },
    {
      component: Terms,
      name: 'T&Cs',
    },
    {
      component: Payment,
      name: 'Payment',
    },
    {
      component: Confirmation,
      name: 'Confirmation',
    },
  ];
  const CheckoutComponent = checkoutSteps[currentStepIndex].component;
  const [customer, setCustomer] = useState(null);
  const diversityGrant = customer && customer.additional && customer.additional.diversityGrant;

  const handleNextStep = () => {
    setCurrentStepIndex(currentStepIndex + 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStepIndex]);

  useEffect(() => {
    const fetchCohorts = async () => {
      const cohortData = await axios.get(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT}/cohorts`,
        {
          headers: {
            Authorization: `Bearer fryingpanthunderstorm52`,
          }
        }
      );

      const updatedCohort = cohortData.data.find(c => c.id === query.cohort);
      setCohort(updatedCohort);
      setCustomer({
        course: {
          id: 'aca86e3e-b9a4-40ab-923f-c8d7e5688245',
          cohort: updatedCohort.id,
        },
      });
    };

    fetchCohorts();
  }, []); // eslint-disable-line

  if (!cohort) {
    return null;
  }

  const totalExcVat = diversityGrant ? cohort.totalExcVat.gbp - cohort.diversityGrantExcVat.gbp : cohort.totalExcVat.gbp;

  return (
    <Wrap>
      <Main>
        <Panel>
          {checkoutSteps.map((checkoutStep, index) => (
            <PanelItem>
              <PanelNumber active={index === currentStepIndex}>{index + 1}</PanelNumber>
              <PanelText>{checkoutStep.name}</PanelText>
            </PanelItem>
          ))}
        </Panel>
        <CheckoutComponent
          course={course}
          cohort={cohort}
          customer={customer}
          totalExcVat={totalExcVat}
          onUpdateCustomer={setCustomer}
          onNextStep={handleNextStep}
        />
      </Main>
      <Summary>
        <Subheader>Order summary</Subheader>
        <Row>
          <RowLabel>
            <span>Course</span>
          </RowLabel>
          <RowValue>Bootcamp</RowValue>
        </Row>
        {cohort.startDate ? (
          <Row>
            <RowLabel>
              <span>Course Start Date</span>
            </RowLabel>
            <RowValue>{moment(cohort.startDate).format('ddd DD MMM YYYY')}</RowValue>
          </Row>
        ) : null}
        <Separator />
        <Row>
          <RowLabel>
            <span>Subtotal</span>
          </RowLabel>
          <RowValue>{formatPrice(cohort.totalExcVat.gbp, { addVat: false })}</RowValue>
        </Row>
        {diversityGrant ? (
          <Row>
            <RowLabel>
              <span>Diversity Grant</span>
            </RowLabel>
            <RowValue>-{formatPrice(cohort.diversityGrantExcVat.gbp, { addVat: false })}</RowValue>
          </Row>
        ) : null}
        <Row>
          <RowLabel>
            <span>VAT</span>
          </RowLabel>
          <RowValue>{calculateVat(totalExcVat)}</RowValue>
        </Row>
        <Separator />
        <Row>
          <RowLabel>
            <b>Total</b>
          </RowLabel>
          <RowValue>
            <b>{formatPrice(totalExcVat)}</b>
          </RowValue>
        </Row>
      </Summary>
    </Wrap>
  );
};

export default Checkout;
