import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getUser } from '@mc/redux/auth/auth.selectors';
import Cta from '@mc/components/_partials/cta/Cta';
import { getHeaderData } from '@mc/redux/app/app.selectors';
import { setUser } from '@mc/redux/auth/auth.slice';
import { auth } from '@mc/config/firebase';
import { Wrap, SignUpHeading, Col, Logo, Title, Illustration } from '../login/Login.styled';
import { Stepper } from './CreateAccount.styled';
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import StepThree from './steps/StepThree';

const CreateAccount = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(getUser);
  const header = useSelector(getHeaderData);
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, [history, location, user]);

  const handleSubmit = values => {
    setFormValues({ ...formValues, ...values });
    setCurrentStep(currentStep + 1);
  };

  const handleSignup = async values => {
    const newUser = { ...formValues, ...values };

    newUser.consent = !newUser.consent; // Checkbox tick means consent not given
    newUser.phoneNumber = parsePhoneNumberFromString(newUser.phoneNumber, newUser.countryCode).number;
    newUser.dob = new Date(newUser.dob);
    delete newUser.confirmPassword;

    try {
      const { data: userWithCourses } = await axios.post(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT}/enrol/itp`,
        newUser
      );

      await auth().signInWithEmailAndPassword(newUser.email, newUser.password);
      dispatch(setUser(userWithCourses));

      try {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'conversion',
        });
      } catch (e) {}

      toast.success('Your account has been created successfully.');
      history.push('/');
    } catch (ex) {
      console.error(ex.message); // eslint-disable-line
      toast.error('There was a problem creating your account. Please try again later or get in touch with us.');
    }
  };

  const steps = [StepOne, StepTwo, StepThree];
  const StepComponent = steps[currentStep];

  return (
    <>
      <Wrap>
        <Col noBottomPad>
          <Logo src={header.logo.src} alt={header.logo.alt} />
          <Title>Create your free account</Title>
          <Illustration src="/images/illustrations/login.svg" alt=""></Illustration>
        </Col>
        <Col>
          <Stepper steps={[1, 2, 3]} activeStepIndex={currentStep}></Stepper>
          <StepComponent onSubmit={handleSubmit} onSignup={handleSignup}></StepComponent>
        </Col>
      </Wrap>
      <Wrap getStarted>
        <SignUpHeading>Already have an account with us?</SignUpHeading>
        <Cta dark url="/login">
          Login
        </Cta>
      </Wrap>
    </>
  );
};

export default CreateAccount;
