import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Wrap,
  Logo,
  Inner,
  User,
  MobileMenuIcon,
  MobileOverlay,
  MobileMenu,
  MobileUser,
} from './MainHeader.styled';
import Hamburger from '../_partials/hamburger/Hamburger';
import { getUser } from '@mc/redux/auth/auth.selectors';
import UserMenu from '../_partials/user-menu/UserMenu';
import { bodyNoScroll } from '@mc/helpers/utilities';
import { getHeaderData } from '@mc/redux/app/app.selectors';
import { themeSelector } from '@mc/redux/theme/theme.selector';

const Header = ({ hideNav }) => {
  const user = useSelector(getUser);
  const header = useSelector(getHeaderData);
  const theme = useSelector(themeSelector);
  const [menuOpen, setMenuOpen] = useState(false);
  const [, setOpenNavTier] = useState(null);

  useEffect(() => {
    if (menuOpen) {
      bodyNoScroll.add();
    } else {
      bodyNoScroll.remove();
    }

    return () => bodyNoScroll.remove();
  }, [menuOpen]);

  return (
    <>
      <Wrap>
        <Inner isLogo>
          <Link to="/">
            <Logo src={header.logo.src} alt={header.logo.alt} />
          </Link>
        </Inner>
        <User>
          <UserMenu></UserMenu>
        </User>
        <MobileMenuIcon>
          <Hamburger onClick={() => [setMenuOpen(!menuOpen), setOpenNavTier(null)]} />
        </MobileMenuIcon>
      </Wrap>
      {menuOpen ? (
        <>
          <MobileOverlay onClick={() => [setMenuOpen(false), setOpenNavTier(null)]}></MobileOverlay>
          <MobileMenu>
            {user ? (
              <MobileUser>
                <UserMenu></UserMenu>
              </MobileUser>
            ) : null}
          </MobileMenu>
        </>
      ) : null}
    </>
  );
};

export default Header;
