import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormFieldGroup from '@mc/components/_form/form-field-group/FormFieldGroup';
import FormLabel from '@mc/components/_form/form-label/FormLabel';
import FormInput from '@mc/components/_form/form-input/FormInput';
import FormButton from '@mc/components/_form/form-button/FormButton';
import FormError from '@mc/components/_form/form-error/FormError';
import { auth } from '@mc/config/firebase';
import { toast } from 'react-toastify';

const StepOneSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9@$=!:.#%-]+$)/,
      'Password must contain at least one number, one uppercase letter and one lowercase letter'
    )
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
});

const StepOne = ({ onSubmit }) => {
  const handleSubmit = async values => {
    try {
      const signInMethods = await auth().fetchSignInMethodsForEmail(values.email);

      if (signInMethods.length) {
        throw new Error('Email address already in use. Please login to your existing account or reset your password.');
      }

      onSubmit(values);
    } catch (ex) {
      toast.error(ex.message);
      console.error(ex); // eslint-disable-line
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={StepOneSchema}
      initialValues={{ email: '', password: '', confirmPassword: '' }}
    >
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Form>
          <FormFieldGroup>
            <FormLabel htmlFor="email">Email</FormLabel>
            <FormInput id="email" value={values.email} onChange={handleChange} onBlur={handleBlur}></FormInput>
            {errors.email && touched.email ? <FormError>{errors.email}</FormError> : null}
          </FormFieldGroup>
          <FormFieldGroup>
            <FormLabel htmlFor="password">Password</FormLabel>
            <FormInput
              id="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            ></FormInput>
            {errors.password && touched.password ? <FormError>{errors.password}</FormError> : null}
          </FormFieldGroup>
          <FormFieldGroup>
            <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
            <FormInput
              id="confirmPassword"
              type="password"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
            ></FormInput>
            {errors.confirmPassword && touched.confirmPassword ? <FormError>{errors.confirmPassword}</FormError> : null}
          </FormFieldGroup>
          <FormButton>Continue</FormButton>
        </Form>
      )}
    </Formik>
  );
};

export default StepOne;
