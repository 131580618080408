import React, { useEffect } from 'react';
import { Content } from '../MyAccount.styled';
import { auth } from '@mc/config/firebase';
import { toast } from 'react-toastify';

const googleProvider = new auth.GoogleAuthProvider();

const LinkAccount = () => {
  const { providerData } = auth().currentUser;
  const googleAccount = providerData.find(provider => provider.providerId === 'google.com');

  const handleLinkGoogle = async () => {
    try {
      await auth().currentUser.linkWithRedirect(googleProvider);
    } catch (ex) {
      toast.error(ex.message);
    }
  }

  useEffect(() => {
    const result = auth().getRedirectResult().then(result => {
      if (result.credential) {
        // Accounts successfully linked.
        var credential = result.credential;
        var user = result.user;
        console.log(credential, user);
      }
    }).catch(ex => {
      console.log(ex);
      toast.error(ex.message);
    });
  }, []);

  return (
    <Content>
      <h2>Link Account</h2>
      <p style={{ marginBottom: 25 }}>Link your account with other authentication providers for faster sign-in.</p>
      <h4>Google</h4>
      {googleAccount ? <>Your account is linked to Google account <strong>{googleAccount.email}</strong>.</> : <img onClick={handleLinkGoogle} style={{ cursor: 'pointer' }} src="/images/btn-google.png" width="191" height="46" alt="Sign in with Google"></img>}
    </Content>
  );
};

export default LinkAccount;
