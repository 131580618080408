import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
`;

export const CountryCodeWrap = styled.div`
  margin-right: 1rem;
`;

export const PhoneNumberWrap = styled.div`
  flex: 1
`;
