import styled from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import { lighten } from 'polished';
import Container from '@mc/components/container/Container';
import { Card } from '@mc/components/dashboard/Dashboard.styled';

export const Outer = styled(Container)`
  margin-bottom: 3rem;
`;

export const Wrap = styled.div`
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
`;

export const Header = styled.header`
  ${({ centered, theme }) => `
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 2.5rem;
    padding: 1.25rem 1rem 0;

    @media (min-width: ${theme.screens.lg}) {
      padding: 1.25rem 0 0;
    }

    ${centered
      ? `
      align-items: center;
      `
      : ``}
  `}
`;

export const Details = styled.div``;

export const Title = styled.h2`
  margin: 0;
`;

export const Description = styled.p`
  opacity: 0.6;
`;

export const Glyph = styled.div`
  ${({ theme, color }) => `
    background-color: ${lighten(0.35, color || 'transparent')};
    color: ${color};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    border-radius: 50%;
    min-width: 50px;
    min-height: 50px;
    margin-bottom: 20px;

    @media (min-width: ${theme.screens.lg}) {
      margin-right: 30px;
      margin-bottom: 0;
      min-width: 80px;
      min-height: 80px;
    }
  `}
`;

export const Icon = styled.span`
  ${({ theme }) => `
    font-size: 1.25rem;

    @media (min-width: ${theme.screens.xl}) {
      font-size: 2.25rem;
    }
  `}
`;

export const Chapters = styled.div``;

export const Chapter = styled(Card)`
  ${({ theme }) => `
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (min-width: ${theme.screens.lg}) {
      height: 100px;
      padding: 1.25rem 2rem;
      flex-direction: row;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  `}
`;

export const ChapterDetails = styled.div`
  ${({ theme }) => `
    display: flex;
    margin-bottom: 1rem;

    @media (min-width: ${theme.screens.lg}) {
      margin-bottom: 0;
      align-items: center;
    }
  `}
`;

export const ChapterIcon = styled.span`
  margin-right: 1rem;
  font-size: 1.5rem;
`;

export const ChapterName = styled.h3`
  ${({ theme }) => `
    font-family: ${theme.fonts.intro};
    font-weight: bold;
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
    text-align: center;
    line-height: 1.375;

    @media (min-width: ${theme.screens.lg}) {
      margin-bottom: 0;
      flex: 1;
      text-align: left;
    }
  `}
`;

export const Lock = styled.div`
  width: 80px;
  height: 80px;
  font-size: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Progress = styled(CircularProgressbar)`
  ${({ theme }) => `
    width: 100px;
    height: auto;

    @media (min-width: ${theme.screens.lg}) {
      height: 100%;
      width: auto;
      margin-bottom: 0;
    }

    .CircularProgressbar-trail {
      stroke: #c1c5c8;
    }

    .CircularProgressbar-path {
      stroke: ${theme.colors.dark};
    }

    .CircularProgressbar-text {
      font-family: Intro, Arial, sans-serif;
      font-size: 1.25rem;
      font-weight: bold;
      fill: ${theme.colors.dark};
      dominant-baseline: mathematical;
    }
  `}
`;
