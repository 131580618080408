import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    content: {
      header: {
        logo: {
          src: '',
          alt: '',
        },
      },
      footer: {
        email: '',
        logo: {
          src: '',
          alt: '',
        },
        phone: '',
      },
      slack: {
        inviteLink: '',
        inviteLinkExpiry: new Date(),
      },
      labels: {},
    }
  },
  reducers: {
    setContent(state, { payload }) {
      state.content = payload;
      
      if (payload.slack.inviteLinkExpiry) {
        state.content.slack.inviteLinkExpiry = payload.slack.inviteLinkExpiry.toDate();
      }
    }
  },
});

export const { actions, reducer } = appSlice;
export const {
  setContent,
} = actions;
export default reducer;
