import React from 'react';
import {
  Wrap,
  Content,
  Pagination,
  Columns,
  Sidebar,
  MainCol,
  SidebarInner,
} from './MyAccount.styled';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import LinkAccount from './sections/LinkAccount';

const MyAccount = () => {
  let { path, url } = useRouteMatch();

  return (
    <Wrap>
      <Breadcrumb
        links={[
          { url: `/account`, text: 'My Account' },
        ]}
      />
      <Columns>
        <Sidebar>
          <Pagination>
            <SidebarInner>
              <h4>Menu</h4>
              <Link to={`${path}`}>Link Account</Link>
            </SidebarInner>
          </Pagination>
        </Sidebar>
        <MainCol>
          <LinkAccount></LinkAccount>
          {/* <Switch>
            <Route exact path={path} render={LinkAccount} />
          </Switch> */}
        </MainCol>
      </Columns>
    </Wrap>
  );
};

export default MyAccount;
