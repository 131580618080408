import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Input = styled.input`
  ${({ theme }) => `
    appearance: none;
    width: 20px;
    height: 20px;
    min-width: 20px;
    position: relative;
    background: ${theme.colors.midGrey};
    border: 0;
    border-radius: 8px;
    position: relative;
    margin: 0;
    margin-right: 1rem;
    outline: none;

    &:focus {
      border-color: ${theme.colors.dark};
    }

    &:checked {
      background-color: ${theme.colors.blue};
      border: 0;
    }

    &:checked:after {
      content: '\\e92f';
      font-family: icomoon;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 4px;
      left: 4px;
      font-size: 13px;
      color: ${theme.colors.grey};
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => `  
    line-height: 1.5;
    font-size: 14px;

    a {
      display: inline-block;
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        bottom: 5px;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: ${theme.colors.blue};
        z-index: -1;
      }

      &:hover {
        color: ${theme.colors.dark};
      }
    }
  `}
`;
