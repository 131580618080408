import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { getUser } from '@mc/redux/auth/auth.selectors';
import { firestore } from '@mc/config/firebase';
import {
  Outer,
  Modules,
  Module,
  Glyph,
  Icon,
  ModuleDetails,
  ModuleName,
  Lock,
  Progress,
  Courses,
  Course,
  ModuleLink,
} from './CourseList.styled';

const CourseList = () => {
  const user = useSelector(getUser);
  const [courseModules, setCourseModules] = useState([]);
  const [completions, setCompletions] = useState([]);

  const userCourses = (user.courses || []).filter(c => c.activated).map(c => c.id); // We want to know if the course has been activated or not (allows admins to remove access to a course)

  useEffect(() => {
    if (!user) {
      return;
    }

    const fetchModuleData = async () => {
      const { docs: coursesDocs } = await firestore()
        .collection('courses')
        .get();
      const coursesArray = coursesDocs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      const { docs: chaptersDocs } = await firestore()
        .collection('chapters')
        .get();
      const chaptersArray = chaptersDocs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      const { docs: modulesDocs } = await firestore()
        .collection('modules')
        .get();
      const modulesArray = modulesDocs
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter(module => module.courses) // if not added to a course yet then don't show to the user as they can't buy it
        .map(module => ({
          ...module,
          chapters: module.chapters.map(chapterRef => chaptersArray.find(chapter => chapter.id === chapterRef.id)),
          courses: module.courses.map(courseRef => coursesArray.find(course => course.id === courseRef.id)),
        }))
        .map(module => ({
          ...module,
          pageCount: module.chapters.reduce((sum, chapter) => sum + chapter?.pages?.length, 0),
        }))
        .sort((a, b) => a.order - b.order);

      const { docs: completedPagesDocs } = await firestore()
        .collection('pageViews')
        .where('userId', '==', user.uid)
        .where('completed', '==', true)
        .get();
      const completedPages = completedPagesDocs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setCompletions(completedPages);
      setCourseModules(modulesArray);
    };

    fetchModuleData();
  }, [user]);

  return (
    <Outer>
      <Modules>
        {user.entryExam && (
          <ModuleLink to="/entry-exam">
            <Module>
              <Glyph color="#36454f">
                <Icon className="icon icon-checklist"></Icon>
              </Glyph>
              <ModuleDetails>
                <ModuleName>Entry Exam</ModuleName>
              </ModuleDetails>
            </Module>
          </ModuleLink>
        )}
        {courseModules.map(module => {
          const moduleCompletions = completions.filter(completion => completion.moduleId === module.id);
          const progress = moduleCompletions.length
            ? Math.ceil((moduleCompletions.length / module.pageCount) * 100)
            : 0;
          const locked = !module.courses.some(course => userCourses.includes(course.id));
          
          return (
            <ModuleLink
              key={module.id}
              to={
                // Career guidance has multiple courses but, one chapter... Whereas ITP has one of each.
                module?.chapters?.length === 1 && module?.courses?.length === 1
                  ? `/module/${module?.slug}/${module?.chapters?.[0]?.slug}`
                  : `/module/${module?.slug}`
              }
            >
              <Module>
                {module.icon && module.color ? (
                  <Glyph color={module.color}>
                    <Icon className={`icon icon-${module.icon}`}></Icon>
                  </Glyph>
                ) : null}
                <ModuleDetails>
                  <ModuleName>{module.name}</ModuleName>
                </ModuleDetails>
                <Courses>
                  {locked ? module.courses.filter(course => course?.short?.toLowerCase() !== 'flexi').map(course =>
                    course?.short ? (
                      <Course
                        data-html
                        data-tip={`<span>
                        You need to enrol on our <strong>${course.name}</strong> course to access this module
                      </span>`}
                      >
                        {course.short}
                      </Course>
                    ) : null
                  ) : null}
                </Courses>
                {locked ? (
                  <>
                    <Lock>
                      <span className="icon icon-lock-circle"></span>
                    </Lock>
                    <ReactTooltip effect="solid" arrowColor="transparent" backgroundColor="#313E48" />
                  </>
                ) : (
                  <Progress value={progress} text={`${progress}%`} strokeWidth={12}></Progress>
                )}
              </Module>
            </ModuleLink>
          );
        })}
      </Modules>
    </Outer>
  );
};

export default CourseList;
