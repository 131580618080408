import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ${({ theme }) => `
    html {
      &.lock-scroll {
        -webkit-overflow-scrolling: auto;
        overflow: hidden;
        position: fixed;
        width: 100%;
        height: 100%;

        body {
          -webkit-overflow-scrolling: auto;
          overflow: hidden;
          position: fixed;
          width: 100%;
          height: 100%;
        }
      }
    }

    body {
      margin: 0;
      background-color: ${theme.colors.bg};
      color: ${theme.colors.dark};
      font-family: "Gilroy", Helvetica, Arial, sans-serif;
      font-size: 16px;
      overflow-x: hidden;
    }

    img {
      max-width: 100%;
      vertical-align: middle;
    }

    h1, h2, h3 {
      font-family: "Gilroy", Helvetica, Arial, sans-serif;
      font-weight: 700;
      margin: 0 0 10px;
      line-height: 1.15;
    }

    h1 {
      font-size: 1.875rem;

      @media (min-width: ${theme.screens.lg}) {
        font-size: 3rem;
      }
    }

    h2 {
      font-size: 2.125rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    h4 {
      font-family: "Gilroy", Helvetica, Arial, sans-serif;
      font-weight: 700;
      font-size: 20px;
      margin: 0 0 10px;
    }

    a {
      color: ${theme.colors.dark};
      font-weight: 500;
      text-decoration: underline ${theme.colors.midGrey};
      text-decoration-thickness: 1px;
      text-decoration-skip-ink: none;
      text-underline-offset: 3px;
      transition: ease-in;

      &:hover {
        color: ${theme.colors.blue};
        text-decoration: underline ${theme.colors.blue};
        text-decoration-thickness: 2px;
      }
    }

    p {
      margin-top: 0;
      line-height: 1.5;
      color: ${theme.colors.darkGrey};

      &:last-child {
        margin-bottom: 0;
      }
    }

    * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      box-sizing: border-box;
      outline: none;
    }

    *:focus-visible {
      border: 2px solid white;
      outline: 2px solid ${theme.colors.green};
    }
  `}
`;

export default GlobalStyle;
