import React, { useState } from 'react';
import Markdown from 'markdown-to-jsx';
import EmojiConverter from 'emoji-js';
import { MarkdownStyles } from '../markdown/Markdown.styled';
import Code from '../markdown/components/Code';
import YouTube from '../markdown/components/YouTube';
import Editor from '../editor/Editor';
import Console from '../console/Console';

const ExamQuestion = ({ question, answer, onUpdateCode }) => {
  const [output, setOutput] = useState([]);
  const emojiConverter = new EmojiConverter();
  const formattedContent = emojiConverter.replace_colons(question.content || '');

  const handleRunCode = () => {
    try {
      const newOutput = [];

      const { log } = console;
      // eslint-disable-next-line no-console
      console.log = (message) => {
        newOutput.push({ type: 'log', value: JSON.stringify(message) });
      };

      try {
        newOutput.push({
          type: 'return',
          // eslint-disable-next-line no-eval
          value: JSON.stringify(eval(answer)) || 'undefined',
        });
      } catch (ex) {
        // eslint-disable-next-line no-eval
        newOutput.push({ type: 'log', value: `${ex.name}: ${ex.message}` });
      }

      // eslint-disable-next-line no-console
      console.log = log;

      setOutput(newOutput);
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error('Error evaluating code');
    }
  };

  return (
    <>
      <h2>{question.title}</h2>
      <MarkdownStyles>
        <Markdown
          options={{
            overrides: {
              pre: {
                component: Code,
              },
              YouTube: {
                component: YouTube,
              },
              a: {
                props: {
                  target: '_blank',
                },
              },
            },
          }}
        >
          {formattedContent || ''}
        </Markdown>
      </MarkdownStyles>
      <Editor code={answer} setCode={onUpdateCode} initialCode={question.initialCode || ''} onRunCode={handleRunCode} />
      <Console output={output} />
    </>
  );
};

export default ExamQuestion;
