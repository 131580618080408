import {light, dark} from '@mc/config/theme';
import { createSelector } from 'reselect'

const themeSelector = state => state.theme.theme;

const getTheme = createSelector(
  themeSelector,
  (theme) => {
    switch(theme){
      case "light":
          return light;
      case "dark":
        return dark;
      default:
        return light;
    }
  }
);

export default getTheme;

export {themeSelector, getTheme};