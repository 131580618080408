import React from 'react';
import { CtaWrap } from './Cta.styled';

const Cta = ({ url, target, primary, children, className, large, queryParams, white, ...props }) => {
  const ctaProps = {};

  if (url) {
    ctaProps.to = url;
  } else {
    ctaProps.as = 'button';
  }

  if (target) {
    ctaProps.as = 'a';
    ctaProps.href = url;
    ctaProps.target = target;
  }

  return (
    <CtaWrap primary={primary} large={large} className={className} white={white} {...ctaProps} {...props}>
      {children}
    </CtaWrap>
  );
};

export default Cta;
