import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '@mc/redux/auth/auth.selectors';
import { Wrap, Image, Title, Description, Content, Divider } from './LoggedInWrapper.styled';
import Cta from '../_partials/cta/Cta';

const LoggedInWrapper = ({ children }) => {
  const [referrer, setReferrer] = useState('');
  const user = useSelector(getUser);

  useEffect(() => {
    const { pathname, search } = window.location;

    setReferrer(window.btoa(`${pathname}${search}`));
  }, []);

  if (!user) {
    return (
      <Wrap>
        <Image src="https://a.storyblok.com/f/79570/x/80d8c8fad6/undraw_order_ride_xjs4.svg" alt=""></Image>
        <Content>
          <Title>You need to be logged in to access this page</Title>
          <Description>Not got an account with us yet?</Description>
          <Cta url={`get-started?referrer=${referrer}`} primary large>
            Get Started
          </Cta>
          <Divider></Divider>
          <Description>Already have an account?</Description>
          <Cta url={`login?referrer=${referrer}`} primary large>
            Login
          </Cta>
        </Content>
      </Wrap>
    );
  }

  return children || null;
};

export default LoggedInWrapper;
