import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.div`
  ${({ theme, avatar }) => `
    border-radius: 50%;
    line-height: 1;
    ${avatar ? `background-image: url('${avatar}')` : ``};
    background-size: cover;
    background-position: center center;
    background-color: ${theme.colors.midGrey};
    font-family: ${theme.fonts.body};
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 1rem;
    height: 40px;
    width: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: ${theme.screens.xl}) {
      margin-right: 1rem;
    }
  `}
`;

export const Name = styled.span`
  font-weight: 500;
`;

export const Greeting = styled.div`
  font-size: 1rem;
  line-height: 1;
`;

export const AccountLink = styled(Link)`
  ${({ theme }) => `
    display: block;
    color: ${theme.colors.blue};
    margin-top: 0.25rem;
    padding: 5px;

    @media (min-width: ${theme.screens.xl}) {
      display: none;
    }
  `}
`;

export const Dots = styled.div`
  ${({ theme, menuOpen }) => `
    transition: opacity 0.2s;
    display: none;
    width: 2rem;
    height: 1rem;
    margin-left: 0.75rem;
    cursor: pointer;
    opacity: 0.25;

    @media (min-width: ${theme.screens.lg}) {
      display: flex;
      align-items: center;
    }

    ${
      menuOpen
        ? `
      opacity: 1;
    `
        : ``
    }

    &:hover {
      opacity: 1;
    }
  `}
`;

export const Dot = styled.div`
  ${({ theme }) => `
    width: 5px;
    height: 5px;
    min-width: 5px;
    margin: 0 2px;
    border-radius: 2px;
    background: ${theme.colors.dark};
    pointer-events: none;
  `}
`;

export const ClickCatch = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Menu = styled.div`
  ${({ theme }) => `
    top: 70px;
    right: 2.5rem;
    box-shadow: 0 0 12px 0 rgba(49, 62, 81, 0.1);
    display: none;
    position: absolute;
    border-radius: 5px;
    background: ${theme.colors.bgSecondary};
    padding: 0 1rem 0 2rem;

    @media (min-width: ${theme.screens.lg}) {
      display: block;
    }
  `}
`;

export const MenuItem = styled(Link)`
  display: block;
  font-size: 0.875rem;
  margin: 1rem 0;
  text-align: right;
`;
