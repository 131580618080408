import adminReducer from './admin/admin.slice';
import appReducer from './app/app.slice';
import authReducer from './auth/auth.slice';
import configReducer from './config/config.slice';
import courseReducer from './course/course.slice';
import ideReducer from './ide/ide.slice';
import themeReducer from "./theme/theme.slice";

export default {
  admin: adminReducer,
  app: appReducer,
  auth: authReducer,
  config: configReducer,
  course: courseReducer,
  ide: ideReducer,
  theme: themeReducer,
};
