import styled from 'styled-components';
import { darken } from 'polished';

export const Wrap = styled.div`
${({theme, form}) => `
  background: ${theme.colors.white};
  padding: 0;
  position: relative;
  margin-top: 0.5rem;

  @media (min-width: ${theme.screens.xl}) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 0;
  }
  
  ${form ? 
    `
      display: block;
      border: 2px solid ${theme.colors.midGrey};
      background-color: ${theme.colors.lightGrey};

      @media (min-width: ${theme.screens.xl}) {
        display: block;
      }
    ` : ``
    }
  `}
`;

export const CountryButton = styled.button`
${({theme, form}) => `
  display: inline-flex;
  align-items: center;
  font-size: 0.875rem;
  color: ${theme.colors.dark};
  appearance: none;
  border: 0;
  font-family: inherit;
  padding: 0.5rem 0;
  position: relative;
  margin: 0.25rem 0;
  background: ${theme.colors.lightGrey};

  cursor: pointer;

    @media (min-width: ${theme.screens.xl}) {
      padding: 1rem 0.75rem;
      outline: none;
      width: 100%;

      &:hover, &:focus {
        background-color: ${theme.colors.midGrey};
      }
    }

    ${ form ? 
      `
        width: 100%;
        background-color: transparent;
        padding: 1rem;
        display: flex;

        @media (min-width: ${theme.screens.xl}) {
          width: 100%;
        }
      ` : ``
    }
  `}
`;

export const Divider = styled.div`
${({theme}) => `
  height: 1px;
  background: ${theme.colors.midGrey};
  margin: 0.25rem 0;
  width: 100%;

  @media (min-width: ${theme.screens.xl}) {
    display: none;
  }
  `}
`;

export const Flag = styled.img`
  height: 0.875rem;
  width: 20;
  object-fit: contain;
  margin-right: 0.5rem;
`;

export const Name = styled.div`
  line-height: 0;
  font-weight: bold;
`;

export const Chevron = styled.span`
  display: inline-block;
  margin-left: 0.25rem;
  position: absolute;
  font-size: 0.75rem;

  ${({ theme, open }) => `
    right: 0;
    transform: rotate(${open ? '180deg' : '0deg'});
    
    @media (min-width: ${theme.screens.xl}) {
      right: 10px;
    }
  `}
`;

export const ClickCatch = styled.div`
${({ theme }) => `
  z-index: 1999;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (min-width: ${theme.screens.xl}) {
    display: block;
  }
  `}
`;

export const Countries = styled.div`
${({ theme }) => `
  width: 100%;
  background: ${theme.colors.lightGrey};
  padding: 0.5rem 0;
  max-height: 200px;
  overflow: scroll;

  @media (min-width: ${theme.screens.xl}) {
    position: absolute;
    top: 50px;
    z-index: 2000;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  `}
`;

export const CountryDivider = styled.div`
  width: 100%;
`;
