import React from 'react';
import Markdown from 'markdown-to-jsx';
import EmojiConverter from 'emoji-js';
import { MarkdownStyles } from './Markdown.styled';
import Code from './components/Code';
import YouTube from './components/YouTube';
import CodePen from './components/CodePen';

const MarkdownWrapper = ({ page, className, children }) => {
  const content = page && page.content ? page.content : children;
  const emojiConverter = new EmojiConverter();
  
  //convert colons explicitly to unicode for native rendering of emojis
  emojiConverter.replace_mode = "unified";
  emojiConverter.allow_native = true;

  const newContent = emojiConverter.replace_colons(content || '');

  return (
    <MarkdownStyles className={className}>
      <Markdown
        options={{
          overrides: {
            pre: {
              component: Code,
            },
            YouTube: {
              component: YouTube,
            },
            CodePen: {
              component: CodePen,
            },
            a: {
              props: {
                target: '_blank',
              },
            },
          },
        }}
      >
        {newContent || ''}
      </Markdown>
    </MarkdownStyles>
  );
};

export default MarkdownWrapper;
