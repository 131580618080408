import styled from 'styled-components';
import Container from '../container/Container';
import { Card } from '@mc/components/dashboard/Dashboard.styled';
import Cta from '@mc/components/_partials/cta/Cta';

export const Wrap = styled(Container)`
  margin-bottom: 4rem;
`;

export const Main = styled(Card)`
${({ theme }) => `

  padding: 1.5rem;

  @media (min-width: ${theme.screens.md}) {
    padding: 2.5rem;
  }
  `}
`;

export const Title = styled.h2`
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

export const Subheader = styled.h3`
  font-size: 34px;
  margin-bottom: 1.25rem;
`;

export const Cohort = styled.div`
${({ theme }) => `
  border: 1px solid ${theme.colors.midGrey};

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  `}
`;

export const Header = styled.header`
${({theme}) => `
  background-color: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.midGrey};

  @media (min-width: ${theme.screens.lg}) {
    display: flex;
    align-items: center;
  }
  `}
`;

export const Date = styled.div`
${({ theme }) => `
  padding: 1rem 1.5rem;
  text-align: left;
  font-weight: 600;

  @media (min-width: ${theme.screens.lg}) {
    text-align: center;
    border-right: 1px solid ${theme.colors.midGrey};
  }
  `}
`;

export const Price = styled.div`
${({ theme }) => `
  padding: 1rem 1.5rem;
  font-weight: 600;
  flex: 1;

  @media (min-width: ${theme.screens.lg}) {
    min-width: 22%;
  }
  `}
`;

export const Button = styled(Cta)`
  margin: 1rem;
`;

export const Body = styled.div`
  padding: 1.5rem;
`;

export const List = styled.ul`
  padding: 0;
  list-style-type: none;
`;

export const ListItem = styled.li`
${({ theme }) => `
  line-height: 1.5;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  a {
    display: inline-block;
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      bottom: 5px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: ${theme.colors.blue};
      z-index: -1;
    }

    &:hover {
      color: ${theme.colors.dark};
    }
  }
  `}
`;

export const Icon = styled.span`

  margin-right: 1rem;

  ${({ warning, theme }) => `
    color: ${warning ? theme.colors.red : theme.colors.green};
  `}
`;

export const Reserve = styled.div`
${({ theme }) => `
  font-size: 0.875rem;
  padding: 1rem 1.5rem;
  line-height: 1.375;

  @media (min-width: ${theme.screens.lg}) {
    padding: 1.5rem 2rem;
    text-align: right;
  }
  `}
`;
