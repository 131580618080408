import React from 'react';
import { Rail, Step, Wrap } from './Stepper.styled';

const Stepper = ({ steps, activeStepIndex, className }) => (
  <Wrap className={className}>
    <Rail></Rail>
    {steps.map((step, index) => (
      <Step key={index} active={activeStepIndex === index} completed={index < activeStepIndex}>
        {index < activeStepIndex ? <i className="icon icon-check"></i> : index + 1}
      </Step>
    ))}
  </Wrap>
);

export default Stepper;
