import { useSelector } from 'react-redux';
import { getCountry } from '@mc/redux/config/config.selectors';
import { countries } from '@mc/config/countries';

const useFormatPrice = () => {
  let selectedCountry = useSelector(getCountry);
  if (!selectedCountry.currency || !selectedCountry.vat){
    // Default back to GBP?
    selectedCountry = countries.find(c => c.code === "GB");
  }

  const formatPrice = (price, { addVat = true, appendCurrency = true } = {}) => {
    let amount = Number(
      !Array.isArray(price) ? price : price.find(keyValue => keyValue.key === selectedCountry.currency).value
    );

    if (addVat) {
      amount *= selectedCountry.vat / 100 + 1;
    }

    if (!appendCurrency) {
      return Number(amount.toFixed());
    }

    const formatter = new Intl.NumberFormat(selectedCountry.locale, {
      style: 'currency',
      currency: selectedCountry.currency,
    });

    return formatter.format(amount);
  };

  return formatPrice;
};

export default useFormatPrice;
