import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  Wrap,
  Main,
  Title,
  Subheader,
  Cohort,
  Header,
  Date,
  Price,
  Body,
  Button,
  List,
  ListItem,
  Icon,
  Reserve,
} from './ChooseCohort.styled';
import useFormatPrice from '@mc/hooks/useFormatPrice';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import Loader from '../_partials/loader/Loader';

const ChooseCohort = () => {
  const formatPrice = useFormatPrice();
  const [cohorts, setCohorts] = useState([]);
  const [cohortsLoading, setCohortsLoading] = useState(true);
  const SEFT_COURSE_ID = 'aca86e3e-b9a4-40ab-923f-c8d7e5688245';

  useEffect(() => {
    const fetchCohorts = async () => {
      const cohortData = await axios.get(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT}/cohorts`,
        {
          headers: {
            Authorization: `Bearer fryingpanthunderstorm52`,
          }
        }
      );

      setCohorts(cohortData.data);
      setCohortsLoading(false);
    };

    fetchCohorts();
  }, []);

  if (cohortsLoading) {
    return <Loader></Loader>;
  }

  return (
    <Wrap>
      <Breadcrumb></Breadcrumb>
      <Main>
        <Title>Software Engineering FastTrack</Title>
        <Subheader>Choose a cohort</Subheader>
        {cohorts.map((futureCohort, index) => {
          const { lessons } = futureCohort;
          const nonDropInLessons = lessons
            .filter(lesson => !lesson.isDropIn)
            .sort((a, b) => moment(a.startTime).toDate() - moment(b.startTime).toDate());
          //const dropInLesson = lessons.find(lesson => lesson.isDropIn);

          return (
            <Cohort>
              <Header>
                <Date>
                  {moment(futureCohort.startDate).format('ddd MMM Do YYYY')} -{' '}
                  {moment(futureCohort.endDate).format('ddd MMM Do YYYY')}
                </Date>
                <Price>Price: {formatPrice(futureCohort.totalExcVat.gbp)} (inc. VAT)</Price>
                {index === 0 || futureCohort.enrollable ? (
                  <Button
                    primary
                    large
                    url={`checkout?course=${SEFT_COURSE_ID}&cohort=${futureCohort.id}`}
                    green
                  >
                    Enrol
                  </Button>
                ) : (
                  <Reserve>
                    To reserve a place on this cohort, please email us on{' '}
                    <a href="mailto:team@manchestercodes.com">team@manchestercodes.com</a>.
                  </Reserve>
                )}
              </Header>
              <Body>
                <p>
                  <i>All dates and times quoted are British Summer Time (BST)</i>
                </p>
                <p>
                  <b>Classes for this cohort run on:</b>
                  {nonDropInLessons.map(lesson => (
                    <>
                      <br />
                      {/* {moment(lesson.startTime).format('dddd, HH:mm')} - {moment(lesson.endTime).format('HH:mm')} */}
                      {moment(lesson.startTime).format('dddd')}, 18:30 - 21:30
                    </>
                  ))}
                </p>
                <p>
                  <b>There is an optional support drop-in open to students on this cohort on:</b>
                  <br />
                  {/* {moment(dropInLesson.startTime).format('dddd, HH:mm')} -{' '}
                  {moment(dropInLesson.endTime).format('HH:mm')} */}
                  Saturday 10:00 - 16:00
                </p>
                <List>
                  <ListItem>
                    <Icon className="icon icon-alert-diamond" warning />
                    You need to ensure you have completed Introduction to Programming prior to the cohort start date.
                  </ListItem>
                  {futureCohort.applicationDeadline ? (
                    <ListItem>
                      <Icon className="icon icon-alert-diamond" warning />
                      <span>
                        The deadline for enrolment is
                        <strong> {moment(futureCohort.applicationDeadline).format('dddd MMMM Do YYYY HH:mm')}</strong>.
                        You might not be able to enrol after this date.
                      </span>
                    </ListItem>
                  ) : null}
                  <ListItem>
                    <Icon className="icon icon-check-circle" />
                    Interest-free finance is available for this cohort (UK residents only). Please allow two weeks prior
                    to the start date to apply. Apply in the Payment step during checkout.
                  </ListItem>
                  {futureCohort.diversityGrant ? (
                    <ListItem>
                      <Icon className="icon icon-check-circle" />
                      If you identify as a woman or non-binary, are from a Black, Asian or minority ethnic background, or you have a disability, then your are eligible for a £500 discount under our Diversity Grant. Apply in the
                      Additional Details step during checkout.
                    </ListItem>
                  ) : null}
                </List>
              </Body>
            </Cohort>
          );
        })}
      </Main>
    </Wrap>
  );
};

export default ChooseCohort;
