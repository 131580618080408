import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import Loader from '@mc/components/_partials/loader/Loader';
import { auth } from '@mc/config/firebase';

const SlackAuth = () => {
  const history = useHistory();
  const location = useLocation();

  const { code } = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (!code) {
      return;
    }

    const doSignIn = async () => {
      const {
        data: { token },
      } = await axios.post('https://us-central1-avocado-on-toast.cloudfunctions.net/slackAuth', { 
        code,
        redirectUri: 
          process.env.NODE_ENV !== 'development'
            ? 'https://platform.manchestercodes.com/slack/auth'
            : 'http://localhost:3000/slack/auth', 
      });

      await auth().signInWithCustomToken(token);

      history.push('/slack/migrate');
    };

    doSignIn();
  }, [code, history]);

  return <Loader />;
};

export default SlackAuth;
