import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Section, Image, Subheading } from '../Checkout.styled';
import { getCountry } from '@mc/redux/config/config.selectors';
import useFormatPrice from '@mc/hooks/useFormatPrice';

const Confirmation = ({ customer, payment, totalExcVat, course }) => {
  const selectedCountry = useSelector(getCountry);
  const formatPrice = useFormatPrice();

  useEffect(() => {
    try {
      window.fbq('track', 'Purchase', {
        value: formatPrice(totalExcVat, { appendCurrency: false }),
        currency: selectedCountry.currency,
        contents: [
          {
            id: course.name.replace(/ /g, ''),
            quantity: 1,
          },
        ],
      });

      window.gtag('event', 'conversion', {
        send_to: 'AW-856445160/ytiGCNr8-dMBEOihsZgD',
        value: formatPrice(totalExcVat, { appendCurrency: false }),
        currency: selectedCountry.currency,
        transaction_id: customer.orderNumber,
      });
    } catch (ex) {
      // fall silently
    }
  }, [course.name, customer.orderNumber, formatPrice, selectedCountry.currency, totalExcVat]);

  return (
    <Section center>
      <Image src="/images/celebration.svg" alt="Celebration" />
      <Subheading>Your application is complete</Subheading>
      <p>
        You should receive a confirmation email shortly. Please take note of your order number just in case you need to
        get in touch: <strong>#{customer.orderNumber}</strong>.
      </p>
      {customer.payment.paymentMethod === 'interestFreeFinance' ? <p>You should receive an email from us in the next 3 working days with an application for finance and an invoice to pay your deposit. If you don't hear from us, please email us on <a href="mailto:team@manchestercodes.com">team@manchestercodes.com</a></p> : null}
      {customer.payment.paymentMethod === 'fullBalance' ? <p>You should receive an email from us in the next 3 working days with an invoice to pay your course fees. If you don't hear from us, please email us on <a href="mailto:team@manchestercodes.com">team@manchestercodes.com</a></p> : null}
      {customer.payment.paymentMethod === 'coursebud' ? <p>You should receive a Coursebud application from us in the next 3 working days. If you don't hear from us, please email us on <a href="mailto:team@manchestercodes.com">team@manchestercodes.com</a></p> : null}
    </Section>
  );
};

export default Confirmation;
