import styled from 'styled-components';
import { Input as McInput } from '@mc/components/_form/form-input/FormInput';
import { Card } from '@mc/components/dashboard/Dashboard.styled';

export const Wrap = styled(Card)`
  ${({ theme }) => `
    font-size: 16px;
    margin: 30px 0;
    padding: 1.5rem;
    text-align: center;

    @media (min-width: ${theme.screens.md}) {
      text-align: left;
    }

    @media (min-width: ${theme.screens.xl}) {
      padding: 1.5rem 2.5rem;
      margin-bottom: 50px;
    }
  `}

  strong {
    font-weight: 500;
  }
`;

export const Heading = styled.h4``;

export const FeedbackWrap = styled.div`
  display: flex;
  margin-top: 30px;
`;

export const EmojiWrap = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: center;

    @media (min-width: ${theme.screens.md}) {
      justify-content: flex-start;
    }
  `}
`;

export const EmojiIcon = styled.button`
  ${({ theme, color, active }) => `
    font-size: 2rem;
    appearance: none;
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    margin-right: 1rem;
    color: ${theme.colors.dark};

    &:hover {
      color: ${theme.colors[color]};
    }

    .icon {
      vertical-align: middle;
    }

    ${active
        ? `
      color: ${theme.colors[color]};
    `
        : ``}
  `}
`;

export const Input = styled(McInput)`
  ${({ theme }) => `
    width: 100%;
    margin-right: 0.5rem;
  `}
`;
