import React from 'react';
import { Wrap, Select } from './FormSelect.styled';

const FormSelect = ({ children, ...props }) => (
  <Wrap>
    <Select {...props}>{children}</Select>
  </Wrap>
);

export default FormSelect;
