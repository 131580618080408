import { firestore } from '@mc/config/firebase';
import { getUser } from '@mc/redux/auth/auth.selectors';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Columns,
  Content,
  MainCol,
  Pagination,
  Sidebar,
  Wrap,
  SidebarInner,
  Nav,
  Button,
  PaginationLoader,
  CompleteButton,
} from '../learner/Learner.styled';
import Loader from '../_partials/loader/Loader';
import ExamQuestion from './ExamQuestion';
import ExamSummary from './ExamSummary';

const EntryExam = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [previousAttempt, setPreviousAttempt] = useState(null);
  const [attemptId, setAttemptId] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [complete, setComplete] = useState(false);
  const user = useSelector(getUser);

  const handleStartExam = async () => {
    const attemptRef = await firestore().collection('exam-attempts').add({
      userId: user.uid,
      startTime: firestore.Timestamp.now(),
      answers: [],
      feedback: [],
      finishTime: null,
      mark: null,
      passed: null,
    });

    setAttemptId(attemptRef.id);
    setCurrentQuestionIndex(0);
  };

  useEffect(() => {
    const getQuestions = async () => {
      const { docs: questionDocs } = await firestore().collection('exam-questions').orderBy('order', 'asc').get();

      const questionsArray = questionDocs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const newAnswers = questionsArray.map((question) => question.initialCode || '');

      const prevAttemptsSnapshot = await firestore()
        .collection('exam-attempts')
        .where('userId', '==', user.uid)
        .orderBy('startTime', 'desc')
        .limit(1)
        .get();

      if (!prevAttemptsSnapshot.empty) {
        const attemptDocs = [];
        prevAttemptsSnapshot.forEach((attemptDoc) => {
          const singleAttempt = {
            id: attemptDoc.id,
            ...attemptDoc.data(),
          };

          singleAttempt.startTime = singleAttempt.startTime.seconds;
          delete singleAttempt.finishTime;

          attemptDocs.push(singleAttempt);
        });

        const newPreviousAttempt = attemptDocs[attemptDocs.length - 1];

        if (moment().diff(moment.unix(newPreviousAttempt.startTime), 'days') < 28) {
          setPreviousAttempt(newPreviousAttempt);
        }
      }

      setQuestions(questionsArray);
      setAnswers(newAnswers);
    };

    getQuestions();
  }, []);

  const handleUpdateCode = (value) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestionIndex] = value;

    setAnswers(newAnswers);
  };

  const handleSubmitExam = async () => {
    await firestore().doc(`exam-attempts/${attemptId}`).update({
      answers,
      finishTime: firestore.Timestamp.now(),
    });

    setComplete(true);
    setCurrentQuestionIndex(null);

    toast.success('Well done! Your exam attempt has been submitted. Please wait 1-3 working days for feedback.');
  };

  return (
    <Wrap>
      <Columns>
        <MainCol>
          <Content>
            {typeof currentQuestionIndex !== 'number' && <ExamSummary />}
            {typeof currentQuestionIndex === 'number' && (
              <ExamQuestion
                question={questions[currentQuestionIndex]}
                answer={answers[currentQuestionIndex]}
                onUpdateCode={handleUpdateCode}
              />
            )}
          </Content>
        </MainCol>
        <Sidebar>
          <Pagination $opaque>
            {!questions.length ? (
              <PaginationLoader>
                <Loader contained />
              </PaginationLoader>
            ) : (
              <SidebarInner>
                <Nav>
                  {currentQuestionIndex > 0 && (
                    <Button
                      onClick={() => [setCurrentQuestionIndex(currentQuestionIndex - 1), window.scrollTo(0, 0)]}
                      style={{ marginBottom: 15 }}
                      secondary
                    >
                      Previous Question
                    </Button>
                  )}

                  {typeof currentQuestionIndex === 'number' && currentQuestionIndex < questions.length - 1 && (
                    <Button onClick={() => [setCurrentQuestionIndex(currentQuestionIndex + 1), window.scrollTo(0, 0)]}>
                      Next Question
                    </Button>
                  )}

                  {typeof currentQuestionIndex === 'number' && currentQuestionIndex === questions.length - 1 && (
                    <CompleteButton onClick={() => handleSubmitExam()} style={{ marginTop: 0 }} green>
                      Submit Exam
                    </CompleteButton>
                  )}

                  {typeof currentQuestionIndex !== 'number' &&
                    !complete &&
                    (!previousAttempt || (previousAttempt && !previousAttempt.answers.length)) && (
                      <Button
                        onClick={() => handleStartExam()}
                        aria-label="Start Exam"
                        disabled={previousAttempt && !previousAttempt.answers.length}
                      >
                        Start Exam
                      </Button>
                    )}

                  {complete ||
                    (previousAttempt && !previousAttempt.feedback && (
                      <Button onClick={() => handleStartExam()} disabled>
                        Awaiting Feedback
                      </Button>
                    ))}

                  {previousAttempt && !!previousAttempt.feedback?.length && (
                    <Button onClick={() => {}}>View Feedback</Button>
                  )}
                </Nav>
              </SidebarInner>
            )}
          </Pagination>
        </Sidebar>
      </Columns>
    </Wrap>
  );
};

export default EntryExam;
