import styled from 'styled-components';
import {lighten} from "polished";

export const Button = styled.button`
  ${({ theme }) => `
    display: inline-block;
    padding: 0 40px;
    font-family: ${theme.fonts.body};
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: ${theme.colors.white}; 
    background: linear-gradient(0deg, ${theme.colors.darkGrey} 0%, ${lighten(0.1, theme.colors.darkGrey)} 100%);
    border: 1px solid ${theme.colors.dark};
    border-radius: 8px;
    height: 48px;
    outline: none;
    cursor: pointer;
    transition: all 0.5s ease-in;

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }

    &:not(:disabled):hover {
      background: linear-gradient(0deg, ${theme.colors.grey} 0%, ${lighten(0.1, theme.colors.grey)} 100%);
      border-color: ${theme.colors.dark};
    }
  `}
`;
