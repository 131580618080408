import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const Toolbar = styled.div`
  background-color: ${({ theme }) => theme.colors.darkAlpha};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
  height: 60px;
`;

export const Title = styled.h2`
  margin: 0;
  color: white;
  font-size: 1.25rem;
  flex: 1;
`;

export const Actions = styled.div`
  display: flex;
`;

export const Output = styled.div`
  flex: 1;
  background-color: ${({ theme }) => darken(0.1, theme.colors.darkAlpha)};
  border: 0;
  color: white;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  min-height: 200px;

  ${({ exam }) =>
    exam
      ? `
    min-height: 250px;
  `
      : ``}

  @media (min-width: ${({ theme }) => theme.screens.xl}) {
    min-height: unset;
  }
`;

export const Line = styled.div`
  ${({ type }) => `
    position: relative;
    padding: 0.5rem 1.25rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    ${
      type === 'return'
        ? `
      &:before {
        content: '◀';
        font-weight: bold;
        color: white;
        position: absolute;
        left: 7px;
        top: 13px;
        font-size: 8px;
      }
    `
        : ``
    }
  `}
`;
