import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import FormFieldGroup from '@mc/components/_form/form-field-group/FormFieldGroup';
import FormLabel from '@mc/components/_form/form-label/FormLabel';
import FormInput from '@mc/components/_form/form-input/FormInput';
import FormButton from '@mc/components/_form/form-button/FormButton';
import FormError from '@mc/components/_form/form-error/FormError';

const StepTwoSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  dob: Yup.string().test(
    'dob',
    'Sorry, you must be at least 13 years old to join Manchester Codes',
    value => moment().diff(moment(value), 'years') >= 13
  ),
});

const StepTwo = ({ onSubmit }) => (
  <Formik onSubmit={onSubmit} validationSchema={StepTwoSchema} initialValues={{ firstName: '', lastName: '', dob: '' }}>
    {({ values, errors, touched, handleChange, handleBlur }) => (
      <Form>
        <FormFieldGroup>
          <FormLabel htmlFor="firstName">First Name</FormLabel>
          <FormInput
            name="firstName"
            id="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus
          ></FormInput>
          {errors.firstName && touched.firstName ? <FormError>{errors.firstName}</FormError> : null}
        </FormFieldGroup>
        <FormFieldGroup>
          <FormLabel htmlFor="lastName">Last Name</FormLabel>
          <FormInput
            name="lastName"
            id="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
          ></FormInput>
          {errors.lastName && touched.lastName ? <FormError>{errors.lastName}</FormError> : null}
        </FormFieldGroup>
        <FormFieldGroup>
          <FormLabel htmlFor="dob">Date of Birth</FormLabel>
          <FormInput
            name="dob"
            id="dob"
            type="date"
            value={values.dob}
            onChange={handleChange}
            onBlur={handleBlur}
          ></FormInput>
          {errors.dob && touched.dob ? <FormError>{errors.dob}</FormError> : null}
        </FormFieldGroup>
        <FormButton>Continue</FormButton>
      </Form>
    )}
  </Formik>
);

export default StepTwo;
