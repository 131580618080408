import styled from 'styled-components';

export const Wrap = styled.div`
  ${({ contained }) => `
    height: ${contained ? '100%' : '100vh'};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const Spinner = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.blue};
    width: 50px;
    height: 50px;
    position: relative;
    border-radius: 9999px;
      
    &:after {
      content: '';
      border: 0px solid ${theme.colors.bgSecondary};
      border-radius: 9999px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: loading 1000ms ease-out forwards infinite;
    }

    @keyframes loading {
      0% {
        border: 0px solid ${theme.colors.bgSecondary};
      }

      20% {
        border: 8px solid ${theme.colors.bgSecondary};
        width: 0%;
        height: 0%;
      }

      100% {
        border: 8px solid ${theme.colors.bgSecondary};
        width: 100%;
        height: 100%;
      }
    }
  `}
`;
