import React, { Fragment } from 'react';
import { Divider, Wrap, Link } from './Breadcrumb.styled';

const Breadcrumb = ({ links = [] }) => {
  const backLink = (links.length && links[links.length - 1]) || {};

  return (
    <>
      <Wrap mobile>
        <i className="icon icon-arrow-left"></i><Link to={backLink.url}> {backLink.text}</Link>
      </Wrap>
      <Wrap desktop>
        <Link to="/">
          Dashboard
        </Link>
        {links.map((link, index) => (
          <Fragment key={index}>
            <Divider>/</Divider>
            {index + 1 === links.length ? (
              <span>{link.text}</span>
            ) : (
              <Link to={link.url}>
                {link.text}
              </Link>
            )}
          </Fragment>
        ))}
      </Wrap>
    </>
  );
};

export default Breadcrumb;
