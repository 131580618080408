import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import {
  Wrap,
  CountryDivider,
  CountryButton,
  Flag,
  Name,
  Chevron,
  Divider,
  Countries,
  ClickCatch,
} from './CountrySelector.styled';
import { getCountry } from '@mc/redux/config/config.selectors';
import { countries } from '@mc/config/countries';
import { setCountry } from '@mc/redux/config/config.slice';

const CountrySelector = ({ form }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const selectedCountry = useSelector(getCountry);

  const handleSelectCountry = countryCode => {
    dispatch(setCountry(countryCode));
    setOpen(false);
    Cookies.set('countryCode', countryCode, { expires: 1, path: '/', sameSite: 'lax' });
  };

  return (
    <>
      {open ? <ClickCatch onClick={() => setOpen(false)}></ClickCatch> : null}
      <Wrap form={form}>
        <CountryButton onClick={() => setOpen(!open)} form={form}>
          {selectedCountry.flag && <Flag src={selectedCountry.flag} alt={selectedCountry.name}></Flag>}
          <Name>{selectedCountry.name}</Name>
          <Chevron className="icon icon-chevron-down" open={open}></Chevron>
        </CountryButton>
        {open ? (
          <Countries>
            <Divider></Divider>
            {countries.map(country => (
              <CountryDivider key={country.code}>
                <CountryButton onClick={() => handleSelectCountry(country.code)}>
                  {country.flag && <Flag src={country.flag} alt={country.name}></Flag> }
                  <Name>{country.name}</Name>
                </CountryButton>
              </CountryDivider>
            ))}
          </Countries>
        ) : null}
      </Wrap>
    </>
  );
};

export default CountrySelector;
