import styled from 'styled-components';
import { Card } from '@mc/components/dashboard/Dashboard.styled';
import MarkdownWrapper from '../markdown/Markdown';

export const Wrap = styled(Card)`
  ${({ theme, completed }) => `
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    position: relative;
    border-top: 8px solid ${completed ? theme.colors.green : theme.colors.yellow};
    
    @media (min-width: ${theme.screens.md}) {
      padding: 2.5rem;
    }
  `}
`;

export const Heading = styled.h2`
  ${({ theme }) => `
    margin-bottom: 1.5rem;

    @media (min-width: ${theme.screens.md}) {
      margin-bottom: 1rem;
    }
  `}
`;

export const Answers = styled.div`
  ${({ theme }) => `
    @media (min-width: ${theme.screens.md}) {
      display: flex;
      flex-wrap: wrap;
      margin-left: -0.5rem;
      width: calc(100% + 1rem);
    }
  `}
`;

export const QuestionNumber = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fonts.intro};
    font-size: 0.875rem;
    text-transform: uppercase;
    color: ${theme.colors.dark};
    padding: 0.5rem 0.5rem 0.25rem;
    margin-bottom: 0.5rem;
    display: inline-block;
    background-color: ${theme.colors.midGrey};
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    border-radius: 8px;

    @media (min-width: ${theme.screens.md}) {
      top: 2.5rem;
      right: 2.5rem;
    }
  `}
`;

export const Question = styled(MarkdownWrapper)`
  ${({ theme }) => `
    font-size: 1.25rem;
    margin-bottom: 1.5rem;

    @media (min-width: ${theme.screens.md}) {
      margin-bottom: 1rem;
    }
  `}
`;

export const AnswerOuter = styled.div`
  ${({ theme }) => `
    position: relative;
    margin-bottom: 1rem;

    @media (min-width: ${theme.screens.md}) {
      width: calc(50% - 1rem);
      margin: 0.5rem;
    }
  `}
`;

export const AnswerHighlight = styled.div`
  ${({ theme, incorrect, correct }) => `
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background-color: ${theme.colors.yellow};
    top: 6px;
    left: 6px;
    position: absolute;
    border-radius: 8px;

    ${
      incorrect
        ? `
      background-color: ${theme.colors.red};
    `
        : ``
    }

    ${
      correct
        ? `
      background-color: ${theme.colors.green};
    `
        : ``
    }
  `}
`;

export const Answer = styled.div`
  ${({ theme, selected, incorrect, correct, completed }) => `
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 1.5rem;
    border: 2px solid ${theme.colors.midGrey};
    position: relative;
    font-weight: 500;
    cursor: pointer;
    height: 100%;

    ${
      selected
        ? `
      border-color: ${theme.colors.dark};
      color: ${theme.colors.black};

      &:before {
        content: ' ';
        width: calc(100% + 3px);
        height: calc(100% + 3px);
        background-color: ${theme.colors.yellow};
        top: 3px;
        left: 3px;
        position: absolute;
        z-index: -1;
      }
    `
        : ``
    }

    ${
      incorrect || correct
        ? `
      color: ${theme.colors.white};
    `
        : ``
    }

    ${
      completed && !correct
        ? `
      opacity: 0.5;
    `
        : ``
    }
  `}
`;

export const AnswerLabel = styled.label`
  display: block;
  position: relative;
  padding-left: calc(30px + 1.5rem);
  font-family: inherit;
  z-index: 9;
  cursor: pointer;
  min-height: 30px;
  display: flex;
  align-items: center;
`;

export const AnswerText = styled(MarkdownWrapper)``;

export const AnswerInput = styled.input`
  position: absolute;
  visibility: hidden;
  outline: none;
  margin: 0;
`;

export const AnswerCheck = styled.div`
  ${({ theme, isRadio, selected }) => `
    display: block;
    position: absolute;
    border: 2px solid ${theme.colors.dark};
    border-radius: ${isRadio ? '50%' : '0'};
    height: 30px;
    width: 30px;
    z-index: 5;
    transition: border 0.25s linear;

    &:before {
      display: block;
      position: absolute;
      content: '${isRadio ? '' : '\\e92f'}';
      border-radius: 100%;
      height: 16px;
      width: 16px;
      top: 5px;
      left: 5px;
      margin: auto;
      visibility: ${selected ? 'visible' : 'hidden'};

      ${
        isRadio
          ? `
      `
          : `
        font-family: icomoon;
          `
      }
    }

    ${AnswerInput}:checked ~ & {
      &:before {
        ${
          isRadio
            ? `
          background-color: ${theme.colors.dark};
        `
            : `
          color: ${theme.colors.dark};
        `
        }
      }
    }
  `}
`;

export const ActionBar = styled.div`
  ${({ theme, quizComplete }) => `
    display: flex;
    margin-top: 1rem;
    justify-content: ${quizComplete ? 'space-between' : 'center'};
  `}
`;
