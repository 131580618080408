import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { rgba } from 'polished';

export const Wrap = styled.div`
  ${({ theme, getStarted }) => `
    background-color: ${theme.colors.white};
    border-radius: 15px;
    box-shadow: 0 10px 20px 0 ${rgba(theme.colors.dark, 0.05)};
    margin-bottom: 30px;
    width: 100%;
    min-height: 380px;

    @media (min-width: ${theme.screens.md}) {
      display: flex;
      flex-direction: row;
      max-width: 700px;
    }

    @media (min-width: ${theme.screens.lg}) {
      max-width: 850px;
    }

    ${
      getStarted
        ? `
      background: linear-gradient(0deg, rgba(49,62,72,1) 0%, rgba(62,79,92,1) 100%);
      margin-bottom: 0px;
      color: white; /* White looks good on both themes so, sticking with it */
      padding: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: auto;
      
      @media (min-width: ${theme.screens.md}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    `
        : ``
    }
  `}
`;

export const Col = styled.div`
  ${({ theme, noBottomPad }) => `
    flex: 1;
    padding: 30px 30px ${noBottomPad ? 0 : '30px'};
    position: relative;

    @media (min-width: ${theme.screens.md}) {
      padding: 60px 40px;
    }
  `}
`;

export const Logo = styled.img`
  ${({ theme }) => `
    height: 2rem;
    width: auto;
    vertical-align: middle;
    margin-bottom: 80px;

    @media (min-width: ${theme.screens.md}) {
      margin-bottom: 40px;
    }
  `}
`;

export const Illustration = styled.img`
  ${({ theme }) => `
    position: absolute;
    width: 100%;
    z-index: 0;
    top: 70px;
    width: 150px;
    right: 30px;

    ${theme.name === "dark" && `
    background: ${theme.colors.darkGrey};
    border-radius: 1rem 1rem 0 0;
    `};
    
    @media (min-width: ${theme.screens.md}) {
      top: auto;
      bottom: 0;
      width: 70%;
      right: 20px;
    }
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => `
    font-size: 1.8rem;
    max-width: 250px;
    margin-bottom: 0;
    
    @media (min-width: ${theme.screens.md}) {
      font-size: 2.25rem;
      max-width: 100%;
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => `
    margin-bottom: 30px;
    font-size: 14px;
  `}
`;

export const SignUpHeading = styled.h3`
  ${({ theme }) => `
    margin: 0 0 15px;

    @media (min-width: ${theme.screens.md}) {
      margin: 0;
    }
  `}
`;

export const ResetLink = styled(Link)`
  ${({ theme }) => `
    font-size: 14px;
    display: inline-block;
    margin-left: 20px;

    @media (min-width: ${theme.screens.md}) {
      margin-left: 30px;
    }
  `}
`;

export const InstructionAction = styled.a`
  ${({ theme, topMargin }) => `
    display: inline-flex;
    color: ${theme.colors.dark};
    border-radius: 3px;
    padding: 0 0.5rem;
    align-items: center;
    height: 40px;
    border: 1px solid ${theme.colors.grey};
    margin-top: ${topMargin ? '50px' : '0'};

    &:hover {
      color: ${theme.colors.dark};
    }

    img {
      width: 22px;
      height: auto;
      display: inline-flex;
      margin-right: 0.5rem;
    }
  `}
`;
