import styled, { css } from 'styled-components';
import { Field as FormikField } from 'formik';
import {rgba, lighten} from "polished";

export const inputStyles = css`
  ${({ theme }) => `
      display: block;
      appearance: none;
      width: 100%;
      padding: 1rem;
      font-size: 1rem;
      font-family: ${theme.fonts.body};
      outline: none;
      height: 48px;
      border-radius: 8px;
      border-color: ${theme.colors.darkGrey};
      background-color: ${theme.colors.white};
      color: ${theme.colors.dark};
      transition: background-color 0.2s ease-in;
  
      &:focus {
        box-shadow: 0 8px 10px 0 ${rgba(theme.colors.dark, 0.4)};
        background: ${theme.colors.white};
        border-color: ${lighten(0.2, theme.colors.darkGrey)};
        outline: none;
      }
    
  `}
`;

export const FormikInput = styled(FormikField)`
  ${inputStyles}
`;

export const NonFormikInput = styled.input`
  ${inputStyles}
`;
