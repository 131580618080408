import styled from 'styled-components';
import Container from '@mc/components/container/Container';
import { Input } from '@mc/components/_form/form-input/FormInput';
import FormSelect from '@mc/components/_form/form-select/FormSelect';
import { Card } from '@mc/components/dashboard/Dashboard.styled';
import RichTextField from '../rich-text-field/RichTextField';

export const Outer = styled.div`
  ${({ whiteBackground }) => `
    ${
      whiteBackground
        ? `
      background-color: #fff;
    `
        : ``
    }
  `}
`;

export const Wrap = styled(Container)`
  ${({ theme, sidebarEnabled }) => `
    justify-content: space-between;

    @media (min-width: ${theme.screens.lg}) {
      display: flex;
    }

    ${
      sidebarEnabled
        ? `
      @media (min-width: ${theme.screens.xl}) {
        padding-left: 14rem;
      }
    `
        : ``
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => `
    margin-bottom: 40px;

    @media (min-width: ${theme.screens.lg}) {
      margin-bottom: 0;
      width: calc(1 / 2 * 100% - (1 - 1 / 2) * 60px);
    }
  `}
`;

export const Title = styled.h2``;

export const Description = styled(RichTextField)`
  margin-bottom: 2.5rem;
`;

export const Form = styled.div``;

export const Field = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

export const FieldItem = styled.span`
  ${({ theme, right }) => `
    &:last-child {
      padding: 1rem;
      width: 130px;
      min-width: 130px;
      max-width: 100%;
      text-align: right;
    }

    @media (min-width: ${theme.screens.lg}) {
      &:last-child {
        width: 150px;
      }
    }
  `}

  small {
    font-size: 12px;
  }
`;

export const FieldInput = styled(Input)`
  width: 130px;
  min-width: 130px;
  max-width: 100%;
  margin-left: 1.25rem;

  ${({ theme }) => `
    @media (min-width: ${theme.screens.lg}) {
      width: 150px;
      max-width: 150px;
    }
  `}
`;

export const FieldSelect = styled(FormSelect)`
  width: 130px;
  min-width: 130px;
  max-width: 100%;

  ${({ theme }) => `
    @media (min-width: ${theme.screens.lg}) {
      width: 150px;
      max-width: 150px;
    }
  `}
`;

export const Summary = styled(Card)`
  ${({ theme, wide }) => `
    align-items: center;
    text-align: center;
    border: 2px solid ${theme.colors.blue};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    padding: 1.5rem 1rem;
    
    @media (min-width: ${theme.screens.lg}) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      width: ${wide ? '100%' : 'calc(1 / 2 * 100% - (1 - 1 / 2) * 60px)'};
    }
  `}
`;

export const Cell = styled.div`
  ${({ theme }) => `
    padding: 1rem;
  `}
`;

export const CellText = styled.span`
  font-size: 0.875rem;
  display: block;
  margin-bottom: 0.5rem;
`;

export const CellNumber = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
  display: block;
`;
