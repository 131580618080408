import React from 'react';
import { Wrap, Slice } from './Hamburger.styled';

const Hamburger = ({ open = false, onClick }) => (
  <Wrap onClick={onClick} aria-label="Header Toggle">
    <Slice />
    <Slice width={80} />
    <Slice width={60} />
  </Wrap>
);

export default Hamburger;
