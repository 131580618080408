import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormFieldGroup from '@mc/components/_form/form-field-group/FormFieldGroup';
import FormLabel from '@mc/components/_form/form-label/FormLabel';
import FormInput from '@mc/components/_form/form-input/FormInput';
import FormButton from '@mc/components/_form/form-button/FormButton';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Wrap, Col, Logo, Title, Illustration, ResetLink, Description } from '../login/Login.styled';
import { getUser } from '@mc/redux/auth/auth.selectors';
import { auth } from '@mc/config/firebase';
import FormError from '@mc/components/_form/form-error/FormError';
import { getHeaderData } from '@mc/redux/app/app.selectors';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Required'),
});

const ForgotPassword = ({ title, description }) => {
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(getUser);
  const header = useSelector(getHeaderData);

  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, [history, location, user]);

  const handleResetPassword = async ({ email }) => {
    try {
      await auth().sendPasswordResetEmail(email);

      setSuccess(true);
    } catch (ex) {
      if (ex.code === 'auth/user-not-found') {
        return setSuccess(true);
      }

      toast.error('Something went wrong. Please try again later.');
      console.error(ex);
    }
  };

  return (
    <Wrap>
      <Col noBottomPad>
        <Logo src={header.logo.src} alt={header.logo.alt} />
        <Title>Reset your password</Title>
        <Illustration src="/images/illustrations/login.svg" alt=""></Illustration>
      </Col>
      <Col>
        {success ? (
          <Description>
            Your password reset request has been submitted. If an account exists under the given email address then you should receive an email shortly with instructions on how to set a new password.
          </Description>
        ) : (
          <Formik onSubmit={handleResetPassword} validationSchema={ForgotPasswordSchema} initialValues={{ email: '' }}>
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form>
                <Description>Please enter the email address linked to your account to reset your password.</Description>
                <FormFieldGroup>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <FormInput id="email" value={values.email} onChange={handleChange} onBlur={handleBlur}></FormInput>
                  {errors.email && touched.email ? <FormError>{errors.email}</FormError> : null}
                </FormFieldGroup>
                <FormButton>Reset password</FormButton>
                <ResetLink to="/login">Return to login</ResetLink>
              </Form>
            )}
          </Formik>
        )}
      </Col>
    </Wrap>
  );
};

export default ForgotPassword;
