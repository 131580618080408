import styled from "styled-components";

export const StyledCode = styled.pre`
${({theme}) => `
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  max-width: 100%;

  background-color: ${theme.colors.lightGrey} ;
  color: ${theme.colors.darkGrey};

  .hljs-comment,
  .hljs-punctuation {
    color: ${theme.colors.grey};
  }

  .hljs-attr,
  .hljs-attribute,
  .hljs-meta,
  .hljs-selector-attr,
  .hljs-selector-class,
  .hljs-selector-id {
    color: ${theme.colors.darkBlue};
  }

  .hljs-variable,
  .hljs-literal,
  .hljs-number,
  .hljs-doctag {
    color: ${theme.colors.orange};
  }

  .hljs-params {
    color: ${theme.colors.darkGrey};
  }

  .hljs-function {
    color: ${theme.colors.darkPink};
  }

  .hljs-class,
  .hljs-tag,
  .hljs-title,
  .hljs-built_in {
    color: ${theme.colors.orange};
  }

  .hljs-keyword,
  .hljs-type,
  .hljs-builtin-name,
  .hljs-meta-keyword,
  .hljs-template-tag,
  .hljs-template-variable {
    color: ${theme.colors.red};
  }

  .hljs-string,
  .hljs-undefined {
    color: ${theme.colors.darkBlue};
  }

  .hljs-regexp {
    color: ${theme.colors.darkBlue};
  }

  .hljs-symbol {
    color: ${theme.colors.darkBlue};
  }

  .hljs-bullet {
    color: ${theme.colors.orange};
  }

  .hljs-section {
    color: ${theme.colors.darkBlue};
    font-weight: bold;
  }

  .hljs-quote,
  .hljs-name,
  .hljs-selector-tag,
  .hljs-selector-pseudo {
    color: ${theme.colors.green};
  }

  .hljs-emphasis {
    color: ${theme.colors.orange};
    font-style: italic;
  }

  .hljs-strong {
    color: ${theme.colors.orange};
    font-weight: bold;
  }

  .hljs-deletion {
    color: ${theme.colors.red};
    background-color: ${theme.colors.darkAlpha};
  }

  .hljs-addition {
    color: ${theme.colors.green};
    background-color: ${theme.colors.darkAlpha};
  }

  .hljs-link {
    color: ${theme.colors.darkBlue};
    font-style: underline;
  } 
  `}
`;