import { createSlice } from '@reduxjs/toolkit';
import { LOCAL_STORAGE_THEME_LOCATION} from "@mc/config/theme";

const savedTheme = localStorage.getItem(LOCAL_STORAGE_THEME_LOCATION) || "light"; // Default to "light" theme

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    theme: savedTheme,
  },
  reducers: {
    updateTheme(state, { payload: theme }) {
      state.theme = theme;
    },
  },
});

export const { actions, reducer } = themeSlice;
export const { updateTheme } = actions;
export default reducer;
