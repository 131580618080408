import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Wrap,
  Inner,
  Section,
  Logo,
  Address,
  Icon,
  Contact,
  Legal,
  LegalCopyright,
  LegalLinks,
  Pip,
} from './MainFooter.styled';
import { getFooterData } from '@mc/redux/app/app.selectors';

const MainFooter = () => {
  const footer = useSelector(getFooterData);

  return (
    <Wrap>
      <Legal>
        <LegalLinks>
          <Link to="/privacy-policy">
            Privacy Policy
          </Link>
          <Pip></Pip>
          <Link to="/terms-of-service">
            Terms of Service
          </Link>
        </LegalLinks>
        <LegalCopyright>&copy; MCRCODES LTD {moment().format('YYYY')}</LegalCopyright>
      </Legal>
    </Wrap>
  );
};

export default MainFooter;
