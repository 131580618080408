import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '@mc/redux/auth/auth.selectors';
import {
  Wrap,
  Blocks,
  ChatCtaCard,
  CourseCard,
  Greeting,
  ErrorCard,
} from './Dashboard.styled';
import Cta from "../_partials/cta/Cta";
import CourseList from '../course-list/CourseList';
import ChatCta from '../_cards/chat-cta/ChatCta';
import Schedule from '../_cards/schedule/Schedule';
import CourseProgress from '../_cards/course-progress/CourseProgress';
import LoggedInWrapper from '../logged-in-wrapper/LoggedInWrapper';
import { useHistory } from 'react-router-dom';

const Dashboard = ({location}) => {
  const user = useSelector(getUser);
  const hist = useHistory();

  if (!user) {
    return <LoggedInWrapper></LoggedInWrapper>;
  }

  return (
    <Wrap>
      <Greeting>
        Welcome, {user.firstName} {user.lastName}
      </Greeting>
      <Blocks>

        {(location.state && location.state.error) && 
          <ErrorCard>
            <span>{location.state.error}</span>
            <Cta white url="https://www.manchestercodes.com/software-engineer-fasttrack" target="_blank">
              Find out more
            </Cta>
          </ErrorCard>
        }
        
        <CourseCard>
          <CourseProgress></CourseProgress>
        </CourseCard>

        <ChatCtaCard>
          <ChatCta></ChatCta>
        </ChatCtaCard>

        {/* <Schedule /> */}
        {/* <Grid>
            <CodeChallengeCard>
              <CodeChallenge></CodeChallenge>
            </CodeChallengeCard>
          </Grid> */}
        {/* <DaysOfCodeCard>
            <DaysOfCode></DaysOfCode>
          </DaysOfCodeCard> */}
        <CourseList></CourseList>
      </Blocks>
    </Wrap>
  );
};

export default Dashboard;
