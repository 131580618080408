import styled from 'styled-components';
import { darken } from 'polished';

export const Wrap = styled.div`
  ${({ theme }) => `
    position: relative;

    &:after {
      content: '\\e907';
      font-family: icomoon;
      font-size: 12px;
      position: absolute;
      color: ${theme.colors.grey};
      top: 19px;
      right: 0.75rem;
      pointer-events: none;
    }
  `}
`;

export const Select = styled.select`
  ${({ theme }) => `
    display: block;
    appearance: none;
    width: 100%;
    padding: 0 2rem 0 1rem;
    font-size: 1rem;
    font-family: ${theme.fonts.body};
    outline: none;
    height: 48px;
    border: 0;
    border-radius: 8px;
    background-color: ${theme.colors.midGrey};
    transition: background-color 0.2s ease-in;
    color: ${theme.colors.dark};

    &:focus {
      box-shadow: 0 8px 10px 0 rgb(49 62 72 / 8%);
      background: ${theme.colors.midGrey};
      outline: none;
    }
    
  `}
`;
