import styled, { css } from 'styled-components';
import Cta from '@mc/components/_partials/cta/Cta';
import {rgba} from "polished";

export const Wrap = styled.div`
  ${({ theme }) => `
    z-index: 100;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: ${theme.colors.white};

    @media (min-width: ${theme.screens.xl}) {
      margin-bottom: 40px;
      height: 85px;
      background: transparent;
    }
  `}
`;

export const Inner = styled.div`
  ${({ theme, isLogo }) => `
    padding: 1.5rem;
    display: flex;
    align-items: center;

    @media (min-width: ${theme.screens.xl}) {
      padding: 1.5rem 2.5rem;

      ${
        isLogo
          ? `
        position: absolute;
        top: 0;
        left: 0;
        height: 85px;
      `
          : ``
      }
    }
  `}
`;

export const Ctas = styled(Inner)`
  ${({ theme }) => `
    display: hidden;

    @media (min-width: ${theme.screens.xl}) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 85px;
    }
  `}
`;

export const User = styled(Inner)`
  ${({ theme }) => `
    display: none;

    @media (min-width: ${theme.screens.xl}) {
      position: absolute;
      top: 0;
      right: 0;
      height: 85px;
      display: flex;
      align-items: center;
      display: block;
    }
  `}
`;

export const Logo = styled.img`
  height: 2rem;
  width: auto;
  vertical-align: middle;
`;

export const HeaderCta = styled(Cta)`
  ${({ theme }) => `
    width: 100%;
    flex: 1;

    @media (min-width: ${theme.screens.xl}) {
      width: auto;
      margin-left: 1rem;
    }
  `}
`;

export const MobileMenuIcon = styled(Inner)`
  ${({ theme }) => `
    @media (min-width: ${theme.screens.xl}) {
      display: none;
    }
  `}
`;

export const MobileOverlay = styled.div`
  ${({ theme }) => `
    background-color: ${rgba(theme.colors.dark, 0.5)};
    z-index: 99;
    top: 80px;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;

    @media (min-width: ${theme.screens.xl}) {
      display: none;
    }
  `}
`;

export const MobileMenu = styled.div`
  ${({ theme }) => `
    z-index: 100;
    border-top: 1px solid ${theme.colors.midGrey};
    max-height: calc(100vh - 80px);
    overflow: auto;
    position: absolute;
    width: 100%;
    background: ${theme.colors.white};
    padding: 1.5rem 1rem;
  `}
`;

export const mobileNavItemStyles = css`
  ${({ theme, open }) => `
    position: relative;
    width: 100%;
    appearance: none;
    display: block;
    border: 0;
    background: transparent;
    font-family: ${theme.fonts.intro};
    font-weight: 600;
    font-size: 1.25rem;
    text-align: left;
    color: ${theme.colors.dark};
    line-height: 2;

    &:after {
      content: '\\e907';
      font-family: icomoon;
      font-weight: normal;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotate(${open ? '180deg' : '0deg'});
      transition: all 200ms linear;  
    }
  `}
`;

export const MobileNavButton = styled.button`
  ${mobileNavItemStyles}
`;

export const MobileNavLink = styled.a`
  ${mobileNavItemStyles}

  &:after {
    content: '';
  }
`;

export const MobileDivider = styled.div``;

export const MobileUser = styled.div`
  padding: 0.5rem 0 1rem;
`;

export const MobileCtas = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;

  a {
    max-width: calc(50% - 0.5rem);

    &:only-child {
      max-width: 100%;
    }
  }
`;

export const MobileSubNav = styled.div`
  padding: 1rem 0;

  ${({ theme }) => `
    border-top: 1px solid ${theme.colors.midGrey};
  `}
`;

export const MobileSubNavLink = styled.a`
  ${({ theme }) => `
    display: block;
    width: 100%;
    font-family: ${theme.fonts.intro};
    text-align: left;
    color: ${theme.colors.dark};
    line-height: 2;
  `}
  
`;

export const DesktopCountrySelector = styled.div`
  ${({ theme }) => `
    display: none;

    @media (min-width: ${theme.screens.xl}) {
      display: block;
    }
  `}
`;
