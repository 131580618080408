import React from 'react';
import { Formik, Form } from 'formik';
import qs from 'qs';
import * as Yup from 'yup';
import FormFieldGroup from '@mc/components/_form/form-field-group/FormFieldGroup';
import FormLabel from '@mc/components/_form/form-label/FormLabel';
import FormInput from '@mc/components/_form/form-input/FormInput';
import FormButton from '@mc/components/_form/form-button/FormButton';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Wrap, Col, Logo, Title, Illustration, Description } from '../login/Login.styled';
import { auth } from '@mc/config/firebase';
import FormError from '@mc/components/_form/form-error/FormError';
import { getHeaderData } from '@mc/redux/app/app.selectors';

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9@$=!:.#%-]+$)/,
      "Password must contain at least one number, one uppercase letter and one lowercase letter"
    )
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required")
});

const ChangePassword = () => {
  const history = useHistory();
  const location = useLocation();
  const header = useSelector(getHeaderData);
  const { oobCode } = qs.parse(location.search, { ignoreQueryPrefix: true });
  // mode = resetPassword

  const handleChangePassword = async ({ password }) => {
    try {
      await auth().confirmPasswordReset(oobCode, password);

      toast.success('Your password has been changed successfully. Please login with your new details.');
      history.push('/login');
    } catch (ex) {
      if (ex.code === 'auth/invalid-action-code') {
        return toast.error('Unable to change password. Your request has expired. Please request another password reset.');
      }

      toast.error('Something went wrong. Please try again later or get in touch with us for support.');
      console.error(ex);
    }
  };

  return (
    <Wrap>
      <Col noBottomPad>
        <Logo src={header.logo.src} alt={header.logo.alt} />
        <Title>Set new password</Title>
        <Illustration src="/images/illustrations/login.svg" alt=""></Illustration>
      </Col>
      <Col>
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          validationSchema={ChangePasswordSchema}
          onSubmit={handleChangePassword}
        >
          {({ errors, touched, handleChange, handleBlur }) => (
            <Form>
              <Description>Please choose a new password.</Description>
              <FormFieldGroup>
                <FormLabel htmlFor="password">Password</FormLabel>
                <FormInput id="password" name="password" type="password" onChange={handleChange} onBlur={handleBlur} />
                {errors.password && touched.password ? (
                  <FormError>{errors.password}</FormError>
                ) : null}
              </FormFieldGroup>
              <FormFieldGroup>
                <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
                <FormInput id="confirmPassword" name="confirmPassword" type="password" onChange={handleChange} onBlur={handleBlur} />
                {errors.confirmPassword && touched.confirmPassword ? (
                  <FormError>{errors.confirmPassword}</FormError>
                ) : null}
              </FormFieldGroup>
              <FormButton type="submit">
                Change Password
              </FormButton>
            </Form>
          )}
        </Formik>
      </Col>
    </Wrap>
  );
};

export default ChangePassword;
