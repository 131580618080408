import React from "react";
import hljs from 'highlight.js';
import {StyledCode} from "./Code.styled";

// credit: https://bernardodiasdacruz.com/2018/04/09/markdown-renderer-component-that-can-render-other-react-components/
const Code = node => {
  const { props } = node.children;
  const language = props.className ? props.className.replace(/^(lang-)/, '') : '';
  const code = props.children || '';
  const htmlBlock = language ? hljs.highlight(code, {language}).value : hljs.highlightAuto(code).value;

  //return renderHTML(`<pre class="hljs" style="max-width: 100%;"><code class="${props.className}">${htmlBlock}</code></pre>`);
  // StyledCode is just a styled `pre` block.
  return (
    <StyledCode className="hljs"> 
      <code className={props.className} dangerouslySetInnerHTML={{__html: htmlBlock}}></code>
    </StyledCode>
  )
};

export default Code;
