import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '@mc/redux/auth/auth.selectors';
import { auth } from '@mc/config/firebase';
import { Wrap, Avatar, Greeting, AccountLink, Dot, Dots, Menu, MenuItem, ClickCatch, Name } from './UserMenu.styled';
import { updateTheme } from '@mc/redux/theme/theme.slice';
import { themeSelector } from '@mc/redux/theme/theme.selector';
import { LOCAL_STORAGE_THEME_LOCATION } from "@mc/config/theme";

const UserMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const user = useSelector(getUser);
  const currentTheme = useSelector(themeSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = async () => {
    await auth().signOut();
    history.push('/');
  };

  const handleThemeSwitch = () => {
    let newTheme = "dark";
    if(currentTheme === "dark"){ newTheme = "light"; }

    dispatch(updateTheme(newTheme));
    // Save it in the localstore
    localStorage.setItem(LOCAL_STORAGE_THEME_LOCATION, newTheme);
  };

  if (!user.firstName) {
    return null;
  }

  return (
    <Wrap>
      <Avatar avatar={user.avatar}>
        {!user.avatar ? (
          <>
            {user.firstName[0]}
            {user.lastName[0]}
          </>
        ) : null}
      </Avatar>
      <Greeting>
        Hey <Name>{user.firstName}</Name> &nbsp;👋
        <AccountLink to="/" onClick={handleLogout}>Logout</AccountLink>{/* eslint-disable-line */}
        <AccountLink to="#" onClick={handleThemeSwitch}>Toggle Dark Mode</AccountLink>
      </Greeting>
      <Dots onClick={() => setMenuOpen(!menuOpen)} menuOpen={menuOpen}>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
      </Dots>
      {menuOpen ? (
        <>
          <ClickCatch onClick={() => setMenuOpen(false)} />
          <Menu>
            <MenuItem to="/" onClick={() => setMenuOpen(false)}>
              <Link to="/">Dashboard</Link>
            </MenuItem>
            <MenuItem to="/account" onClick={() => setMenuOpen(false)}>
              <Link to="/account">Account</Link>
            </MenuItem>
            <MenuItem as="a" href="#" onClick={handleThemeSwitch}>
              Toggle Dark Mode
            </MenuItem>
            <MenuItem as="a" href="#" onClick={handleLogout}>
              Logout
            </MenuItem>
          </Menu>
        </>
      ) : null}
    </Wrap>
  );
};

export default UserMenu;
