import { createSlice } from '@reduxjs/toolkit';

const courseSlice = createSlice({
  name: 'course',
  initialState: {
    chapters: [],
    chapter: null,
    page: null,
  },
  reducers: {
    getChapters() {},
    setChapters(state, { payload: chapters }) {
      state.chapters = chapters;
    },
    getChapter() {},
    setChapter(state, { payload: chapter }) {
      state.chapter = chapter;
    },
    getPage() {},
    setPage(state, { payload: page }) {
      state.page = page;
    },
  },
});

export const { actions, reducer } = courseSlice;
export const { getChapters, setChapters, getChapter, setChapter, getPage, setPage } = actions;
export default reducer;
