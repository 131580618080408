import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import ScrollToTop from './components/_utilities/ScrollToTop';
import * as serviceWorker from './serviceWorker';
import store from '@mc/redux/store';

import './styles/fonts.css';
import 'react-circular-progressbar/dist/styles.css';
import 'highlight.js/styles/atom-one-dark-reasonable.css';
import 'react-google-places-autocomplete/dist/index.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'pure-react-carousel/dist/react-carousel.es.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Router>
      <App />
      <ScrollToTop></ScrollToTop>
    </Router>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
