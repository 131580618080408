import {lighten, darken} from "polished";

export const LOCAL_STORAGE_THEME_LOCATION = "mcr_codes_theme";

const light = {
  name: "light",
  fontFamily: {
    display: ['Gilroy', 'Helvetica', 'Arial', 'sans-serif'],
    body: ['Gilroy', 'Helvetica', 'Arial', 'sans-serif'],
    intro: ['Gilroy', 'Helvetica', 'Arial', 'sans-serif'],
  },
  fonts: {
    intro: '"Gilroy", Arial, Helvetica, sans-serif',
    body: '"Gilroy", Helvetica, Arial, sans-serif',
  },
  colors: {
    blue: '#00d6f2',
    blueHover: '#00c4e8',

    darkBlue: "#2B3891",

    dark: '#000000',
    darkAlpha: '#838B91',
    
    lightGrey: '#F8F8F9',
    midGrey: '#e2e2e2',
    grey: '#6d6d6d',
    darkGrey: '#2f2f2f',

    bg: '#fff',
    bgSecondary: '#fff',
    buttonBg: '#000',
    buttonBgHover: '#2f2f2f',
    buttonText: '#fff',
    
    // Brand colours. Don't need to change with theme
    yellow: '#FFD400',
    red: '#ff0043',
    redHover: '#cc0036',
    green: '#44d62c',
    white: '#FFFFFF',
    orange: "#FF6B1C",
    pink: "#EDB5D4",
    darkPink: darken(0.2, "#EDB5D4"),
    black: "#000000",
  },
  screens: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  },
};

const dark = {
  name: "dark",
  // Inherit default stuffs from light theme
  fontFamily: {
    ...light.fontFamily
  },
  fonts: {
    ...light.fonts
  },
  screens: {
    ...light.screens
  },
  colors: {
    ...light.colors,

    darkBlue: "#69CCE3",

    dark: "#ffffff",
    darkAlpha: '#c4c4c4',

    bg: '#000',
    bgSecondary: '#0d0d0d',
    buttonBg: '#fff',
    buttonBgHover: '#ddd',
    buttonText: '#000',

    lightGrey: '#2f2f2f',
    midGrey: '#6d6d6d',
    grey: '#e2e2e2',
    darkGrey: "#F8F8F9",
  
    white: '#0d0d0d',
    
    darkPink: lighten(0.1, light.colors.pink),
  },
};

export default light;

export {light, dark};