import styled from 'styled-components';

export const Wrap = styled.div`
  ${({ theme }) => `  
    padding: 20px;

    @media (min-width: ${theme.screens.md}) {
      padding: 0;
      min-height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 40px;
    }
  `}
`;
