import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Section,
  Title,
  Fieldset,
  Label,
  Description,
  Input,
  Checkbox,
  CtaContainer,
  Error,
} from '../Checkout.styled';
import Cta from '@mc/components/_partials/cta/Cta';
import useFormatPrice from '@mc/hooks/useFormatPrice';

const AdditionalDetailsSchema = Yup.object().shape({
  laptop: Yup.bool().oneOf([true], 'Your laptop must meet the minimum specification in order to take this course'),
  reach: Yup.string().required('Required'),
});

const AdditionalDetails = ({ onNextStep, customer, onUpdateCustomer }) => {
  const formatPrice = useFormatPrice();

  const handleSubmit = values => {
    onUpdateCustomer({
      ...customer,
      additional: values,
    });
    onNextStep();
  };

  const laptopHireExcVat = [
    { key: 'GBP', value: '5.08' },
    { key: 'EUR', value: '19' },
  ];

  return (
    <Formik
      initialValues={{
        // health: '',
        laptop: false,
        ubuntuSupport: false,
        reach: '',
        referral: '',
        diversityGrant: false,
        // emergencyContactName: '',
        // emergencyContactPhone: '',
      }}
      validationSchema={AdditionalDetailsSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Form>
          <Section>
            <Title>Additional details</Title>
            {/* <Fieldset>
              <Label htmlFor="emergencyContactName">Emergency contact name</Label>
              <Input name="emergencyContactName" id="emergencyContactName" placeholder="Joe Smith"></Input>
              {errors.emergencyContactName && touched.emergencyContactName ? (
                <Error>{errors.emergencyContactName}</Error>
              ) : null}
            </Fieldset>
            <Fieldset>
              <Label htmlFor="emergencyContactPhone">Emergency contact number</Label>
              <Input
                name="emergencyContactPhone"
                id="emergencyContactPhone"
                placeholder="+44(0)7873077189"
                type="number"
              ></Input>
              {errors.emergencyContactPhone && touched.emergencyContactPhone ? (
                <Error>{errors.emergencyContactPhone}</Error>
              ) : null}
            </Fieldset>
            <Fieldset>
              <Label htmlFor="health">
                Do you have any allergies, dietary requirements or health conditions we should be aware of?
              </Label>
              <Input
                name="health"
                id="health"
                placeholder="Please state any allergies, dietary requirements or health conditions…"
              ></Input>
              {errors.health && touched.health ? <Error>{errors.health}</Error> : null}
            </Fieldset> */}
            <Fieldset>
              <Label htmlFor="laptop">
                Please confirm your computer meets the minimum specification detailed below:
              </Label>
              <Description>
                <p>
                  <b>Display Size:</b> At least 13 inches<br></br>
                  <b>Memory/RAM:</b> At least 8GB<br></br>
                  <b>Hard Drive:</b> At least 128GB<br></br>
                  <b>Operating System:</b> Mac OS X or Ubuntu
                </p>
                <p>
                  If your computer doesn't meet the minimum specification, <strong>especially if you are a Windows user</strong>, please email us on {' '}
                  <a href="mailto:team@manchestercodes.com">
                    team@manchestercodes.com
                  </a>
                  . We may be able to lease you one of our MacBooks from {formatPrice(laptopHireExcVat)} a week, which will be shipped to your home address.
                </p>
              </Description>
              <br></br>
              <Checkbox name="laptop" id="laptop" onChange={handleChange} onBlur={handleBlur}>
                I confirm my computer meets the minimum specification
              </Checkbox>
              {errors.laptop && touched.laptop ? <Error>{errors.laptop}</Error> : null}
            </Fieldset>
            <Fieldset>
              <Label htmlFor="reach">How did you hear about us?</Label>
              <Input name="reach" id="reach" placeholder="Please state how you heard about us…"></Input>
              {errors.reach && touched.reach ? <Error>{errors.reach}</Error> : null}
            </Fieldset>
            <Fieldset>
              <Label htmlFor="referral">If anybody referred you to Manchester Codes, please tell us their name...</Label>
              <Input name="referral" id="referral" placeholder="Please tell us who referred you…"></Input>
              {errors.referral && touched.referral ? <Error>{errors.referral}</Error> : null}
            </Fieldset>
            <Fieldset>
              <Label htmlFor="diversityGrant">
                We offer a £500 discount to selected applicants under our Diversity Grant. If you identify as a woman or non-binary, are from a Black, Asian or minority ethnic background, or you have a disability, then please state below.
              </Label>
              <Checkbox
                name="diversityGrant"
                id="diversityGrant"
                onChange={e => {
                  onUpdateCustomer({
                    ...customer,
                    additional: {
                      ...values,
                      diversityGrant: e.target.checked,
                    },
                  });

                  handleChange(e);
                }}
                onBlur={handleBlur}
              >
                I meet the above eligibility criteria for the Diversity Grant
              </Checkbox>
              {errors.diversityGrant && touched.diversityGrant ? <Error>{errors.diversityGrant}</Error> : null}
            </Fieldset>
          </Section>
          <CtaContainer>
            <Cta primary type="submit">
              Continue
            </Cta>
          </CtaContainer>
        </Form>
      )}
    </Formik>
  );
};

export default AdditionalDetails;
