import styled from "styled-components";
import Container from "../container/Container";
import { Card } from "../dashboard/Dashboard.styled";

export const Wrap = styled(Container)``;

export const Columns = styled.div`
  ${({ theme }) => `
    @media (min-width: ${theme.screens.xl}) {
      display: flex;
    }
  `}
`;

export const Sidebar = styled.div`
  ${({ theme }) => `
    @media (min-width: ${theme.screens.xl}) {
      min-width: 400px;
      width: 400px;
      max-width: 400px;
      padding-right: 30px;
    }
  `}
`;

export const Pagination = styled.div`
  ${({ theme }) => `
    margin-bottom: 3rem;
    overflow: inherit;
    
    @media (min-width: ${theme.screens.xl}) {
      position: sticky;
      top: 30px;
      z-index: 1;
      width: 370px;
    }
  `}
`;

export const SidebarInner = styled(Card)`
  ${({ theme }) => `
    padding: 1.5rem;

    @media (min-width: ${theme.screens.xl}) {
      padding: 2.5rem;
    }
  `}
`;

export const MainCol = styled.div`
  ${({ theme }) => `
    @media (min-width: ${theme.screens.xl}) {
      flex: 1;
      width: calc(100% - 400px);
    }
  `}
`;

export const Content = styled(Card)`
  ${({ theme }) => `
    margin-bottom: 1.5rem;
    padding: 1.5rem;

    @media (min-width: ${theme.screens.xl}) {
      padding: 2.5rem;
    }
  `}
`;
