import React from 'react';
// import RichTextField from '@mc/components/rich-text-field/RichTextField';
import { Wrap, Input, Label } from './FormCheckbox.styled';

const FormCheckbox = ({ children, id, ...props }) => (
  <Wrap>
    <Input id={id} type="checkbox" {...props} />
    <Label htmlFor={id}>{children}</Label>
  </Wrap>
);

export default FormCheckbox;
