import React from 'react';
import { Wrap, Spinner } from './Loader.styled';

const Loader = ({ contained = false }) => (
  <Wrap contained={contained}>
    <Spinner />
  </Wrap>
);

export default Loader;
