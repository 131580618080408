import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { schedule } from '@mc/config/schedule';
import FormSelect from '@mc/components/_form/form-select/FormSelect';
import { getUser } from '@mc/redux/auth/auth.selectors';
import { firestore } from '@mc/config/firebase';
import { setUser } from '@mc/redux/auth/auth.slice';
import { ScheduleCard } from '@mc/components/dashboard/Dashboard.styled';
import {
  Wrap,
  Nav,
  NavButton,
  SettingsButton,
  Settings,
  CloseIcon,
  Label,
  Subheader,
  Title,
  Tracks,
  Link,
  Details,
  PlayIcon,
} from './Schedule.styled';

const localStorage =
  typeof window !== 'undefined'
    ? window.localStorage
    : {
        getItem: () => {},
        setItem: () => {},
      };

const Schedule = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const userWeeks = user.weeks || 24;
  const [currentWeekIndex, setCurrentWeekIndex] = useState(parseInt(localStorage.getItem('scheduleWeek'), 10) || 0);
  const [showSettings, setShowSettings] = useState(false);
  const flexi = user.courses.find(course => course.id === 'JqaR140v0XOrJpkRndKF');

  const handleUpdateWeeks = async weeks => {
    try {
      await firestore()
        .doc(`users/${user.uid}`)
        .set(
          {
            weeks,
          },
          { merge: true }
        );

      setCurrentWeekIndex(0);
      dispatch(setUser({ ...user, weeks }));
      toast.success('Updated schedule');
    } catch (ex) {
      toast.error('Unable to update weeks. Please try again later.');
    }
  };

  if (!flexi || !flexi.activated) {
    return null;
  }

  const bespokeSchedule = new Array(userWeeks).fill().map((_, index) => {
    const weeksPerScheduleWeek = schedule.length / userWeeks;
    const weeks = schedule.slice(index * weeksPerScheduleWeek, index * weeksPerScheduleWeek + weeksPerScheduleWeek);

    return weeks.reduce(
      (prevWeek, scheduleWeek) => ({
        lessons: [...prevWeek.lessons, ...scheduleWeek.lessons].filter(
          (lesson, lessonIndex, lessons) =>
            lessonIndex === lessons.findIndex(otherLesson => lesson.url === otherLesson.url)
        ),
        module: scheduleWeek.module,
      }),
      {
        lessons: [],
        module: '',
      }
    );
  });

  const weekOptions = [12, 24];

  const changeWeek = week => {
    setCurrentWeekIndex(week);
    localStorage.setItem('scheduleWeek', week);
  };

  const { lessons } = bespokeSchedule[currentWeekIndex];

  return (
    <ScheduleCard>
      <Wrap>
        <Nav>
          <NavButton
            onClick={() => changeWeek(currentWeekIndex - 1 < 0 ? bespokeSchedule.length - 1 : currentWeekIndex - 1)}
          >
            <span className="icon icon-arrow-left" />
          </NavButton>
          <Title>Week {currentWeekIndex + 1}</Title>
          <NavButton
            onClick={() => changeWeek(currentWeekIndex + 1 < bespokeSchedule.length ? currentWeekIndex + 1 : 0)}
          >
            <span className="icon icon-arrow-right" />
          </NavButton>
          <SettingsButton onClick={() => setShowSettings(true)} type="button" className="icon icon-cog" />
        </Nav>
        <Tracks>
          {lessons.length ? (
            <>
              <Subheader>{bespokeSchedule[currentWeekIndex].module}</Subheader>
              {lessons.map(lesson => (
                <Link href={lesson.url} prefetch={false}>
                  <Details>
                    {lesson.type === 'lecture' ? (
                      <span className="icon icon-e-learning-laptop" />
                    ) : (
                      <span className="icon icon-book" />
                    )}
                    {lesson.name}
                  </Details>
                  <PlayIcon className="icon icon-play" />
                </Link>
              ))}
            </>
          ) : (
            <>
              <Subheader>Recap & Personal Development</Subheader>
              <p>
                There is no set content for this week. Please use it to complete unfinished parts of the course or to
                work on a personal project. If you are looking for a job in the tech industry then please work through
                our <a href="/module/career-guidance/career-guidance">Career Guidance</a> module.
              </p>
            </>
          )}
        </Tracks>
        {showSettings ? (
          <Settings>
            <CloseIcon onClick={() => setShowSettings(false)} className="icon icon-cross" />
            <Label htmlFor="studyLength">How many weeks would you like to study for?</Label>
            <FormSelect
              name="studyLength"
              id="studyLength"
              onChange={e => handleUpdateWeeks(parseInt(e.target.value, 10))}
              value={userWeeks}
            >
              {weekOptions.map(weekOption => (
                <option key={weekOption} value={weekOption}>
                  {weekOption}
                </option>
              ))}
            </FormSelect>
          </Settings>
        ) : null}
      </Wrap>
    </ScheduleCard>
  );
};

export default Schedule;
