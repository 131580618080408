import styled from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import { lighten } from 'polished';
import { Card } from '@mc/components/dashboard/Dashboard.styled';
import { Link } from 'react-router-dom';

export const Outer = styled.div`
  margin-bottom: 5rem;
`;

export const Wrap = styled.div`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const CourseTitle = styled.h3`
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
`;

export const Modules = styled.div``;

export const ModuleLink = styled(Link)`
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

export const Module = styled(Card)`
  ${({ theme }) => `
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${theme.screens.lg}) {
      padding: 1.25rem 2rem;
      height: 120px;
      flex-direction: row;
    }
  `}
`;

export const Glyph = styled.div`
  ${({ theme, color }) => `
    background-color: ${lighten(0.35, color || 'transparent')};
    color: ${color};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-bottom: 20px;

    @media (min-width: ${theme.screens.lg}) {
      margin-bottom: 0;
    }
  `}
`;

export const Icon = styled.span`
  font-size: 1.625rem;
`;

export const ModuleDetails = styled.div`
  ${({ theme }) => `
    text-align: center;
    flex: 1;
    margin-bottom: 1rem;

    @media (min-width: ${theme.screens.lg}) {
      text-align: left;
      margin-left: 30px;
      margin-bottom: 0;
    }
  `}
`;

export const ModuleName = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fonts.intro};
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    line-height: 1.15;

    @media (min-width: ${theme.screens.lg}) {
      flex: 1;
      text-align: left;
    }
  `}
`;

export const ModuleDate = styled.div`
  opacity: 0.6;
  font-size: 0.875rem;
`;

export const Lock = styled.div`
  ${({ theme }) => `
    width: 80px;
    height: 80px;
    font-size: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.dark};
  `}
`;

export const Progress = styled(CircularProgressbar)`
  ${({ theme }) => `
    width: 100px;
    height: auto;

    @media (min-width: ${theme.screens.lg}) {
      height: 100%;
      width: auto;
    }

    .CircularProgressbar-trail {
      stroke: #c1c5c8;
    }

    .CircularProgressbar-path {
      stroke: ${theme.colors.dark};
    }

    .CircularProgressbar-text {
      font-family: Intro, Arial, sans-serif;
      font-size: 1.25rem;
      font-weight: bold;
      fill: ${theme.colors.dark};
      dominant-baseline: mathematical;
    }
  `}
`;

export const Courses = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0 2rem;
`;

export const Course = styled.div`
  ${({ theme }) => `  
    display: inline-block;
    border-radius: 0.375rem;
    background: ${theme.colors.lightGrey};
    padding: 0.25rem 0.5rem;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.875rem;
    color: ${theme.colors.dark};
    margin: 0 0.5rem;
  `}
`;
