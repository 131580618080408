import { useSelector } from 'react-redux';
import { getCountry } from '@mc/redux/config/config.selectors';
import { countries } from '@mc/config/countries';

const useCalculateVat = () => {
  let selectedCountry = useSelector(getCountry);
  if (!selectedCountry.currency || !selectedCountry.vat) {
    // Default back to GB VAT (20%)?
    selectedCountry = countries.find(c => c.code === "GB");
  }

  const calculateVat = (price, { appendCurrency = true } = {}) => {
    const amount = Number(
      !Array.isArray(price) ? price : price.find(keyValue => keyValue.key === selectedCountry.currency).value
    );
    const amountPlusVat = amount * (selectedCountry.vat / 100 + 1);
    const vatAmount = amountPlusVat - amount;

    if (!appendCurrency) {
      return vatAmount;
    }

    const formatter = new Intl.NumberFormat(selectedCountry.locale, {
      style: 'currency',
      currency: selectedCountry.currency,
    });

    return formatter.format(vatAmount);
  };

  return calculateVat;
};

export default useCalculateVat;
