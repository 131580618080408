import React, { useState, useEffect, useCallback } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import Cta from '@mc/components/_partials/cta/Cta';
import { getCountry } from '@mc/redux/config/config.selectors';
import useFormatPrice from '@mc/hooks/useFormatPrice';
import FormSelect from '@mc/components/_form/form-select/FormSelect';
import { getUser } from '@mc/redux/auth/auth.selectors';
import { Summary, Cell, CellText, CellNumber } from '../../finance-calculator/FinanceCalculator.styled';
import {
  Section,
  Title,
  CtaContainer,
  Fieldset,
  Label,
  Input,
  PaymentOptions,
  RadioLabel,
  RadioInput,
  RadioText,
  Error,
  ErrorAlert,
  Checkbox,
  Description,
} from '../Checkout.styled';

const PaymentSchema = (props) =>
  Yup.object().shape({
    paymentMethod: Yup.string().required('Required'),
    employerPays: Yup.bool(),
    financeDepositAmount: Yup.number()
      .when('paymentMethod', {
        is: (val) => val === 'interestFreeFinance',
        then: Yup.number().min(props.minimumDeposit, `Minimum deposit is ${props.minimumDepositFormatted}`),
      })
      .when('paymentMethod', {
        is: (val) => val === 'interestFreeFinance',
        then: Yup.number().max(props.maximumDeposit, `Maximum deposit is ${props.maximumDepositFormatted}`),
      }),
    financeInstalments: Yup.number().when('paymentMethod', {
      is: (val) => val === 'interestFreeFinance',
      then: Yup.number()
        .min(6, 'You must make a minimum of 6 instalments')
        .max(24, 'You can make a maximum of 24 instalments'),
    }),
    financeStartDate: Yup.string().when('paymentMethod', {
      is: (val) => val === 'interestFreeFinance',
      then: Yup.string().required('Required'),
    }),
  });

const Payment = ({ cohort, customer, totalExcVat, onUpdateCustomer, onNextStep }) => {
  const formatPrice = useFormatPrice();
  // const stripe = useStripe();
  // const elements = useElements();

  const minimumDeposit = formatPrice(cohort.minimumDeposit, { appendCurrency: false, addVat: false });
  const maximumDeposit = formatPrice(cohort.totalExcVat.gbp, { addVat: true, appendCurrency: false }) * 0.8;

  // const [stripeClientSecret, setStripeClientSecret] = useState('');
  // const [paymentError, setPaymentError] = useState('');
  const [processing, setProcessing] = useState(false);
  const user = useSelector(getUser);

  // const createPaymentIntent = useCallback(async (finance = false) => {
  //   const idToken = user.token;

  //   try {
  //     const {
  //       data: { clientSecret },
  //     } = await axios.post(
  //       `${process.env.REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT}/enrol/checkout`,
  //       {
  //         countryCode: selectedCountry.code,
  //         cohortId: cohort.id,
  //         diversityGrant: customer.additional.diversityGrant,
  //         finance: {
  //           selected: finance,
  //           deposit: finance ? depositAmount : 0,
  //         },
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${idToken}`,
  //         },
  //       }
  //     );

  //     setStripeClientSecret(clientSecret);
  //   } catch (ex) {
  //     if (ex.response.status === 401) {
  //       return toast.error('Your user session has expired. Please refresh the page.');
  //     }

  //     toast.error("Payment isn't working at the moment. Please contact the Manchester Codes team.");
  //   }
  // }, [user, selectedCountry, cohort, customer, depositAmount]);

  // useEffect(() => {
  //   createPaymentIntent();
  // }, [createPaymentIntent]);

  const paymentRadioOptions = [
    // { value: 'card', label: 'Pay {0} with debit/credit card' },
    // { value: 'bacs', label: 'Pay {0} via bank transfer (BACS)' },
    { value: 'fullBalance', label: 'Pay the full balance upfront' },
    { value: 'interestFreeFinance', label: 'Apply for up to 24 months interest-free finance' },
    { value: 'coursebud', label: 'Apply for a tuition fee loan through Coursebud' },
  ];

  const getFinanceAmounts = (values) => {
    const total = formatPrice(totalExcVat, { addVat: true, appendCurrency: false });
    const deposit = formatPrice(values.financeDepositAmount, { addVat: false, appendCurrency: false });
    const financeAmount = formatPrice(total - deposit, { addVat: false, appendCurrency: false });

    return {
      total,
      deposit,
      financeAmount,
    };
  };

  const handleSubmit = async (values) => {
    setProcessing(true);

    const payment = {
      paymentMethod: values.paymentMethod,
      balance: Number(getFinanceAmounts(values).total.toFixed()),
    };

    if (values.paymentMethod === 'interestFreeFinance') {
      payment.financeDepositAmount = Number(values.financeDepositAmount);
      payment.financeBorrowingAmount = getFinanceAmounts(values).financeAmount;
      payment.financeInstalments = Number(values.financeInstalments);
      payment.financeStartDate = Number(values.financeStartDate);
    }

    // if (values.paymentMethod === 'card') {
    //   const payload = await stripe.confirmCardPayment(stripeClientSecret, {
    //     payment_method: {
    //       card: elements.getElement(CardElement),
    //       billing_details: {
    //         name: `${customer.personal.firstName} ${customer.personal.lastName}`,
    //       },
    //     },
    //   });

    //   if (payload.error) {
    //     setPaymentError(`Payment failed ${payload.error.message}`);
    //     return setProcessing(false);
    //   }

    //   setPaymentError('');

    //   onUpdateCustomer({
    //     ...customer,
    //     payment,
    //     paymentIntent: payload.paymentIntent,
    //   });
    // }

    const idToken = user.token;

    try {
      const {
        data: { orderNumber },
      } = await axios.post(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT}/enrol`,
        {
          ...customer,
          payment,
          cohort: cohort.id,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      onUpdateCustomer({
        ...customer,
        payment,
        orderNumber,
      });
      onNextStep();
    } catch (ex) {
      console.error(ex);
      setProcessing(false);
      toast.error('There was an issue with your enrolment. Please contact the Manchester Codes team.');
    }
  };

  // const CardElementOptions = {
  //   style: {
  //     base: {
  //       color: '#32325d',
  //       fontFamily: 'monospace',
  //       fontSmoothing: 'antialiased',
  //       fontSize: '14px',
  //       '::placeholder': {
  //         color: '#aab7c4',
  //       },
  //     },
  //     invalid: {
  //       color: '#fa755a',
  //       iconColor: '#fa755a',
  //     },
  //   },
  // };

  // const handleCardChange = async event => {
  //   setDisabled(event.empty);
  //   setCardError(event.error ? event.error.message : '');
  // };

  // const getPaymentText = values => (values.paymentMethod === 'card' ? 'Make Payment' : 'Confirm Payment');

  return (
    <Formik
      initialValues={{
        paymentMethod: 'fullBalance',
        financeDepositAmount: minimumDeposit,
        financeInstalments: '12',
        financeStartDate: '1',
        // employerPays: false,
      }}
      validationSchema={PaymentSchema({
        minimumDeposit,
        minimumDepositFormatted: formatPrice(minimumDeposit, { addVat: false }),
        maximumDeposit,
        maximumDepositFormatted: formatPrice(maximumDeposit, { addVat: false }),
      })}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, handleChange, handleBlur, values }) => (
        <Form>
          <Section>
            {/* {paymentError ? <ErrorAlert>{paymentError}</ErrorAlert> : null} */}
            <Title>Payment</Title>
            <PaymentOptions>
              {paymentRadioOptions.map((option) =>
                option.hidden ? null : (
                  <RadioLabel>
                    <RadioInput
                      key={option.value}
                      name="paymentMethod"
                      value={option.value}
                      checked={values.paymentMethod === option.value}
                      label={option.label}
                      type="radio"
                    />
                    <RadioText>{option.label.replace('{0}', values.finance ? 'deposit' : 'full balance')}</RadioText>
                  </RadioLabel>
                )
              )}
            </PaymentOptions>
            {values.paymentMethod === 'interestFreeFinance' ? (
              <>
                <h3>Up to 24 months interest free</h3>
                <Description>
                  <p>
                    You can pay for your course fees over a period up to 24 months interest-free (0% APR) in conjunction
                    with our finance partners{' '}
                    <a href="https://payitmonthly.uk/" target="_blank" rel="noopener noreferrer">
                      Payitmonthly
                    </a>{' '}
                    and (for 24 month agreements){' '}
                    <a href="https://edaid.com" target="_blank" rel="noopener noreferrer">
                      EdAid
                    </a>
                    .
                  </p>
                  <p>
                    Specify the deposit you would like to pay now (minimum £1,000) and the number of instalments you
                    would like to pay the remaining balance over.
                  </p>
                  <p>
                    Within 3 working days of completing this form, Payitmonthly will get in touch with a finance
                    application you will need to complete on their website. If successful, your place on the course will
                    then be confirmed. If unsuccessful, we will work with you to see if we can find a different
                    solution, otherwise your deposit amount will be refunded.
                  </p>
                </Description>
                <br />
                <Fieldset>
                  <Label htmlFor="financeDepositAmount">Deposit amount (£)</Label>
                  <Input
                    name="financeDepositAmount"
                    id="financeDepositAmount"
                    placeholder="1000"
                    min="1000"
                    max="4000"
                    type="number"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  {errors.financeDepositAmount && touched.financeDepositAmount ? (
                    <Error>{errors.financeDepositAmount}</Error>
                  ) : null}
                </Fieldset>
                <Fieldset>
                  <Label htmlFor="financeInstalments">Number of monthly repayments</Label>
                  <FormSelect
                    name="financeInstalments"
                    id="financeInstalments"
                    value={values.financeInstalments}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {new Array(10).fill().map((_, index) => (
                      <option key={index} value={index + 3}>
                        {index + 3} months
                      </option>
                    ))}
                    <option value={24}>24 months</option>
                  </FormSelect>
                  {errors.financeInstalments && touched.financeInstalments ? (
                    <Error>{errors.financeInstalments}</Error>
                  ) : null}
                </Fieldset>
                <Fieldset>
                  <Label htmlFor="financeStartDate">Preferred Instalment Day</Label>
                  <FormSelect
                    name="financeStartDate"
                    id="financeStartDate"
                    type="date"
                    value={values.financeStartDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="1">1st of the month</option>
                    <option value="8">8th of the month</option>
                    <option value="15">15th of the month</option>
                    <option value="22">22nd of the month</option>
                  </FormSelect>
                  {errors.financeStartDate && touched.financeStartDate ? (
                    <Error>{errors.financeStartDate}</Error>
                  ) : null}
                </Fieldset>
                <Fieldset>
                  <Summary wide>
                    <Cell>
                      <CellText>Deposit amount</CellText>
                      <CellNumber>{formatPrice(getFinanceAmounts(values).deposit, { addVat: false })}</CellNumber>
                    </Cell>
                    <Cell>
                      <CellText>Finance amount</CellText>
                      <CellNumber>{formatPrice(getFinanceAmounts(values).financeAmount, { addVat: false })}</CellNumber>
                    </Cell>
                    <Cell>
                      <CellText>Number of repayments</CellText>
                      <CellNumber>{values.financeInstalments}</CellNumber>
                    </Cell>
                    <Cell>
                      <CellText>Monthly repayment</CellText>
                      <CellNumber>
                        {formatPrice(getFinanceAmounts(values).financeAmount / values.financeInstalments, {
                          addVat: false,
                        })}
                      </CellNumber>
                    </Cell>
                    <Cell>
                      <CellText>Representative APR</CellText>
                      <CellNumber>0%</CellNumber>
                    </Cell>
                    <Cell>
                      <CellText>Total amount</CellText>
                      <CellNumber>{formatPrice(totalExcVat)}</CellNumber>
                    </Cell>
                  </Summary>
                </Fieldset>
                <h4>Paying the deposit</h4>
                <Description>
                  <p>
                    Upon enrolment, we will email you an invoice for your balance due. You can make payments against
                    this invoice with credit/debit card and/or BACS until your balance due is paid off. Your invoice
                    will only cover your deposit. A separate finance agreement will be sent out for the remaining
                    balance.
                  </p>
                </Description>
              </>
            ) : null}
            {/* {values.paymentMethod === 'card' ? (
              <Fieldset>
                <Label>
                  Pay{' '}
                  {values.finance
                    ? formatPrice(Number(values.financeDepositAmount), { addVat: false })
                    : formatPrice(totalExcVat)}{' '}
                  with credit/debit card
                </Label>
                <CardElement id="card-element" options={CardElementOptions} onChange={handleCardChange} />
                {cardError ? <Error>{cardError}</Error> : null}
              </Fieldset>
            ) : null} */}
            {values.paymentMethod === 'fullBalance' ? (
              <>
                <h3>Pay the full balance upfront</h3>
                <Description>
                  <p>
                    Upon enrolment, we will email you an invoice for your balance due. You can make payment against this
                    invoice with credit/debit card and/or BACS. If an employer is paying your tuition fee, please email
                    us on <a href="mailto:team@manchestercodes.com">team@manchestercodes.com</a>.
                  </p>
                </Description>
              </>
            ) : null}
            {values.paymentMethod === 'coursebud' ? (
              <>
                <h3>Apply for a tuition fee loan through Coursebud</h3>
                <Description>
                  <p>
                    Upon enrolment, we will email you a link to our Coursbud page where you can apply for a loan to
                    cover your tuition fees and any living expenses.
                  </p>
                </Description>
              </>
            ) : null}
          </Section>
          <CtaContainer>
            <Cta primary type="submit" disabled={processing}>
              {processing ? 'Processing enrolment. Please wait...' : 'Complete Enrolment'}
            </Cta>
          </CtaContainer>
        </Form>
      )}
    </Formik>
  );
};

export default Payment;
