import React from 'react';
import { Wrap } from './FixedWidthLayout.styled';
import MainHeader from '@mc/components/main-header/MainHeader';
import MainFooter from '@mc/components/main-footer/MainFooter';

const FixedWidthLayout = ({ children }) => (
  <Wrap>
    <MainHeader></MainHeader>
    {children}
    <MainFooter></MainFooter>
  </Wrap>
);

export default FixedWidthLayout;
