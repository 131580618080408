import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormFieldGroup from '@mc/components/_form/form-field-group/FormFieldGroup';
import FormLabel from '@mc/components/_form/form-label/FormLabel';
import FormButton from '@mc/components/_form/form-button/FormButton';
import FormError from '@mc/components/_form/form-error/FormError';
import FormPhoneInput from '@mc/components/_form/form-phone-input/FormPhoneInput';
import { countries } from '@mc/config/countries';
import FormCheckbox from '@mc/components/_form/form-checkbox/FormCheckbox';
import { Description } from '../../login/Login.styled';

const StepThreeSchema = Yup.object().shape({
  countryCode: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required'),
});

const StepThree = ({ onSignup }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <Formik
      onSubmit={values => [setFormSubmitted(true), onSignup(values)]}
      validationSchema={StepThreeSchema}
      initialValues={{ countryCode: countries.find(country => country.default).code, phoneNumber: '', consent: false }}
    >
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Form>
          <FormFieldGroup>
            <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
            <FormPhoneInput
              countryCodeName="countryCode"
              phoneNumberName="phoneNumber"
              phoneNumberId="phoneNumber"
              countryCodeValue={values.countryCode}
              phoneNumberValue={values.phoneNumber}
              onCountryCodeChange={handleChange}
              onPhoneNumberChange={handleChange}
              onCountryCodeBlur={handleBlur}
              onPhoneNumberBlur={handleBlur}
              autoFocus
            ></FormPhoneInput>
            {errors.phoneNumber && touched.phoneNumber ? <FormError>{errors.phoneNumber}</FormError> : null}
          </FormFieldGroup>
          <Description>
            With my registration I agree to the{' '}
            <a href="https://www.commandshift.co/terms-of-service" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://www.commandshift.co/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>{' '}
            of Command Shift.
          </Description>
          <FormButton disabled={Object.keys(errors).length > 0 || formSubmitted}>Complete Signup</FormButton>
        </Form>
      )}
    </Formik>
  );
};

export default StepThree;
