import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormFieldGroup from '@mc/components/_form/form-field-group/FormFieldGroup';
import FormLabel from '@mc/components/_form/form-label/FormLabel';
import FormInput from '@mc/components/_form/form-input/FormInput';
import FormButton from '@mc/components/_form/form-button/FormButton';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getUser } from '@mc/redux/auth/auth.selectors';
import { auth } from '@mc/config/firebase';
import FormError from '@mc/components/_form/form-error/FormError';
import Cta from '@mc/components/_partials/cta/Cta';
import { getHeaderData } from '@mc/redux/app/app.selectors';
import { Wrap, SignUpHeading, Col, Logo, Title, Illustration, ResetLink, InstructionAction } from './Login.styled';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Required'),
  password: Yup.string().required('Required'),
});

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(getUser);
  const header = useSelector(getHeaderData);

  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, [history, location, user]);

  const handleLogin = async ({ email, password }) => {
    try {
      await auth().signInWithEmailAndPassword(email, password);
    } catch (ex) {
      toast.error(ex.message);
    }
  };

  return (
    <>
      <Wrap>
        <Col noBottomPad>
          <Logo src={header.logo.src} alt={header.logo.alt} />
          <Title>Sign in to Command Shift</Title>
          <Illustration src="/images/illustrations/login.svg" alt=""></Illustration>
        </Col>
        <Col>
          <Formik onSubmit={handleLogin} validationSchema={LoginSchema} initialValues={{ email: '', password: '' }}>
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form>
                <FormFieldGroup>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <FormInput id="email" value={values.email} onChange={handleChange} onBlur={handleBlur}></FormInput>
                  {errors.email && touched.email ? <FormError>{errors.email}</FormError> : null}
                </FormFieldGroup>
                <FormFieldGroup>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <FormInput
                    id="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></FormInput>
                  {errors.password && touched.password ? <FormError>{errors.password}</FormError> : null}
                </FormFieldGroup>
                <FormButton>Login</FormButton>
                <ResetLink to="/forgot-password">Forgot password?</ResetLink>
                <div>
                  <InstructionAction
                    href={`https://slack.com/oauth/v2/authorize?user_scope=identity.basic,identity.email,identity.team,identity.avatar&client_id=1093354796259.1093484814342&team=manchestercodes&redirect_uri=${
                      process.env.NODE_ENV !== 'development'
                        ? 'https://platform.manchestercodes.com'
                        : 'http://localhost:3000'
                    }/slack/auth`}
                    topMargin
                  >
                    <img src="/images/slack-glyph.svg" alt="Slack"></img>
                    <span>
                      Sign in with <strong>Slack</strong>
                    </span>
                  </InstructionAction>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Wrap>
      <Wrap getStarted>
        <SignUpHeading>Not got an account?</SignUpHeading>
        <Cta dark url="/create-account">
          Get started for free
        </Cta>
      </Wrap>
    </>
  );
};

export default Login;
