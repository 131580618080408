import React from 'react';
import { Wrap, Toolbar, Title, Output, Line } from './Console.styled';

const Console = ({ output, exam }) => (
  <Wrap>
    <Toolbar>
      <Title>Console</Title>
    </Toolbar>
    <Output>
      {output.length ? (
        output.map((line) => <Line type={line.type}>{line.value}</Line>)
      ) : (
        <Line type="log">Run some code in the editor above to see the console output</Line>
      )}
    </Output>
  </Wrap>
);

export default Console;
