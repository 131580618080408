import React from 'react';
import { useSelector } from 'react-redux';
import { Wrap, Logo, Text, ImageWrap, CtaWrap } from './ChatCta.styled';
import Cta from '@mc/components/_partials/cta/Cta';
import { getSlack } from '@mc/redux/app/app.selectors';

const ChatCta = (props) => {
  const slack = useSelector(getSlack);

  return (
    <Wrap>
      <ImageWrap>
        <Logo src="/images/slack.svg" alt="Slack"></Logo>
        {props.children ? props.children : <Text>Chat with other learners in our thriving community</Text>}
      </ImageWrap>
      <CtaWrap>
        <Cta url="https://join.slack.com/t/manchestercodes/shared_invite/zt-e1oyg9cy-wZ1MCl_aAbEuaaReqBYyvQ" target="_blank" primary $large>
          Join our community
        </Cta>
        <Cta secondary url="https://manchestercodes.slack.com" target="_blank" $large>
          Open Slack
        </Cta>
      </CtaWrap>
    </Wrap>
  )
};

export default ChatCta;
