import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  Wrap,
  Heading,
  Question,
  Answers,
  AnswerOuter,
  AnswerHighlight,
  Answer,
  AnswerInput,
  AnswerLabel,
  AnswerText,
  AnswerCheck,
  QuestionNumber,
  ActionBar,
} from './Quiz.styled';
import Cta from '../_partials/cta/Cta';
import { firestore } from '@mc/config/firebase';

const Quiz = ({ quiz, pageView, onUpdateAnswers }) => {
  const getCurrentQuestionIndex = useCallback(() => {
    if (!pageView.answers) {
      return 0;
    }

    const completedQuestions = Object.keys(pageView.answers).sort((a, b) => a - b);
    const lastQuestionIndex = parseInt(completedQuestions[completedQuestions.length - 1], 10);
    const nextQuestionIndex = lastQuestionIndex + 1;

    return nextQuestionIndex >= quiz.length ? 0 : nextQuestionIndex;
  }, [pageView, quiz]);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(getCurrentQuestionIndex());
  const [selectedAnswers, setSelectedAnswers] = useState(
    (pageView.answers && pageView.answers[getCurrentQuestionIndex()]) || []
  );
  const [answers, setAnswers] = useState(pageView.answers);
  const [checkingAnswer, setCheckingAnswer] = useState(false);
  const [incorrectAnswers, setIncorrectAnswers] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(
    (pageView.answers && pageView.answers[getCurrentQuestionIndex()]) || []
  );

  useEffect(() => {
    setCurrentQuestionIndex(getCurrentQuestionIndex());
    setSelectedAnswers((pageView.answers && pageView.answers[getCurrentQuestionIndex()]) || []);
    setAnswers(pageView.answers);
    setCheckingAnswer(false);
    setIncorrectAnswers([]);
    setCorrectAnswers((pageView.answers && pageView.answers[getCurrentQuestionIndex()]) || []);
  }, [pageView, quiz, getCurrentQuestionIndex]); 

  const currentQuestion = quiz[currentQuestionIndex];
  const quizComplete = Boolean(answers && answers[quiz.length - 1]);
  const isRadio = currentQuestion && currentQuestion.correctAnswers.length === 1;

  const handleSelectAnswer = answerIndex => {
    if (
      (correctAnswers.length && isRadio) ||
      checkingAnswer ||
      quizComplete ||
      (!isRadio &&
        selectedAnswers.length === currentQuestion.correctAnswers.length &&
        !selectedAnswers.includes(answerIndex))
    ) {
      return;
    }

    setIncorrectAnswers([]);

    if (isRadio) {
      return setSelectedAnswers([answerIndex]);
    }

    setSelectedAnswers(
      selectedAnswers.includes(answerIndex)
        ? selectedAnswers.filter(selectedAnswerIndex => selectedAnswerIndex !== answerIndex)
        : [...selectedAnswers, answerIndex]
    );
  };

  const handleCheckAnswers = async () => {
    const newIncorrectAnswers = selectedAnswers.filter(
      answerIndex => !currentQuestion.correctAnswers.includes(answerIndex)
    );
    const newCorrectAnswers = selectedAnswers.filter(answerIndex =>
      currentQuestion.correctAnswers.includes(answerIndex)
    );

    if (newIncorrectAnswers.length) {
      if (!isRadio) {
        setCorrectAnswers(newCorrectAnswers);
      }

      return setIncorrectAnswers(newIncorrectAnswers);
    }

    try {
      setCheckingAnswer(true);

      const newAnswers = { ...(pageView.answers || answers || {}), [currentQuestionIndex]: selectedAnswers };

      await firestore()
        .doc(`pageViews/${pageView.id}`)
        .set({ answers: newAnswers }, { merge: true });

      setAnswers(newAnswers);
      setCheckingAnswer(false);
      setCorrectAnswers(selectedAnswers);
      onUpdateAnswers(newAnswers);
    } catch (ex) {
      console.error(ex);
      toast.error('Something went wrong. Please try again later.');
    }
  };

  const handlePrevQuestion = () => {
    setCorrectAnswers(answers[currentQuestionIndex - 1]);
    setSelectedAnswers(answers[currentQuestionIndex - 1]);
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const handleNextQuestion = () => {
    if (quizComplete) {
      setCorrectAnswers(answers[currentQuestionIndex + 1]);
      setSelectedAnswers(answers[currentQuestionIndex + 1]);
    } else {
      setCorrectAnswers([]);
      setSelectedAnswers([]);
    }

    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const incorrectAnswersSelected = selectedAnswers.some(answerIndex => incorrectAnswers.includes(answerIndex));

  return (
    <Wrap completed={quizComplete}>
      <Heading>Quiz</Heading>
      <QuestionNumber>
        Question {currentQuestionIndex + 1} of {quiz.length}
      </QuestionNumber>
      <Question>{currentQuestion?.question}</Question>
      <Answers>
        {currentQuestion?.answers.length === 1 ? (
          <></>
        ) : (
          currentQuestion?.answers.map((answer, answerIndex) => {
            const selected = selectedAnswers.includes(answerIndex);
            const answerId = `${currentQuestionIndex}_${answerIndex}`;
            const incorrect = incorrectAnswers.includes(answerIndex);
            const correct = correctAnswers.includes(answerIndex);

            return (
              <AnswerOuter key={answerIndex} onClick={() => handleSelectAnswer(answerIndex)}>
                {selected || incorrect || correct ? (
                  <AnswerHighlight incorrect={incorrect} correct={correct}></AnswerHighlight>
                ) : null}
                <Answer selected={selected} incorrect={incorrect} correct={correct} completed={quizComplete}>
                  <AnswerInput
                    name="answer"
                    value={answerId}
                    type={isRadio ? 'radio' : 'checkbox'}
                    checked={selected}
                    onChange={() => handleSelectAnswer(answerIndex)}
                  />
                  <AnswerLabel htmlFor={answerId}>
                    <AnswerText>{answer}</AnswerText>
                  </AnswerLabel>
                  <AnswerCheck isRadio={isRadio} selected={selected} />
                </Answer>
              </AnswerOuter>
            );
          })
        )}
      </Answers>
      <ActionBar quizComplete={quizComplete}>
        {correctAnswers.length && correctAnswers.length === currentQuestion?.correctAnswers.length && !quizComplete ? (
          <Cta yellow large onClick={handleNextQuestion}>
            Next Question <span className="icon icon-arrow-right" />
          </Cta>
        ) : null}
        {correctAnswers.length !== currentQuestion?.correctAnswers.length && !quizComplete ? (
          <Cta
            green
            large
            disabled={
              !selectedAnswers.length ||
              checkingAnswer ||
              incorrectAnswersSelected ||
              (!isRadio && selectedAnswers.length < currentQuestion?.correctAnswers.length)
            }
            onClick={handleCheckAnswers}
            interactive
          >
            Check Answer <span className="icon icon-checklist"></span>
          </Cta>
        ) : null}
        {quizComplete ? (
          <>
            {currentQuestionIndex > 0 ? (
              <Cta iconLeft darkOutline onClick={handlePrevQuestion}>
                <span className="icon icon-arrow-left" /> Previous Question
              </Cta>
            ) : (
              <div></div>
            )}
            {currentQuestionIndex < quiz.length - 1 ? (
              <Cta darkOutline onClick={handleNextQuestion}>
                Next Question <span className="icon icon-arrow-right" />
              </Cta>
            ) : (
              <div></div>
            )}
          </>
        ) : null}
      </ActionBar>
    </Wrap>
  );
};

export default Quiz;
