import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Wrap, FeedbackWrap, EmojiWrap, EmojiIcon, Input, Heading } from './Feedback.styled';
import { firestore } from '@mc/config/firebase';
import FormButton from '@mc/components/_form/form-button/FormButton';

const Feedback = ({ currentPageView }) => {
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackSent, setFeedbackSent] = useState(Boolean(currentPageView.mood));
  const [feedbackInput, setFeedbackInput] = useState(false);
  const [activeEmoji, setActiveEmoji] = useState(currentPageView.mood || null);

  useEffect(() => {
    setFeedbackMessage('');
    setFeedbackSent(Boolean(currentPageView.mood));
    setFeedbackInput(false);
  }, [currentPageView]);

  const emojis = [
    {
      color: 'red',
      name: 'sad',
    },
    {
      color: 'yellow',
      name: 'indifferent',
    },
    {
      color: 'green',
      name: 'smile',
    },
  ];

  const handleEmojiClick = async emoji => {
    setFeedbackInput(true);
    setActiveEmoji(emoji);

    try {
      await firestore()
        .doc(`pageViews/${currentPageView.id}`)
        .set({ mood: emoji }, { merge: true });
    } catch (ex) {
      console.error(ex);
      toast.error('Something went wrong. Please try again later.');
    }
  };

  const handleSendFeedback = async () => {
    try {
      await firestore()
        .doc(`pageViews/${currentPageView.id}`)
        .set({ feedback: feedbackMessage }, { merge: true });

      setFeedbackSent(true);
    } catch (ex) {
      console.error(ex);
      toast.error('Something went wrong. Please try again later.');
    }
  };

  return (
    <Wrap>
      {feedbackSent ? (
        <Heading>Thank you for your feedback!</Heading>
      ) : (
        <>
          <Heading>How did you find this page?</Heading>
          <EmojiWrap>
            {emojis.map(emoji => (
              <EmojiIcon
                key={emoji.name}
                color={emoji.color}
                active={activeEmoji === emoji.name && feedbackInput}
                onClick={() => handleEmojiClick(emoji.name)}
              >
                <span className={`icon icon-smiley-${emoji.name}`}></span>
              </EmojiIcon>
            ))}
          </EmojiWrap>
        </>
      )}
      {feedbackInput && !feedbackSent ? (
        <FeedbackWrap>
          <Input
            name="message"
            id="message"
            value={feedbackMessage}
            onChange={e => setFeedbackMessage(e.target.value)}
            placeholder="Tell us more…"
          />
          <FormButton onClick={handleSendFeedback} disabled={!feedbackMessage.length}>
            Send
          </FormButton>
        </FeedbackWrap>
      ) : null}
    </Wrap>
  );
};

export default Feedback;
