import React, { useState } from 'react';
import { Wrap, Toolbar, Title, Actions, TextArea } from './Editor.styled';

import 'codemirror/mode/javascript/javascript';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import Cta from '../_partials/cta/Cta';

const Editor = ({ code, setCode, onRunCode, initialCode }) => (
  <Wrap>
    <Toolbar>
      <Title>Code Editor</Title>
      <Actions>
        <Cta onClick={() => onRunCode()} interactive>
          Run Code
        </Cta>
      </Actions>
    </Toolbar>
    <TextArea
      value={code}
      options={{
        mode: 'javascript',
        theme: 'material',
        autofocus: true,
        lineNumbers: true,
      }}
      onBeforeChange={(editor, data, value) => setCode(value)}
    />
  </Wrap>
);

export default Editor;
