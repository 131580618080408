import styled from 'styled-components';
import Container from '../container/Container';
import RichTextField from '../rich-text-field/RichTextField';

export const Wrap = styled(Container)`
  ${({ theme }) => `  
    padding: 1.5rem;
    text-align: center;

    @media (min-width: ${theme.screens.md}) {
      padding: 4rem 2.5rem;
    }

    @media (min-width: ${theme.screens.lg}) {
      display: flex;
      align-items: center;
      text-align: left;
    }
  `}
`;

export const Image = styled.img`
  width: 100%;
  max-width: 500px;
  height: auto;
  margin-bottom: 2.5rem;
`;

export const Title = styled.h2`
  margin-bottom: 1rem;
`;

export const Description = styled(RichTextField)`
  margin-bottom: 1rem;
`;

export const Content = styled.div`
  ${({ theme }) => `
    @media (min-width: ${theme.screens.lg}) {
      padding-left: 5rem;
    }
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => `
    height: 1px;
    width: 100%;
    background-color: ${theme.colors.midGrey};
    margin: 2rem 0;
  `}
`;
