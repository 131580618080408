import styled from 'styled-components';

export const Label = styled.label`
  ${({ theme }) => `
    display: block;
    margin-bottom: 0.75rem;
    font-weight: 600;

    span {
      color: ${theme.colors.red};
    }
  `}
`;
