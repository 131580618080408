import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Markdown from '@mc/components/markdown/Markdown';
import { firestore, auth } from '@mc/config/firebase';
import { getUser } from '@mc/redux/auth/auth.selectors';
import Quiz from '../quiz/Quiz';
import {
  Wrap,
  SlackWrap,
  SlackText,
  ContentOuter,
  Content,
  Title,
  Progress,
  ProgressBar,
  ProgressBarInner,
  ProgressHeader,
  Select,
  Selected,
  SelectedIcon,
  ChevronIcon,
  ClickCatch,
  Menu,
  MenuSubheading,
  MenuHeading,
  OptionWrap,
  Option,
  OptionLink,
  OptionIcon,
  Gradient,
  Pagination,
  Nav,
  Button,
  CompleteButton,
  CompleteButtonIcon,
  Columns,
  Sidebar,
  MainCol,
  PaginationLoader,
  SidebarInner,
} from './Learner.styled';
import Feedback from '../_partials/feedback/Feedback';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import Loader from '../_partials/loader/Loader';
import { getPageIndex } from './Learner.helpers';

import ChatCta from '../_cards/chat-cta/ChatCta';
import { Text } from '../_cards/chat-cta/ChatCta.styled';

const Learner = () => {
  const { moduleSlug, chapterSlug } = useParams();
  const { search } = useLocation();
  const user = useSelector(getUser);
  const [menuOpen, setMenuOpen] = useState();
  const [pageComplete, setPageComplete] = useState(false);
  const [pageViews, setPageViews] = useState([]);
  const { pageId } = qs.parse(search, { ignoreQueryPrefix: true });
  const [module, setModule] = useState(null);
  const [chapter, setChapter] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const fetchPageData = async () => {
      setLoading(true);

      const tokenResult = await auth().currentUser.getIdTokenResult();
      const headers = {
        Authorization: `Bearer ${tokenResult.token}`,
      };

      try {
        const {
          data: { courseModule, courseChapter, completed, studentPageViews },
        } = await axios.get(
          `${
            process.env.REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT
          }/page?moduleSlug=${moduleSlug}&chapterSlug=${chapterSlug}${pageId ? `&pageId=${pageId}` : ''}`,
          { headers }
        );

        setModule(courseModule);
        setChapter(courseChapter);
        setPageViews(studentPageViews);
        setPageComplete(completed);
        setLoading(false);
      } catch (ex) {
        // Probably unauthorized...
        console.error(ex.response);
        history.push('/', { error: ex.response.data.message });
      }
    };

    fetchPageData();
  }, [chapterSlug, moduleSlug, pageId, user.token]);

  const currentPageId = chapter && (pageId || chapter.pages[0].id);
  const currentPageView = pageViews.find((pageView) => pageView.pageId === currentPageId);
  const pageIndex = chapter && getPageIndex(chapter.pages, pageId);
  const [answers, setAnswers] = useState(currentPageView && currentPageView.answers);
  const coursePage = chapter && (chapter.pages.find((page) => page.id === pageId) || chapter.pages[0]);

  useEffect(() => {
    if (currentPageView) {
      setAnswers(currentPageView.answers);
    }
  }, [currentPageView]);

  const chapterCompletionPercentage =
    chapter && Math.ceil((pageViews.filter((pageView) => pageView.completed).length / chapter.pages.length) * 100);

  const pagesWithProgress =
    chapter &&
    chapter.pages.map((page) => ({
      ...page,
      completed: pageViews.some((pageView) => pageView.completed && pageView.pageId === page.id),
    }));

  const quizComplete = Boolean(answers && coursePage && coursePage.quiz && answers[coursePage.quiz.length - 1]);

  const handleComplete = async () => {
    if (coursePage.quiz && coursePage.quiz.length && !quizComplete) {
      return;
    }

    try {
      await firestore().doc(`pageViews/${currentPageView.id}`).set({ completed: true }, { merge: true });
      setPageComplete(true);
      const newPageViews = pageViews.map((pageView) => ({
        ...pageView,
        completed: pageView.id === currentPageView.id ? true : pageView.completed,
        completedAt: new Date(),
        answers,
      }));

      setPageViews(newPageViews);

      if (moduleSlug === 'introduction-to-programming') {
        const courseFinished = chapter.pages.length === (pageIndex + 1);
        window.dataLayer = window.dataLayer || [];
        
        if (courseFinished) {
          window.dataLayer.push({
            event: 'intro_complete',
          });
        } else {
          window.dataLayer.push({
            event: 'intro_step',
            step_number: `step_${pageIndex + 1}`,
            step_name: coursePage.title,
          });
        }
      }

      if (coursePage.tag) {
        try {
          await fetch(`https://hooks.zapier.com/hooks/catch/11857442/33xyq3l/`, {
            method: 'POST',
            body: JSON.stringify({
              email: user.email,
              tag: coursePage.tag,
            }),
          });
        } catch (ex) {
          // fall silently
        }
      }

      // const completedPageViews = newPageViews.filter((pageView) => pageView.completed).length;

      // if (completedPageViews === chapter.pages.length && moduleSlug === 'introduction-to-programming') {
      //   try {
      //     await axios.request({
      //       method: 'POST',
      //       url: 'https://hooks.zapier.com/hooks/catch/4751012/o8yt2rd/',
      //       data: {
      //         email: user.email,
      //         date: new Date(),
      //         slackUserId: user.uid,
      //         name: (user.name && user.name.split(' ')[0]) || 'there',
      //       },
      //       headers: {
      //         'Content-Type': 'application/x-www-form-urlencoded',
      //       },
      //     });
      //   } catch (ex) {
      //     // fall silently
      //   }
      // }

      history.push(getNextLink());
    } catch (ex) {
      console.error(ex);
      toast.error('Something went wrong. Please try again later.');
    }
  };

  const handleUncomplete = async () => {
    try {
      await firestore().doc(`pageViews/${currentPageView.id}`).set({ completed: false }, { merge: true });
      setPageComplete(false);
      setPageViews(
        pageViews.map((pageView) => ({
          ...pageView,
          completed: pageView.id === currentPageView.id ? false : pageView.completed,
        }))
      );
    } catch (ex) {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  const getPrevLink = () =>
    chapter.pages[pageIndex - 1]
      ? `/module/${moduleSlug}/${chapterSlug}/?pageId=${chapter.pages[pageIndex - 1].id}`
      : `#`;

  const getNextLink = () =>
    pageIndex < chapter.pages.length - 1
      ? `/module/${moduleSlug}/${chapterSlug}/?pageId=${chapter.pages[pageIndex + 1].id}`
      : `/module/${moduleSlug}`;

  if (!chapter || !module) {
    return <Loader />;
  }

  const prevButtonProps = {};
  if (pageIndex === 0) {
    prevButtonProps.disabled = true;
  } else {
    prevButtonProps.url = getPrevLink();
  }

  return (
    <Wrap>
      <Breadcrumb
        links={[
          { url: `/module/${moduleSlug}`, text: module.name },
          { url: `/module/${moduleSlug}/${chapterSlug}`, text: chapter.name },
          { url: `/module/${moduleSlug}/${chapterSlug}?pageId=${pageId}`, text: chapter.pages[pageIndex].title },
        ]}
      />
      <Columns>
        <MainCol>
          <ContentOuter>
            <Content>
              <Title>{coursePage.title}</Title>
              <Markdown page={coursePage} />
            </Content>
            {coursePage.quiz && coursePage.quiz.length && currentPageView ? (
              <Quiz quiz={coursePage.quiz} pageView={currentPageView} onUpdateAnswers={setAnswers} />
            ) : null}
            {currentPageView && <Feedback currentPageView={currentPageView} />}
          </ContentOuter>
        </MainCol>
        <Sidebar>
          <Pagination>
            <Select>
              <Selected onClick={() => setMenuOpen(!menuOpen)} menuOpen={menuOpen}>
                <SelectedIcon className="icon icon-layers" />
                {chapter.pages[pageIndex].title}
                <ChevronIcon className="icon icon-chevron-down" />
              </Selected>
              {menuOpen ? (
                <Menu>
                  <MenuSubheading>{module.name} / {chapter.name}</MenuSubheading>
                  <MenuHeading>{chapter.pages[pageIndex].title}</MenuHeading>
                  <Progress>
                    <ProgressHeader>{chapterCompletionPercentage}% complete</ProgressHeader>
                    <ProgressBar>
                      <ProgressBarInner percentage={chapterCompletionPercentage} />
                    </ProgressBar>
                  </Progress>
                  <OptionWrap>
                    {pagesWithProgress.map((page, index) => (
                      <Option currentPage={currentPageId === page.id} key={page.id}>
                        <OptionIcon completed={page.completed}>
                          {!page.completed ? index + 1 : <span className="icon icon-check" />}
                        </OptionIcon>
                        <OptionLink
                          to={`/module/${moduleSlug}/${chapterSlug}/?pageId=${page.id}`}
                          onClick={() => setMenuOpen(false)}
                        >
                          {page.title}
                        </OptionLink>
                      </Option>
                    ))}
                  </OptionWrap>
                  <Gradient />
                </Menu>
              ) : null}
            </Select>
            {menuOpen ? <ClickCatch onClick={() => setMenuOpen(false)} /> : null}
            {loading ? (
              <PaginationLoader>
                <Loader contained />
              </PaginationLoader>
            ) : (
              <SidebarInner>
                <Nav>
                  {prevButtonProps.disabled ? (
                    ''
                  ) : (
                    <Button aria-label="Previous Page" {...prevButtonProps}>
                      Previous Page
                    </Button>
                  )}

                  {pageComplete ? (
                    <CompleteButton green small onClick={handleUncomplete}>
                      Completed
                      <CompleteButtonIcon className="icon icon-check-circle" />
                    </CompleteButton>
                  ) : (
                    <CompleteButton
                      primary
                      small
                      onClick={handleComplete}
                      disabled={coursePage.quiz && coursePage.quiz.length && !quizComplete}
                    >
                      Mark as Complete
                      <CompleteButtonIcon className="icon icon-check-circle" />
                    </CompleteButton>
                  )}

                  {pageComplete ? ( // Only show the "Next page" button when a page is completed. Otherwise, we can just use the "Mark complete" button to both mark the page as complete and move onto the next page.
                    <Button url={getNextLink()} aria-label="Next Page">
                      {pageIndex < chapter.pages.length - 1 ? 'Next Page' : 'Back to Module'}
                    </Button>
                  ) : (
                    ''
                  )}
                </Nav>
              </SidebarInner>
            )}
          </Pagination>
        </Sidebar>
      </Columns>
    </Wrap>
  );
};

export default Learner;
