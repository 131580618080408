import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { geocodeByAddress } from 'react-google-places-autocomplete';
import {
  Section,
  Title,
  FieldGroup,
  Fieldset,
  Label,
  Select,
  Input,
  Button,
  CtaContainer,
  Error,
  PlaceSearch,
} from '../Checkout.styled';
import Cta from '@mc/components/_partials/cta/Cta';
import CountrySelector from "@mc/components/country-selector/CountrySelector";
import FormPhoneInput from '@mc/components/_form/form-phone-input/FormPhoneInput';
import { getCountry } from '@mc/redux/config/config.selectors';
import { getUser } from '@mc/redux/auth/auth.selectors';
//import LoggedInWrapper from '@mc/components/logged-in-wrapper/LoggedInWrapper';

const titleOptions = ['Mr.', 'Mrs.', 'Miss.', 'Ms.', 'Mx.', 'Dr.'];

const EnrolSchema = Yup.object().shape({
  title: Yup.string()
    .oneOf(titleOptions)
    .required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  address: Yup.object().shape({
    line1: Yup.string().required('Required'),
    line2: Yup.string(),
    city: Yup.string().required('Required'),
    county: Yup.string(),
    postcode: Yup.string().required('Required'),
  }),
  phone: Yup.string().required('Required'),
  email: Yup.string()
    .email()
    .required('Required'),
});

const Enrolment = ({ onNextStep, customer, onUpdateCustomer }) => {
  const selectedCountry = useSelector(getCountry);
  const user = useSelector(getUser);

  const handleSubmit = values => {
    onUpdateCustomer({
      ...customer,
      personal: values,
    });
    onNextStep();
  };

  return (
    <Formik
      initialValues={{
        title: '',
        firstName: '',
        lastName: '',
        address: {
          line1: '',
          line2: '',
          city: '',
          county: '',
          postcode: '',
        },
        phone: '',
        email: user.email,
        countryCode: selectedCountry.code,
        emergencyContactName: '',
        emergencyContactPhone: '',
      }}
      validationSchema={EnrolSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, handleChange, handleBlur, values, setFieldValue }) => (
        <Form>
          <Section>
            <Title>Personal Details</Title>
            <FieldGroup>
              <Fieldset>
                <Label>Title</Label>
                <Select name="title" onChange={handleChange} onBlur={handleBlur} value={values.title}>
                  <option key="none" value="" disabled="disabled" hidden>
                    Select
                  </option>
                  {titleOptions.map(title => (
                    <option key={title} value={title}>
                      {title}
                    </option>
                  ))}
                </Select>
                {errors.title && touched.title ? <Error>{errors.title}</Error> : null}
              </Fieldset>
              <Fieldset>
                <Label htmlFor="firstName">First name</Label>
                <Input name="firstName" id="firstName" placeholder="John"></Input>
                {errors.firstName && touched.firstName ? <Error>{errors.firstName}</Error> : null}
              </Fieldset>
              <Fieldset>
                <Label htmlFor="lastName">Last name</Label>
                <Input name="lastName" id="lastName" placeholder="Smith"></Input>
                {errors.lastName && touched.lastName ? <Error>{errors.lastName}</Error> : null}
              </Fieldset>
            </FieldGroup>
            <Fieldset>
              <Label htmlFor="email">Email address</Label>
              <Input name="email" id="email" placeholder="email@email.com"></Input>
              {errors.email && touched.email ? <Error>{errors.email}</Error> : null}
            </Fieldset>

            {/* Makes sense to show country first if it changes the default value of phone  */}
            <Fieldset> 
              <Label htmlFor="country">Choose your country</Label>
              <CountrySelector form></CountrySelector>
            </Fieldset>

            <Fieldset>
              <Label htmlFor="phone">Phone number</Label>
              <FormPhoneInput
                countryCodeName="countryCode"
                phoneNumberName="phone"
                phoneNumberId="phone"
                countryCodeValue={values.countryCode}
                phoneNumberValue={values.phone}
                onCountryCodeChange={handleChange}
                onPhoneNumberChange={handleChange}
                onCountryCodeBlur={handleBlur}
                onPhoneNumberBlur={handleBlur}
              ></FormPhoneInput>
              {errors.phone && touched.phone ? <Error>{errors.phone}</Error> : null}
            </Fieldset>
            {/* <Fieldset>
              {showAddressFields ? (
                <Button onClick={() => setShowAddressFields(false)}>Search by address or postcode</Button>
              ) : (
                <>
                  <Label htmlFor="addressPostcode">Search by address or postcode</Label>
                  <PlaceSearch
                    type="search"
                    onSelect={async res => {
                      const [address] = await geocodeByAddress(res.description);
                      setShowAddressFields(true);

                      if (!address) {
                        return;
                      }

                      const { address_components: addressComponents } = address;

                      const streetNumber = addressComponents.find(addressComponent =>
                        addressComponent.types.includes('street_number')
                      );
                      const street = addressComponents.find(addressComponent =>
                        addressComponent.types.includes('route')
                      );
                      const locality = addressComponents.find(addressComponent =>
                        addressComponent.types.includes('locality')
                      );
                      const city = addressComponents.find(addressComponent =>
                        addressComponent.types.includes('postal_town')
                      );
                      const county = addressComponents.find(addressComponent =>
                        addressComponent.types.includes('administrative_area_level_2')
                      );
                      const postcode = addressComponents.find(addressComponent =>
                        addressComponent.types.includes('postal_code')
                      );

                      setFieldValue('address', {
                        line1: `${streetNumber ? `${streetNumber.long_name} ` : ''}${street ? street.long_name : ''}`,
                        line2: locality ? locality.long_name : '',
                        city: city ? city.long_name : '',
                        county: county ? county.long_name : '',
                        postcode: postcode ? postcode.long_name : '',
                      });
                    }}
                    autoComplete="new-password"
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: [selectedCountry.code],
                      },
                    }}
                    placeholder=""
                    name="moo"
                  />
                  or <Button onClick={() => setShowAddressFields(true)}>enter manually</Button>
                </>
              )}
            </Fieldset> */}
            <FieldGroup>
              <Fieldset>
                <Label htmlFor="addressBuilding">Address 1</Label>
                <Input name="address.line1" id="addressBuilding" placeholder="Address 1"></Input>
                {errors.address && errors.address.line1 && touched.address && touched.address.line1 ? (
                  <Error>{errors.address.line1}</Error>
                ) : null}
              </Fieldset>
              <Fieldset>
                <Label htmlFor="addressStreet">Address 2</Label>
                <Input name="address.line2" id="addressStreet" placeholder="Address 2" />
                {errors.address && errors.address.line2 && touched.address && touched.address.line2 ? (
                  <Error>{errors.address.line2}</Error>
                ) : null}
              </Fieldset>
            </FieldGroup>
            <FieldGroup>
              <Fieldset>
                <Label htmlFor="addressTown">Town/City</Label>
                <Input name="address.city" id="addressTown" placeholder="Town/City"></Input>
                {errors.address && errors.address.city && touched.address && touched.address.city ? (
                  <Error>{errors.address.city}</Error>
                ) : null}
              </Fieldset>
              <Fieldset>
                <Label htmlFor="addressCounty">County/State</Label>
                <Input name="address.county" id="addressCounty" placeholder="County" />
                {errors.address && errors.lastName && touched.address && touched.lastName ? (
                  <Error>{errors.lastName}</Error>
                ) : null}
              </Fieldset>
              <Fieldset>
                <Label htmlFor="addressPostcode">Postcode</Label>
                <Input name="address.postcode" id="addressPostcode" placeholder="Postcode" />
                {errors.address && errors.address.postcode && touched.address && touched.address.postcode ? (
                  <Error>{errors.address.postcode}</Error>
                ) : null}
              </Fieldset>
            </FieldGroup>
          </Section>
          <CtaContainer>
            <Cta primary type="submit">
              Continue
            </Cta>
          </CtaContainer>
        </Form>
      )}
    </Formik>
  );
};

export default Enrolment;
