import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Link } from 'react-router-dom';

export const CtaWrap = styled(Link)`
  ${({ theme, dark, $large, primary, secondary, white, green, yellow, darkOutline, disabled, interactive, iconLeft }) => `
    appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    padding: 0 1.5rem;
    background-color: ${theme.colors.buttonBg};
    color: ${theme.colors.buttonText};
    border: 0;
    border-radius: 50px;
    height: 44px;
    outline: none;
    transition: all 0.2s ease-in;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    letter-spacing: 0.025em;
    position: relative;

    &:disabled {
      cursor: default;
    }

    ${$large &&
      css`
        height: 50px;

        @media (min-width: ${theme.screens.md}) {
          padding: 0 22px 0 22px;
          font-size: 1rem;
        }
      `}

    @media (min-width: ${theme.screens.xl}) {
      font-size: 18px;
    }

    &:not(:disabled):hover {
      text-decoration: none;
      color: ${theme.colors.buttonText};
      background-color: ${theme.colors.buttonBgHover};
    }

    .icon {
      display: inline-block;
      ${iconLeft ? `margin-right: 0.5rem;` : `margin-left: 0.5rem;`}
      position: relative;
      top: 1px;
    }

    ${dark ? `
      border-color: #FFF;
      color: #FFF;

      &:hover {
        color: ${theme.colors.blueHover};
      }
    ` : ``}

    ${
      primary
        ? `
      background-color: ${theme.colors.blue};
      color: #000;
      border: 0;

      &:not(:disabled):hover {
        background-color: ${theme.colors.blueHover};
        color: #000;
      }
    `
        : ``
    }

    ${
      secondary
        ? `
      background-color: ${theme.colors.red};
      color: #fff;
      border: 0;

      &:not(:disabled):hover {
        background-color: ${theme.colors.redHover};
        color: #fff;
      }
    `
        : ``
    }

    ${
      white
        ? `
      background-color: ${theme.colors.white};
      border-color: ${theme.colors.white};
      color: ${theme.colors.dark};

      &:hover {
        color: ${theme.colors.blue};
      }
    `
        : ``
    }

    ${
      green
        ? `
      background-color: ${theme.colors.green};
      border: 0;
      color: #000;

      &:not(:disabled):hover {
        color: #000;
        background-color: ${darken(0.1, theme.colors.green)};
      }
    `
        : ``
    }

    ${
      yellow
        ? `
        background-color: ${theme.colors.yellow};
        border: 0;
        color: ${theme.colors.dark};

        &:hover {
          color: ${theme.colors.dark};
          background-color: ${darken(0.1, theme.colors.yellow)};
        }
      `
        : ``
    }

    ${
      darkOutline
        ? `
      border-color: ${theme.colors.dark};
    `
        : ``
    }

    ${
      disabled
        ? `
      opacity: 0.5;
    `
        : ``
    }
  `}
`;
