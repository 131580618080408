import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  });
}

// if (process.env.NODE_ENV !== 'production') {
//   firebase.auth().useEmulator('http://localhost:9099/');
//   firebase.firestore().useEmulator('localhost', 8080);
//   firebase.functions().useEmulator('localhost', 5001);
// }

export const { auth, firestore } = firebase;
export default firebase;
