import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '@mc/redux/auth/auth.selectors';
import { firestore } from '@mc/config/firebase';
import Cta from '@mc/components/_partials/cta/Cta';
import {
  Outer,
  Wrap,
  Header,
  Details,
  Title,
  Description,
  Glyph,
  Icon,
  Chapters,
  Chapter,
  ChapterDetails,
  ChapterIcon,
  ChapterName,
  Progress,
  Lock,
} from './Module.styled';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import { EnrolCard } from '../dashboard/Dashboard.styled';

const Module = () => {
  const { moduleSlug } = useParams();
  const user = useSelector(getUser);
  const userCourses = (user.courses || []).filter(c => c.activated).map(c => c.id); // We want to know if the course has been activated or not (allows admins to remove access to a course)
  const [module, setModule] = useState(null);
  const [chapterProgress, setChapterProgress] = useState([]);

  useEffect(() => {
    const fetchModule = async () => {
      const { docs: modulesDocs } = await firestore()
        .collection('modules')
        .where('slug', '==', moduleSlug)
        .limit(1)
        .get();
      const [moduleObj] = modulesDocs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
    
      const { docs: chaptersDocs } = await firestore()
        .collection('chapters')
        .get();
      const chaptersArray = chaptersDocs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        pages: [],
        pageCount: doc.data().pages.length,
      }));
  
      moduleObj.chapters = moduleObj.chapters.map(chapterRef => chaptersArray.find(chapter => chapter.id === chapterRef.id));
      moduleObj.locked = !moduleObj.courses.some(courseRef => userCourses.includes(courseRef.id));

      setModule(moduleObj);

      const { docs: completedPagesDocs } = await firestore()
        .collection('pageViews')
        .where('userId', '==', user.uid)
        .where('completed', '==', true)
        .where('moduleId', '==',
          firestore()
            .collection('modules')
            .doc(moduleObj.id)
        )
        .get();
      const completedPages = completedPagesDocs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      const progressions = moduleObj.chapters.map(chapter => {
        const completedPageCount = completedPages?.filter(pageView => pageView?.chapterId === chapter?.id).length;
        const progress = Math.ceil((completedPageCount / chapter?.pageCount) * 100);

        return progress;
      });

      setChapterProgress(progressions);
    }

    fetchModule();
  }, []); // eslint-disable-line

  const handleTrackBootcamp = (event) => {
    event.preventDefault();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'find_out_more_cta',
    });

    window.location.href = event.target.href;
  }

  if (!module) {
    return null;
  }

  return (
    <Outer>
      <Breadcrumb></Breadcrumb>
      <Wrap>
        <Header centered={!module.description}>
          <Glyph color={module.color}>
            <Icon className={`icon icon-${module.icon}`}></Icon>
          </Glyph>
          <Details>
            <Title>{module.name}</Title>
            {module.description ? <Description>{module.description}</Description> : null}
          </Details>
        </Header>
        {userCourses.length === 1 ? (
          <EnrolCard>
            <span>This module is only available to students enrolled onto our <strong>BOOTCAMP</strong> course</span>
            <Cta white url="https://www.manchestercodes.com/courses/bootcamp" target="_blank" onClick={handleTrackBootcamp}>
              Find out more
            </Cta>
          </EnrolCard>
        ) : null}
        <Chapters>
          {module.chapters.map((chapter, index) => {
            const chapterProps = {};
            const progress = (chapterProgress.length && chapterProgress[index]) || 0;

            if (!module.locked) {
              chapterProps.as = 'a';
              chapterProps.href = `/module/${module?.slug}/${chapter?.slug}`;
            }

            return (
              <Chapter {...chapterProps}>
                <ChapterDetails>
                  <ChapterIcon className="icon icon-book" />
                  <ChapterName>{chapter?.name}</ChapterName>
                </ChapterDetails>
                {module.locked ? (
                  <Lock>
                    <span className="icon icon-lock-circle"></span>
                  </Lock>
                ) : (
                  <Progress value={progress} text={`${progress}%`} strokeWidth={12}></Progress>
                )}
              </Chapter>
            );
          })}
        </Chapters>
      </Wrap>
    </Outer>
  );
};

export default Module;
